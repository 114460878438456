import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAppState } from "../../lib/Store";
import { ReactComponent as IcoTime } from "../../assets/ico_time.svg";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ButtonPrimary } from "../atoms/Button";

type ModalDeleteScheduleProps = {
  appointment: {
    id: string;
    display_summary: string;
    display_time_period: string;
    display_short_comment: string;
    guest_email: string;
  };
  close: Function;
  setAppointments?: Function;
};

const IcoTimeWrap = styled(IcoTime)`
  fill: ${colors.orange.hex};
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

const ModalMask = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 496px;
  min-height: 220px;
  background-color: ${colors.white.hex};
`;

const ButtonWrap = styled.div`
  margin: 40px auto 0;
  width: 350px;
  display: flex;
  justify-content: space-between;
`;

const ContentInnter = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
`;

const ButtonPrimaryLarge = styled(ButtonPrimary)`
  width: 160px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
`;

const PageTitle = styled.h1`
  font-size: 20px;
  letter-spacing: 0.28em;
  color: ${colors.keyColor.hex};
  margin: 0 4px 0;
  padding: 0;
  text-align: center;
`;

const PageTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: 8px;
  background-color: ${colors.white.hex};
  border-bottom: 1px solid ${colors.whiteAccent.hex};
`;

const Text = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
`;

const ScheduleText = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: ${colors.keyColor.hex};
  margin: 0 0 4px;
`;

export const ModalDeleteSchedule: React.FC<ModalDeleteScheduleProps> = (props) => {
  const history = useHistory();
  const state = useAppState();
  const deleteAppointments = useCallback(
    async (token, id) => {
      try {
        const results = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}me/events/appointments/${id}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(results)
        props.setAppointments(results.data);
      } catch (error) {
        console.log(error);
      }
    },
    [history]
  );

  return (
    <ModalMask>
      <ModalWrap>
        <ModalContainer>
          <PageTitleWrap>
            <PageTitle>スケジュールの削除</PageTitle>
          </PageTitleWrap>
          <ContentInnter>
            <ScheduleText>{props.appointment.display_summary}</ScheduleText>
            <ScheduleText>
              <IcoTimeWrap />
              {props.appointment.display_time_period}
            </ScheduleText>
            <Text>上記のスケジュールを削除します。 よろしいですか？</Text>
            <ButtonWrap>
              <ButtonPrimaryLarge
                onClick={() => {
                  props.close(false);
                }}
              >
                やめる
              </ButtonPrimaryLarge>
              <ButtonPrimaryLarge
                onClick={(e) => {
                  deleteAppointments(state.accessToken, props.appointment.id);
                  props.close(false);
                }}
              >
                削除する
              </ButtonPrimaryLarge>
            </ButtonWrap>
          </ContentInnter>
        </ModalContainer>
      </ModalWrap>
    </ModalMask>
  );
};
