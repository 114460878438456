import React from "react";
import styled from "styled-components";
import loading from "../../assets/animation-loding.gif";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 124px;
  height: auto;
`;

export const Loading: React.FC<{}> = () => {
  return (
    <Container>
      <Img src={loading} />
    </Container>
  );
};
