/**
 * 既存のCellFreeはチェックボックスになっている。
 * テンプレート画面では空き時間の表示のみを行いたいので、専用のセルを作成する。
 */
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { colors } from "../../data/colors";

type CellFreeProps = {
  startTime: string;
  endTime: string;
  uuid: string;
};

interface ContentProps {
  height: number;
  top: number;
};

const Cell = styled.div<ContentProps>`
  position: absolute;
  left: 0;
  top: ${(props) => props.top}px;
  height: ${(props) => props.height}px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 19px;
  color: ${colors.primary.hex};
  background-color: ${colors.ground.hex};
  border-radius: 1px;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid ${colors.keyColor.hex};
  box-sizing: border-box;
`;

const LabelWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CellFreeTemplate: React.FC<CellFreeProps> = (props) => {
  const text = props.children;
  const dateFrom = moment(props.startTime);
  const dateTo = moment(props.endTime);
  const diff = Math.floor(Math.abs(dateFrom.diff(dateTo, "minutes")) * 1.33);
  const hours = dateFrom.format("HH");
  const minutes = dateFrom.format("mm");
  const top = Number(hours) * 80 + Number(minutes) * 1.33;

  return (
    <Cell top={top} height={diff}>
      <LabelWrap>{text}</LabelWrap>
    </Cell>
  );
};

export default CellFreeTemplate;