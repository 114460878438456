import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Layout } from "../components/Layout";
import styled from "styled-components";
import { colors } from "../data/colors";
import { ReactComponent as AppLogo } from "../assets/app_logo.svg";
import { ReactComponent as AppTitle } from "../assets/app_title.svg";
import Footer from "../components/molecules/Footer";
import { ButtonPrimary } from "../components/atoms/Button";
import { TextField } from "../components/atoms/TextField";
import { app } from "../lib/Base";
import { AppVersion } from "../components/atoms/AppVersion";

const AppLogoWrap = styled(AppLogo)`
  width: auto;
  height: 108px;
`;

const AppTitleWrap = styled(AppTitle)`
  width: 396px;
  height: auto;
`;

const AppVersionWrap = styled.div`
  margin-bottom: 40px;
`;

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  padding-top: 40px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  width: 400px;
`;

const Headings = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 216px;
  margin: 0 0 12px;
`;

const TextFieldWrap = styled.div`
  margin-bottom: 16px;
`;

const ButtonHorizontalWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const AttentionWrap = styled.div`
  margin: 20px 0 30px;
  text-align: center;
  font-size: 16px;
`;

const AttentionText = styled.p`
  margin: 0;
`;

export const PasswordReset: React.FC<{}> = () => {
  const history = useHistory();
  const emailRef = useRef(null);
  const [isInput, seIsInput] = useState(false);
  const [isComplated, seIsComplated] = useState(false);

  const handleResetPass = useCallback(async (event) => {
    event.preventDefault();
    await app
      .auth()
      .sendPasswordResetEmail(emailRef.current.value)
      .then(function () {
        seIsComplated(true);
      })
      .catch(function (error) {
        alert(error);
      });
  }, []);

  const handleEmailValidation = (e) => {
    if (e.target.value.length > 0) {
      seIsInput(true);
    } else {
      seIsInput(false);
    }
  };

  return (
    <Layout title="ログイン">
      <MainContent>
        <Content>
          <Headings>
            <AppLogoWrap />
            <AppTitleWrap />
          </Headings>
          <AppVersionWrap>
            <AppVersion />
          </AppVersionWrap>
          {!isComplated && (
            <>
              <AttentionWrap>
                <AttentionText>
                  パスワードを再発行いたします。 <br />
                  ご登録されているメールアドレスを入力してください。
                </AttentionText>
              </AttentionWrap>
              <TextFieldWrap>
                <TextField
                  type="email"
                  ref={emailRef}
                  placeholder="Email"
                  name="email"
                  id="email"
                  onChange={(e) => {
                    handleEmailValidation(e);
                  }}
                />
              </TextFieldWrap>
              <ButtonHorizontalWrap>
                <ButtonPrimary
                  disabled={false}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  キャンセル
                </ButtonPrimary>
                <ButtonPrimary disabled={!isInput} type="submit" onClick={handleResetPass}>
                  送信
                </ButtonPrimary>
              </ButtonHorizontalWrap>
            </>
          )}
          {isComplated && (
            <>
              <AttentionWrap>
                <AttentionText>
                  パスワード再発行手続きの
                  <br />
                  ご案内を指定のメールアドレスへ 送信しました。
                </AttentionText>
              </AttentionWrap>
              <ButtonWrap>
                <ButtonPrimary
                  disabled={false}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  ログインページ
                </ButtonPrimary>
              </ButtonWrap>
            </>
          )}
        </Content>
      </MainContent>
      <Footer />
    </Layout>
  );
};
