import React from "react";
import styled from "styled-components";

const VersionWrap = styled.p`
  font-size: 12px;
  text-align: center;
  margin: 0;
`;

export const AppVersion: React.FC<{}> = () => {
  return <VersionWrap>ver 0.0.1</VersionWrap>;
};
