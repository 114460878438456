import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ButtonRound } from "../../components/atoms/Button";
import { HeaderMenu } from "../../components/molecules/HeaderMenu";
import { ReactComponent as AppLogo } from "../../assets/app_default_logo.svg";
import { ReactComponent as IcoHome } from "../../assets/ico_home.svg";
import { ReactComponent as IcoSchedule } from "../../assets/ico_schedule.svg";
import { ReactComponent as IcoTemplate } from "../../assets/ico_template.svg";
import { ReactComponent as IcoAccount } from "../../assets/ico_account.svg";
import { ReactComponent as IcoNotification } from "../../assets/ico_notification.svg";

const HeaderElement = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 800;
  background-color: ${colors.white.hex};
  border-bottom: 1px solid ${colors.groundDark.hex};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyLogoHeadings = styled.h1`
  display: block;
  width: 41px;
  height: 25px;
  margin: -14px 8px 0;
  padding: 0;
  font-size: 0;
`;

const AppLogoWrap = styled(AppLogo)`
  width: 40px;
  height: 40px;
`;

const ButtonRoundAccount = styled(ButtonRound)`
  width: 32px;
  height: 32px;
`;

const ButtonRoundNotification = styled(ButtonRound)`
  width: 16px;
  height: 16px;
  padding: 0;
  background-color: transparent;
  display: none;
`;

const IcoAccountWrap = styled(IcoAccount)`
  fill: ${colors.keyColor.hex};
  height: 19px;
`;

const IcoNotificationWrap = styled(IcoNotification)`
  fill: ${colors.middleGray.hex};
  width: 13px;
  height: 16px;
`;

const SettingsButtonWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 10px;
`;

const MainNav = styled.ul`
  width: 472px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 5px 0 0;
`;

const LinkWrap = styled.li``;

const StyledLink = styled(Link)`
  height: 32px;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 14px;
  text-decoration: none;
  color: ${colors.keyColor.hex};
  border-radius: 1px;
  &.active {
    //background-color: ${colors.orange.hex};
  }
  &:hover,
  &:focus {
    opacity: 0.6;
  }
`;

const HeaderIconWrap = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid ${colors.keyColor.hex};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    background-color: ${colors.keyColor.hex};
  }
`;

const IcoHomeWrap = styled(IcoHome)`
  fill: ${colors.keyColor.hex};
  width: 16px;
  height: auto;
  &.active {
    fill: ${colors.white.hex};
  }
`;

const IcoScheduleWrap = styled(IcoSchedule)`
  fill: ${colors.keyColor.hex};
  width: 18px;
  height: auto;
  &.active {
    fill: ${colors.white.hex};
  }
`;

const IcoTemplateWrap = styled(IcoTemplate)`
  fill: ${colors.keyColor.hex};
  width: 16px;
  height: auto;
  &.active {
    fill: ${colors.white.hex};
  }
`;

const TextWrap = styled.span`
  padding-left: 8px;
`;

const HelpWrap = styled.a`
    margin: 0 0 auto;
    padding 0 20px 0 0;
    color: ${colors.keyColor.hex};
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    &:hover,
    &:focus {
      opacity: 0.6;
    }
 `;

export const Header: React.FC<{}> = () => {
  //TODO: パスによってアクティブなリンクを設定する
  const [showMenu, setShowMenu] = useState(false);
  const path = useLocation().pathname;
  const helpPath: string = 'https://www.notion.so/TimeLab-Ap-95f8125d559a4ae99e95d4ad76850096';

  const showMenuFunc = () => {
    setShowMenu(!showMenu);
    let eventFunc;
    document.addEventListener(
      "click",
      (eventFunc = (e) => {
        if (showMenu) {
          setShowMenu(false);
        }
        document.removeEventListener("click", eventFunc);
      })
    );
  };

  return (
    <HeaderElement>
      <CompanyLogoHeadings>
        <AppLogoWrap />
      </CompanyLogoHeadings>
      <MainNav>
        <LinkWrap>
          <StyledLink to="/home" className={path === "/home" ? "active" : ""}>
            <HeaderIconWrap className={path === "/home" ? "active" : ""}>
              <IcoHomeWrap className={path === "/home" ? "active" : ""} />
            </HeaderIconWrap>
            <TextWrap>ホーム</TextWrap>
          </StyledLink>
        </LinkWrap>
        <LinkWrap>
          <StyledLink to="/schedule" className={path === "/schedule" ? "active" : ""}>
            <HeaderIconWrap className={path === "/schedule" ? "active" : ""}>
              <IcoScheduleWrap className={path === "/schedule" ? "active" : ""} />
            </HeaderIconWrap>
            <TextWrap>新規日程調整</TextWrap>
          </StyledLink>
        </LinkWrap>
        <LinkWrap>
          <StyledLink to="/template" className={path === "/template" ? "active" : ""}>
            <HeaderIconWrap className={path === "/template" ? "active" : ""}>
              <IcoTemplateWrap className={path === "/template" ? "active" : ""} />
            </HeaderIconWrap>
            <TextWrap>テンプレート</TextWrap>
          </StyledLink>
        </LinkWrap>
      </MainNav>
      <SettingsButtonWrap>
        <HelpWrap href={helpPath} rel="noopener" target="_blank">ヘルプ</HelpWrap>
        <ButtonRoundAccount
          onClick={() => {
            showMenuFunc();
          }}
        >
          <IcoAccountWrap />
        </ButtonRoundAccount>
        <ButtonRoundNotification>
          <IcoNotificationWrap />
        </ButtonRoundNotification>
        {showMenu && <HeaderMenu />}
      </SettingsButtonWrap>
    </HeaderElement>
  );
};
