import styled from "styled-components";
import { colors } from "../../data/colors";
import bg from "../../assets/ico_select-arrow.png";

export const Selectbox = styled.select`
  background-color: ${colors.whiteAccent.hex};
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: calc(100% - 6px) center;
  border: none;
  box-sizing: border-box;
  color: ${colors.gray2.hex};
  font-size: 14px;
  width: 100%;
  height: 32px;
  outline: none;
  padding: 0px 8px;
  appearance: none;
`;

export const SelectboxOption = styled.option`
  background: ${colors.silverGray.hex};
  border: none;
  box-sizing: border-box;
  color: ${colors.gray2.hex};
  font-size: 12px;
  width: 100%;
  height: 32px;
  outline: none;
  padding: 0px 8px;
`;

export const SelectboxNormal = styled.select`
  background-color: ${colors.silverGray.hex};
  border: none;
  box-sizing: border-box;
  color: ${colors.gray2.hex};
  font-size: 14px;
  width: 100%;
  height: 32px;
  outline: none;
  padding: 0px 8px;
  appearance: none;
`;
