import React from "react";
import styled from "styled-components";
import moment from "moment";
import { colors } from "../../data/colors";

type CellBusyProps = {
  startTime: string;
  endTime: string;
};

interface ContentProps {
  height: number;
  top: number;
}

const Cell = styled.div<ContentProps>`
  position: absolute;
  left: 0;
  top: ${(props) => props.top}px;
  height: ${(props) => props.height}px;
  width: 100%;
  min-height: 19px;
  color: ${colors.buttonGrayText.hex};
  background-color: ${colors.whiteAccent.hex};
  border-radius: 1px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  padding: 4px 6px;
`;

export const CellBusy: React.FC<CellBusyProps> = (props) => {
  const dateFrom = moment(props.startTime);
  const dateTo = moment(props.endTime);
  const diff = Math.floor(Math.abs(dateFrom.diff(dateTo, "minutes")) * 1.33);
  const hours = dateFrom.format("HH");
  const minutes = dateFrom.format("mm");
  const top = Number(hours) * 80 + Number(minutes) * 1.33;
  return (
    <Cell top={top} height={diff}>
      {props.children}
    </Cell>
  );
};
