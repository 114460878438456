import React, { createContext, useState } from "react";

export const TimeContext = createContext(null);

export const TimeProvider = ({ children }) => {
  const [uuid, setUuid] = useState([]);
  const [range, setRange] = useState({ start: "0:00", end: "23:59" });
  const [blockDays, setBlockDays] = useState([]);

  const addUuid = (_uuid) => {
    if (_uuid) {
      const uuids = [];
      uuids.push(_uuid);
      setUuid(uuids);
    }
  };

  const removeUuid = (_uuid) => {
    setUuid([]);
  };

  const addRange = (start, end) => {
    setRange({ start: start, end: end });
  };

  const removeRange = () => {
    setRange({ start: "0:00", end: "23:59" });
  };

  const addBlockDays = (blockDay) => {
    const tmpBlockDays = JSON.parse(JSON.stringify(blockDays));
    tmpBlockDays.push(`${blockDay}T00:00:00.000+09:00`);
    setBlockDays(tmpBlockDays);
  };

  const removeBlockDays = (blockDay) => {
    const tmpBlockDays = JSON.parse(JSON.stringify(blockDays));
    const index = tmpBlockDays.indexOf(`${blockDay}T00:00:00.000+09:00`);
    if (index >= 0) {
      tmpBlockDays.splice(index, 1);
      setBlockDays(tmpBlockDays);
    }
  };

  const clearBlockDays = () => {
    setBlockDays([]);
  };

  const getRange = () => {
    return range;
  };

  const getBlockDays = () => {
    return blockDays;
  };

  return (
    // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
    <TimeContext.Provider
      value={{
        addUuid: addUuid,
        removeUuid: removeUuid,
        addRange: addRange,
        removeRange: removeRange,
        addBlockDays: addBlockDays,
        removeBlockDays: removeBlockDays,
        clearBlockDays: clearBlockDays,
        getRange: getRange,
        getBlockDays: getBlockDays,
        uuid,
        range,
        blockDays,
      }}
    >
      {children}
    </TimeContext.Provider>
  );
};
