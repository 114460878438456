import React from 'react';
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ReactComponent as IcoDelete } from "../../assets/ico_delete.svg";
import { ReactComponent as IcoTime } from "../../assets/ico_time.svg";
import { ReactComponent as IcoEdit } from "../../assets/ico_edit.svg";
import { ReactComponent as IcoDirectionsWalk } from "../../assets/ico_directions-walk.svg";
import { ButtonPrimary } from "../atoms/Button";
import { useHistory } from 'react-router-dom';

type TemplateCardProps = {
  template: {
    uuid: string;
    title: string;
    main_param: number;
    buffer_param: number;
  };
  deleteTemplate?: Function;
  setIsCopyClipboard? :Function;
}

const ScheduleBox = styled.div`
  min-height: 72px;
  background-color: ${colors.white.hex};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18);
  border-radius: 1px;
  margin: 20px auto 0;
  cursor: pointer;
`;

const ScheduleBoxTitleWrap = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: space-between;
  background-color: ${colors.white.hex};
  align-items: center;
  padding: 0 8px 0 9px;
`;

const ScheduleBoxTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

const TemplateContentWrap = styled.div`
  position: relative;
  padding-left: 8px;
`;

const ButtonPrimarySuqure28x28 = styled(ButtonPrimary)`
  width: 28px;
  height: 28px;
  background-color: ${colors.white.hex};
  border: 0;
  margin-left: 8px;
`;

const ButtonPrimarySuqureBorder36x36 = styled(ButtonPrimary)`
  width: 36px;
  height: 36px;
  background-color: ${colors.white.hex};
  margin-left: 5px;
  margin-bottom: 5px;
  &&& {
    border: 1px solid ${colors.primary.hex};
  }
`;

const TemplateContentTimesWrap = styled.div`
  display: inline;
`;

const TemplateContentTimeWrap = styled.div`
  display: inline;
`

const TemplateContentTextTimeWrap = styled.span`
  color: ${colors.orange.hex};
  font-size: 28px;
`

const TemplateContentTextTimeUnitWrap = styled.span`
  color: ${colors.orange.hex};
  font-size: 12px;
  padding-left: 3px;
`

const TemplateContentButtons = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
`

const TemplateContentButtonContent = styled.span`
  font-size: 4px;
  width: 16px;
  height: 16px;
  margin-right: 3px;
  white-space: nowrap;
  color: ${colors.primary.hex};
  display: inline-block;
`;

const IcoDeleteWrap = styled(IcoDelete)`
  fill: ${colors.keyColor.hex};
  width: 14px;
  height: 18px;
`;

const IcoEditWrap = styled(IcoEdit)`
  fill: ${colors.primary.hex};
  width: 20px;
  height: 26px;
`;

const IcoTimeWrap = styled(IcoTime)`
  fill: ${colors.orange.hex};
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const IcoDirectionsWalkWrap = styled(IcoDirectionsWalk)`
  fill: ${colors.orange.hex};
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-left: 10px;
`;


const TemplateCard: React.FC<TemplateCardProps> = (props) => {

  const history = useHistory();

  // URLをクリップボードに直接コピーする。
  const clipboardCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(`${process.env.REACT_APP_HOST_URL}/invitation?uuid=${props.template.uuid}`);
    }
  };

  const pushTemplateEdit = () => {
    history.push({
      pathname: '/template',
      state: props.template,
    });
  }

  return (
    <ScheduleBox>
      <ScheduleBoxTitleWrap>
        <ScheduleBoxTitle>{props.template.title}</ScheduleBoxTitle>
      </ScheduleBoxTitleWrap>
      <TemplateContentWrap>

        <TemplateContentTimesWrap>
          <TemplateContentTimeWrap>
            <IcoTimeWrap />
            <TemplateContentTextTimeWrap>
              {props.template.main_param}
            </TemplateContentTextTimeWrap>
            <TemplateContentTextTimeUnitWrap>
              min
            </TemplateContentTextTimeUnitWrap>
          </TemplateContentTimeWrap>
          <TemplateContentTimeWrap>
            <IcoDirectionsWalkWrap />
            <TemplateContentTextTimeWrap>
              {props.template.buffer_param}
            </TemplateContentTextTimeWrap>
            <TemplateContentTextTimeUnitWrap>
              min
            </TemplateContentTextTimeUnitWrap>
          </TemplateContentTimeWrap>
        </TemplateContentTimesWrap>

        <TemplateContentButtons>

          {/* URL発行ボタン */}
          <ButtonPrimarySuqureBorder36x36
            onClick={() => {
              clipboardCopy();
              props.setIsCopyClipboard(true);
            }}
          >
            <TemplateContentButtonContent>
              URL<br />発行
            </TemplateContentButtonContent>
          </ButtonPrimarySuqureBorder36x36>

          {/* 編集ボタン */}
          <ButtonPrimarySuqureBorder36x36 onClick={pushTemplateEdit}>
            <IcoEditWrap />
          </ButtonPrimarySuqureBorder36x36>
          <ButtonPrimarySuqure28x28
            onClick={() => {
              props.deleteTemplate(props.template);
            }}
          >
            <IcoDeleteWrap />
          </ButtonPrimarySuqure28x28>
        </TemplateContentButtons>

      </TemplateContentWrap>
    </ScheduleBox>
  );
};

export default TemplateCard;