import React from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { CellFree } from "../atoms/CellFree";
import { CellBusy } from "../atoms/CellBusy";
import CellFreeTemplate from "../atoms/CellFreeTemplate";

type DateProps = {
  date?: string;
  data: {
    date: string;
    day: string;
    holiday: boolean;
    holiday_name: string;
    data: object[];
  };
  day: string;
  addFunction?: Function;
  removeFunction?: Function;
  isGuest: boolean;      // trueならば、Freeのセル（空き時間）のみを表示する。
  isBusyOnly: boolean;   // trueならば、Busyのセル（既に埋まっているイベント）のみを表示する。
  isTemplate?: boolean;  // trueならば、Freeのセルが表示のみ（チェックボックスでない）。オプション。
};

interface ContentProps {
  contentDay: string;
}

const Container = styled.div<ContentProps>`
  width: calc(100% - 116px);
  border-top: 2px solid ${colors.ultraPaleGray.hex};
  position: relative;
  //margin-right: ${(props) => (props.contentDay === "San." ? 0 : 12)}px;
`;

const HourWrap = styled.div`
  height: 80px;
  border-bottom: 2px solid ${colors.ultraPaleGray.hex};
`;

const CellWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 14px);
`;

export const DateTimeline: React.FC<DateProps> = (props) => {
  return (
    <Container contentDay={props.day}>
      {!props.isGuest && !props.isBusyOnly && !props.isTemplate && (
        <CellWrap>
          {props.data.data.length > 0 &&
          props.data.data.map((data, index) =>
            data["event_type"] === "free" ? (
              <CellFree uuid={data["uuid"]} startTime={data["start_time"]} endTime={data["end_time"]} key={`key-${index}`} initChecked={false} isGuest={props.isGuest} addFunction={props.addFunction} removeFunction={props.removeFunction}>
                {data["name"]}
              </CellFree>
            ) : (
              <CellBusy startTime={data["start_time"]} endTime={data["end_time"]} key={`key-${index}`}>
                {data["name"]}
              </CellBusy>
            )
          )}
        </CellWrap>
      )}
      {/* テンプレート画面での表示の場合 */}
      { props.isTemplate && (
        <CellWrap>
          { props.data.data.length > 0 && props.data.data.map((data, index) =>
            data["event_type"] == "free" ? (
              <CellFreeTemplate key={`key-${index}`} startTime={data["start_time"]} endTime={data["end_time"]} uuid={data["uuid"]}>
                {data["name"]}
              </CellFreeTemplate>
            ) : (
              <CellBusy startTime={data["start_time"]} endTime={data["end_time"]} key={`key-${index}`}>
                {data["name"]}
              </CellBusy>
            )
          )}
        </CellWrap>
      )}
      {/* ゲスト側での表示の場合 */}
      {props.isGuest && (
        <CellWrap>
          {props.data.data.length > 0 &&
            props.data.data.map((data, index) => (
              <CellFree uuid={data["uuid"]} startTime={data["start_time"]} endTime={data["end_time"]} key={`key-${index}`} initChecked={false} isGuest={props.isGuest} addFunction={props.addFunction} removeFunction={props.removeFunction}>
                {data["name"]}
              </CellFree>
            ))}
        </CellWrap>
      )}
      {/* BusyCellのみの表示の場合 */}
      {props.isBusyOnly && (
        <CellWrap>
          {props.data.data.length > 0 &&
            props.data.data.map((data, index) =>
              data["event_type"] === "busy" ? (
                <CellBusy startTime={data["start_time"]} endTime={data["end_time"]} key={`key-${index}`}>
                  {data["name"]}
                </CellBusy>
              ) : (
                <></>
              )
            )}
        </CellWrap>
      )}

      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
      <HourWrap />
    </Container>
  );
};
