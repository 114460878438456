import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../data/colors";

export interface ButtonProps {
  width: number;
  height: number;
  disabled: boolean;
  color: string;
  effect: string;
  corner: string;
  onClick?: (e: any) => void;
}

const ButtonBase = styled.button`
  color: ${colors.keyColor.hex};
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    opacity: 0.6;
  }
  &:disabled {
    cursor: default;
    opacity: 1;
  }
`;

export const ButtonPrimary = styled(ButtonBase)`
  border-radius: 1px;
  width: 184px;
  height: 48px;
  padding: 0;
  background-color: ${colors.ground.hex};
  border: 1px solid ${colors.keyColor.hex};
  &:disabled {
    color: ${colors.buttonGrayText.hex};
    border: 0;
    background-color: ${colors.buttonGrayGround.hex};
  }
`;

export const ButtonRound = styled(ButtonBase)`
  border-radius: 50%;
  background-color: ${colors.groundDark.hex};
`;

export const ButtonText = styled(ButtonBase)`
  color: ${colors.text.hex};
  font-size: 10px;
  width: 168px;
  height: 32px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
  &:hover {
    color: ${colors.buttonGrayText.hex};
    background-color: ${colors.whiteAccent.hex};
  }
`;

const LinkBase = styled(Link)`
  display: inline-block;
  color: ${colors.white.hex};
  font-size: 14px;
  border: none;
  outline: none;
  font-weight: bold;
  text-decoration: none;
`;

export const ButtonLink = styled(LinkBase)`
  border-radius: 1px;
  width: 184px;
  height: 48px;
  padding: 0;
  color: ${colors.keyColor.hex};
  background-color: ${colors.ground.hex};
  border: 1px solid ${colors.keyColor.hex};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${colors.ultraPaleGray.hex};
  }
`;

export const TextLink = styled(Link)`
  display: inline-block;
  color: ${colors.keyColor.hex};
  font-size: 14px;
  font-weight: bold;
  width: 184px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
`;

export const RelatedLink = styled.a`
  border-radius: 1px;
  display: inline-block;
  color: ${colors.keyColor.hex};
  font-size: 12px;
  border: none;
  outline: none;
  font-weight: bold;
  text-decoration: none;
  font-size: 10px;
  width: 184px;
  height: 48px;
  background-color: ${colors.ground.hex};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.keyColor.hex};
  &:hover {
    background-color: ${colors.ultraPaleGray.hex};
  }
`;
