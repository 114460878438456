import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonText } from "../atoms/Button";
import { colors } from "../../data/colors";
import { app } from "../../lib/Base";

const HeaderMenuWrap = styled.ul`
  position: absolute;
  top: 0;
  right: 36px;
  width: 168px;
  height: auto;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  background-color: ${colors.white.hex};
  list-style-type: none;
  padding: 0;
  z-index: 999;
`;

const ButtonWrap = styled.li``;

const MenuLink = styled(Link)`
  color: ${colors.text.hex};
  font-size: 12px;
  width: 168px;
  height: 32px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
  text-decoration: none;
  &:hover {
    color: ${colors.buttonGrayText.hex};
    background-color: ${colors.whiteAccent.hex};
  }
`;

export const HeaderMenu: React.FC<{}> = () => {
  const history = useHistory();
  const handleSignOut = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await app
          .auth()
          .signOut()
          .then(() => {
            history.push("/");
          })
          .catch((error) => {
            alert(`ログアウト時にエラーが発生しました (${error})`);
          });
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  return (
    <HeaderMenuWrap>
      <ButtonWrap>
        <MenuLink to="/profile">プロフィール編集</MenuLink>
      </ButtonWrap>
      <ButtonWrap>
        <MenuLink to="/setting">基本設定</MenuLink>
      </ButtonWrap>
      <ButtonWrap>
        <ButtonText
          onClick={(e) => {
            handleSignOut(e);
          }}
        >
          ログアウト
        </ButtonText>
      </ButtonWrap>
    </HeaderMenuWrap>
  );
};
