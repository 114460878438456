import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";

const GlobalStyle = createGlobalStyle`
  ${normalize}
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    overflow: auto;
    font-size: 62.5%;
  }
  body {
    position: relative;
    min-height: 100%;
    margin: 0;
    font-family: "Noto Sans JP", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
    font-weight: 400;
    line-height: 1.8;
    color: #333;
    word-wrap: break-word;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -webkit-font-kerning: normal;
    font-kerning: normal;
    font-size: 1.6rem;
    display: flex;
  }
  #root {
    width: 100%;
    min-height: 100%;
  }
  select::-ms-expand {
    display: none;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

type LayoutProps = {
  title: string;
};

export const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <>
      <GlobalStyle />
      <Container>{props.children}</Container>
    </>
  );
};
