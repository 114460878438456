import React from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ButtonLink } from "../../components/atoms/Button";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: ${colors.modalBg.rgba};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 496px;
  min-height: 240px;
  background-color: #fff;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
`;

const PageTitle = styled.h1`
  font-size: 20px;
  letter-spacing: 0.28em;
  color: ${colors.keyColor.hex};
  margin: 0 4px 0;
  padding: 0;
  text-align: center;
`;

const PageTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: 8px;
  background-color: ${colors.white.hex};
  border-bottom: 1px solid ${colors.whiteAccent.hex};
`;

const ButtonWrap = styled.div`
  margin: 30px auto 30px;
  width: 184px;
`;

const Message = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  width: 326px;
  text-align: center;
`;

const MessageWrap = styled.div`
  padding: 20px 20px 0;
  width: 100%;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalFirstAlert: React.FC<{}> = (props) => {
  return (
    <>
      <Container>
        <Content>
          <PageTitleWrap>
            <PageTitle>はじめに</PageTitle>
          </PageTitleWrap>
          <MessageWrap>
            <Message>まずは新規日程調整をしてみましょう！</Message>
          </MessageWrap>
          <ButtonWrap>
            <ButtonLink to="/schedule">新規日程調整へ</ButtonLink>
          </ButtonWrap>
        </Content>
      </Container>
    </>
  );
};
