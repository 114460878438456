import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { colors } from "../../data/colors";
import { TimeContext } from "../../lib/CheckboxProvider";

type CellFreeProps = {
  startTime: string;
  endTime: string;
  initChecked: boolean;
  addFunction?: Function;
  removeFunction?: Function;
  isGuest: boolean;
  uuid: string;
};

interface ContentProps {
  height: number;
  top: number;
  guest?: boolean;
}

const Input = styled.input`
  display: none;
`;

const Cell = styled.label<ContentProps>`
  position: absolute;
  left: 0;
  top: ${(props) => props.top}px;
  height: ${(props) => props.height}px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 19px;
  color: ${colors.primary.hex};
  background-color: ${colors.ground.hex};
  border-radius: 1px;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid ${colors.keyColor.hex};
  box-sizing: border-box;
  &:hover {
    color: ${colors.keyColor.hex};
    background-color: ${colors.white.hex};
    border: 1px dashed ${colors.keyColor.hex};
    box-shadow: inset 0px 0px 8px rgba(255, 255, 255, 0.84);
  }
  &.checked {
    color: ${(props) => (props.guest ? colors.white.hex : colors.white.hex)};
    background-color: ${(props) => (props.guest ? `#B34215` : colors.whiteAccent.hex)};
    //border: 1px dashed ${(props) => (props.guest ? `#FF936B` : `#746f6f`)};
  }
`;

const LabelWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CellFree: React.FC<CellFreeProps> = (props) => {
  const { addUuid, removeUuid, uuid, range, blockDays } = useContext(TimeContext);
  const [checked, setChecked] = useState(props.initChecked || false);
  const [text, setText] = useState(props.children);
  const dateFrom = moment(props.startTime);
  const dateTo = moment(props.endTime);
  const diff = Math.floor(Math.abs(dateFrom.diff(dateTo, "minutes")) * 1.33);
  const hours = dateFrom.format("HH");
  const minutes = dateFrom.format("mm");
  const top = Number(hours) * 80 + Number(minutes) * 1.33;
  const [isShow, setIsShow] = useState(true);

  const updateChecked = (e) => {
    if (e.currentTarget.checked) {
      props.addFunction(props.startTime, props.endTime, props.uuid);
      if (props.isGuest) {
        setText("Selected");
        addUuid(props.uuid);
      }
    } else {
      props.removeFunction(props.startTime, props.endTime, props.uuid);
      if (props.isGuest) {
        setText(props.children);
        removeUuid(props.uuid);
      }
    }
    setChecked(e.currentTarget.checked);
  };

  useEffect(() => {
    if (props.isGuest && uuid[0] === props.uuid) {
      setChecked(true);
      setText(`Selected:`);
    } else {
      setChecked(false);
      setText(`${props.children}`);
    }
  }, [props.uuid, uuid]);

  useEffect(() => {
    const startDate = `${moment(dateFrom).format("YYYY-MM-DD")} ${range["start"]}`;
    const endDate = `${moment(dateFrom).format("YYYY-MM-DD")} ${range["end"]}`;
    const dateFromFormated = moment(dateFrom).format("YYYY-MM-DD HH:mm");
    const dateToFormated = moment(dateTo).format("YYYY-MM-DD HH:mm");
    const isStartRangeStart = moment(dateFromFormated).isSameOrAfter(startDate);
    const isStartRangeEnd = moment(dateFromFormated).isSameOrBefore(endDate);
    const isEndRangeStart = moment(dateToFormated).isSameOrAfter(startDate);
    const isEndRangeEnd = moment(dateToFormated).isSameOrBefore(endDate);

    setIsShow(true);
    if (!props.isGuest) {
      if (isStartRangeStart && isStartRangeEnd && isEndRangeStart && isEndRangeEnd) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    }

    blockDays.map((day) => {
      if (moment(day).format("YYYY-MM-DD") === moment(dateFrom).format("YYYY-MM-DD")) {
        setIsShow(false);
      }
    });
  }, [range, blockDays]);

  return (
    <>
      {isShow && (
        <Cell top={top} height={diff} className={checked ? "checked" : ""} guest={props.isGuest}>
          <Input
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              updateChecked(e);
            }}
          />
          <LabelWrap>{text}</LabelWrap>
        </Cell>
      )}
    </>
  );
};
