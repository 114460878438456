import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAppState } from "../../lib/Store";
import { ReactComponent as IcoTime } from "../../assets/ico_time.svg";
import { ReactComponent as IcoDirectionsWalk } from "../../assets/ico_directions-walk.svg";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ButtonPrimary } from "../atoms/Button";

type ModalDeleteTemplateProps = {
  template: {
    uuid: string;
    title: string;
    main_param: number;
    buffer_param: number;
  };
  close: Function;
  setTemplates?: Function;
};

const IcoTimeWrap = styled(IcoTime)`
  fill: ${colors.orange.hex};
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

const IcoDirectionsWalkWrap = styled(IcoDirectionsWalk)`
  fill: ${colors.orange.hex};
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

const ModalMask = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 496px;
  min-height: 220px;
  background-color: ${colors.white.hex};
`;

const ButtonWrap = styled.div`
  margin: 40px auto 0;
  width: 350px;
  display: flex;
  justify-content: space-between;
`;

const ContentInnter = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
`;

const ButtonPrimaryLarge = styled(ButtonPrimary)`
  width: 160px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
`;

const PageTitle = styled.h1`
  font-size: 20px;
  letter-spacing: 0.28em;
  color: ${colors.keyColor.hex};
  margin: 0 4px 0;
  padding: 0;
  text-align: center;
`;

const PageTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: 8px;
  background-color: ${colors.white.hex};
  border-bottom: 1px solid ${colors.whiteAccent.hex};
`;

const Text = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
`;

const ScheduleText = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: ${colors.keyColor.hex};
  margin: 0 0 4px;
 `;

const ModalDeleteTemplate: React.FC<ModalDeleteTemplateProps> = (props) => {
  const history = useHistory();
  const state = useAppState();
  const deleteTemplate = useCallback(
    async (token, uuid) => {
      try {
        // DELETEを投げる。戻り値は削除したデータなので使わない。
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}time_templates/${uuid}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        // GETを投げて、新しいtemplates配列を取得し、set。
        const results = await axios.get(`${process.env.REACT_APP_API_BASE_URL}me/time_templates`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        props.setTemplates(results.data);
      } catch (error) {
        console.log(error);
      }
    },
    [history]
  );

  return (
    <ModalMask>
      <ModalWrap>
        <ModalContainer>
          <PageTitleWrap>
            <PageTitle>テンプレートの削除</PageTitle>
          </PageTitleWrap>
          <ContentInnter>
            <ScheduleText>{props.template.title}</ScheduleText>
            <ScheduleText>
              <IcoTimeWrap />
              {props.template.main_param}min
              /
              <IcoDirectionsWalkWrap />
              {props.template.buffer_param}min
            </ScheduleText>
            <Text>上記のテンプレートを削除します。 よろしいですか？</Text>
            <ButtonWrap>
              <ButtonPrimaryLarge
                onClick={() => {
                  props.close(false);
                }}
              >
                やめる
              </ButtonPrimaryLarge>
              <ButtonPrimaryLarge
                onClick={() => {
                  deleteTemplate(state.accessToken, props.template.uuid);
                  props.close(false);
                }}
              >
                削除する
              </ButtonPrimaryLarge>
            </ButtonWrap>
          </ContentInnter>
        </ModalContainer>
      </ModalWrap>
    </ModalMask>
  );
};

export default ModalDeleteTemplate;