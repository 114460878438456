/*
  InvitationToSmartphone: スマートフォン対応の招待画面
  Routerで599px以下でこちらの画面に切り替わる。
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import axios from "axios";
import moment, { now } from "moment";
import { colors } from "../data/colors";
import { ReactComponent as AppLogo } from "../assets/app_logo.svg";
import { ReactComponent as AppTitle } from "../assets/app_title.svg";
import { ReactComponent as IcoHelp} from "../assets/ico_help.svg";
import { addFreebusy, makeCalendar, whatWeek } from "../lib/Calendar";
import { Layout } from "../components/Layout";
import { TextField } from "../components/atoms/TextField";
import { Textarea } from "../components/atoms/Textarea";
import {ButtonPrimary, RelatedLink} from "../components/atoms/Button";
import Footer from "../components/molecules/Footer";
import { Error } from "../components/molecules/Error";
import { Loading } from "../components/molecules/Loading";
import { TextFieldWrapper } from "../components/molecules/TextFiledWrapper";
import DateTimelineToSmartphone from "../components/molecules/DateTimelineToSmartphone";

interface PageProps extends RouteComponentProps<{}> {}

// Header Styled Components Start
const IcoHelpWrap = styled(IcoHelp)`
  fill: ${colors.orange.hex};
  height: 30px;
`;

const HelpLinkWrap = styled.a`
`;

const HeaderElement = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 800;
  background-color: ${colors.white.hex};
  border-bottom: 1px solid ${colors.groundDark.hex};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyLogoHeadings = styled.h1`
  display: block;
  width: 41px;
  height: 25px;
  margin: -14px 8px 0;
  padding: 0;
  font-size: 0;
`;

const AppLogoWrap = styled(AppLogo)`
  width: 40px;
  height: 40px;
`;

const Stepbar = styled.div`
  width: 100%;
  height: 56px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepWrap = styled.ul`
  height: 32px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const Step = styled.li`
  height: 32px;
  background-color: ${colors.silverGray.hex};
  color: rgba(167, 164, 162, 1);
  text-align: center;
  font-size: 10px;
  line-height: 14px;
  padding-top: 2px;
  &.active {
    background-color: ${colors.white.hex};
    color: ${colors.orange.hex};
  }
  padding-right: 10px;
  padding-left: 10px;
`;
// Header Styled Components End

// Main Styled Components Start
const MainContent = styled.main`
  width: 100%;
  padding-top: 56px;
  background-color: ${colors.white.hex};
`;

const InfoContainerWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  width: 100%;
  background-color: ${colors.ground.hex};
  padding-top: 3px;
  padding-bottom: 3px;
`;

const InfoContent = styled.div`
  margin: auto;
`;

const EmptyWrap = styled.div`
  padding: 3px 10px;
`;

const Headings = styled.p`
  color: #a7a4a2;
  font-size: 10px;
  margin: 0 0 4px;
`;

const TitleWrap = styled.div`
  height: 28px;
  font-size: 14px;
  line-height: 1;
  color: ${colors.orange.hex};
`;

const ExpirWrap = styled.div`
  height: 28px;
  font-size: 14px;
  line-height: 1;
  color: ${colors.orange.hex};
`;

const TextWrap = styled.div`
  font-size: 14px;
`;

const TimelineWrap = styled.ul`
  margin: 0 auto;
  padding: 0;
  padding-bottom: 20px;
  overflow-x: auto;
  overflow-y: scroll;
  width: 90%;
  max-height: 50rem;
  white-space: nowrap;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
`;

const TimelineItemWrap = styled.li`
  display: inline-block;
  list-style: none;
  width: 100%;
  vertical-align: top;
`;

const BottomnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 40px;
`

const BottonSelect = styled(ButtonPrimary)`
`;
// Main Styled Components End

// Confirm Form Styled Components Start
const ConfirmContent = styled.div`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
`;

const InputHeadings = styled.div`
  margin: 0 0 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  width: 100%;
  text-align: center;
`;

const TextFieldWrap = styled.div`
  font-size: 10px;
  margin-bottom: 16px;
  color: ${colors.darkGray.hex};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ConfirmContentBody = styled.div`
  margin: 0 auto;
  padding: 5px 0;
  align-items: center;
`;

const PlanTime = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin-bottom: 12px;
  padding-bottom: 10px;
  text-align: center;
  border-bottom: 2px solid ${colors.lightGray.hex};
  &.fix {
    color: ${colors.darkGray.hex};
  }
`;

const ButtonPrimaryMiddle = styled(ButtonPrimary)`
  width: 150px;
`;

const BottomButtonWrap = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const DateConfirmWrap = styled.div`
`;

const TextFieldH32 = styled(TextField)`
  height: 32px;
`;

const TextareaH162 = styled(Textarea)`
  height: 162px;
`;
const Required = styled.span`
  color: ${colors.white.hex};
  font-size: 10px;
  padding: 0 10px;
  background-color: ${colors.orange.hex};
  margin-left: 12px;
`;

const HorizontalWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;
// Confirm Form Styled Components End

// Confirm Page Styled Components Begin
const PlanMessageText = styled.p`
  font-size: 12px;
  color: ${colors.darkGray.hex};
  margin: 0;
  width: 100%;
  strong {
    font-weight: bold;
  }
`;

const AttentionHostText = styled.div`
  font-size: 16px;
  padding: 12px 20px;
  border: 1px solid ${colors.orange.hex};
  color: ${colors.darkGray.hex};
  margin-top: 12px;
  text-align: center;
  background: #f0f0f5;
`;

const HostInfo = styled.p`
  color: ${colors.orange.hex};
  font-weight: bold;
  margin: 0;
`;

const Deadline = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

const ButtonPrimaryAddCalendar = styled(RelatedLink)`
  width: 100%;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
`;

const ButtonSubText = styled.p`
  font-size: 14px;
`;

const SubCopy = styled.p`
  width: 100%;
  font-size: 12px;
  color: ${colors.middleGray.hex};
  text-align: center;
  margin: 20px 0 12px;
`;

const CompanyLogoContentWrap = styled(AppLogo)`
  margin-top: 25px;
  height: 30px;
  width: 100%;
`;

const AppTitleWrap = styled(AppTitle)`
  width: 100%;
  height: 10px;
`;

const MeetingUrlWrap = styled.div`
  margin: 10px 0 10px;
`;

const MeetingUrlTitle = styled.p`
  margin: 0;
`;

const MeetingUrlText = styled.div`
  color: ${colors.orange.hex};
  font-weight: bold;
  margin: 0;
`;
// Confirm Page Styled Components End

const InvitationToSmartphone: React.FC<PageProps> = (props) => {
  const params = props.location.search;

  let urlCode: string;         // eventの場合はurl_code, templateの場合はuuid
  let mode: string;　          // url_codeの場合はevent, uuidの場合はtemplate
  let getRequestPath: string;  // eventとtemplateで異なるURLにGETするため
  const helpPath: string = 'https://www.notion.so/TimeLab-Ap-95f8125d559a4ae99e95d4ad76850096';

  if (params.indexOf("&") > -1) {
    //URLに想定外の&でパラメーターを付与されてしまった場合（messangerなどで勝手に付与される）
    const paramsArray = params.split("&");
    mode = paramsArray[0].split("=")[0];
    urlCode = paramsArray[0].split("=")[1];
  } else {
    mode = params.split("?")[1].split("=")[0];
    urlCode = params.split("?")[1].split("=")[1];
  }

  if (mode === "uuid") {
    getRequestPath = `${process.env.REACT_APP_API_BASE_URL}events/template_freebusy/${urlCode}`
  } else if (mode === "url_code") {
    getRequestPath = `${process.env.REACT_APP_API_BASE_URL}events/query_freebusy/${urlCode}`
  }

  const [step, setStep] = useState(0);  // 1: 日程選択, 2: 入力フォーム, 3: 確認画面
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [expire, setExpire] = useState("");
  const [weekArray, setWeekArray] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [displayHost, setDisplayHost] = useState("");
  const [uuid, setUuid] = useState("");
  const [displayTimePeriod, setDisplayTimePeriod] = useState("");
  const [fixTime, setFixtime] = useState({ startTime: "", endTime: "" });
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSelected, setIsSelected] = useState(true);
  const [isConfirmInputValid, setIsConfirmValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [userName, setUserName] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [commitAt, setCommitAt] = useState("");
  const [registrationUrl, setRegistrationUrl] = useState("");
  const [meetingUrl, setMeetingUrl] = useState("");

  const userNameRef = useRef(null);
  const userEmailRef = useRef(null);
  const userCompanyRef = useRef(null);
  const userMessageRef = useRef(null);

  useEffect(() => {
    const fn = async () => {
      setIsLoaded(true);

      try {
        const results = await axios.get(getRequestPath, {
          headers: {
            accept: "application/json",
          },
        });

        setStep(1);
        const _calendar = makeCalendar(moment(results.data.current_time).format("YYYY"));

        // freebusyが空配列（ホスト側で全ての曜日のenabledをfalseにした）の場合は本日の日付をセット
        const dateLength = results.data.freebusy.length

        // freeの最初の日付取得
        const firstDate = dateLength ?
          moment(results.data.freebusy[0]["start_time"]).format("YYYY-MM-DD") :
          moment(now()).format("YYYY-MM-DD");

        // freeの最後の日付取得
        const lastDate = dateLength ?
          moment(results.data.freebusy[dateLength - 1]["end_time"]).format("YYYY-MM-DD") :
          moment(now()).format("YYYY-MM-DD");

        // firstDate, lastDateがカレンダーの何周目かを取得
        const currentWeekIndex = whatWeek(_calendar, firstDate);
        const lastWeekIndex = whatWeek(_calendar, lastDate);
        const differenceIndex = lastWeekIndex - currentWeekIndex;

        for (let i = 0; i <= differenceIndex; i++) {
          _calendar[currentWeekIndex + i].forEach(date => {
            // weekArrayにfirstDateからlastDateまでの週のデータを挿入
            weekArray.push(date);
          })
        }

        const newCalendar = addFreebusy(_calendar, results.data.freebusy);
        setCalendar(newCalendar);
        setIsLoaded(false);
        setTitle(results.data.time_query.event_title);
        setMessage(results.data.time_query.event_message);
        setExpire(moment(results.data.time_query.expires_at).format("YYYY/MM/DD HH:mm"));
        setDisplayHost(`${results.data.time_query.host_nickname}（${results.data.time_query.host_email}）`);
      } catch (error) {
        console.log(error);
        setIsLoaded(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
      }
    };
    fn();
  }, []);

  // 時間帯をクリックした際の処理
  const addSelected = (startTime, endTime, _uuid) => {
    setUuid(_uuid);
    setFixtime({ startTime: startTime, endTime: endTime });
    setIsSelected(false);
  };

  // 選択した時間帯をクリックした際の処理
  const removeSelected = (startTime, endTime, _uuid) => {
    setUuid("");
    setFixtime({ startTime: "", endTime: "" });
    setIsSelected(true);
  };

  // フォーム全体のバリデーションチェック
  const confirmInputValidation = () => {
    let isUserName = false;
    let isUserCompany = false;
    let isUserEmail = false;
    let isUserMessage = false;

    // ユーザ名のバリデーション
    if (userNameRef.current.value.length >= 2 && userNameRef.current.value.length <= 15) {
      isUserName = true;
    }
    // ユーザの会社名のバリデーション
    if (userCompanyRef.current.value.length >= 1 && userCompanyRef.current.value.length <= 30) {
      isUserCompany = true;
    }
    // ユーザのメールアドレスのバリデーション
    if (userEmailRef.current.value.length >= 6 && userEmailRef.current.value.length <= 254) {
      isUserEmail = true;
    }
    // メッセージのバリデーション
    if (userMessageRef.current.value.length <= 2000) {
      isUserMessage = true;
    }

    // 上記の全てのバリデーションが通ったときのみフラグをオフ
    if (isUserName && isUserCompany && isUserEmail && isUserMessage) {
      setIsConfirmValid(false);
    } else {
      setIsConfirmValid(true);
    }

    // stateに値をセット
    setUserName(userNameRef.current.value);
    setUserCompany(userCompanyRef.current.value);
    setUserEmail(userEmailRef.current.value);
    setUserMessage(userMessageRef.current.value);
  }

  // 個別のフォームのチェック
  const charLengthValidation = (e, min, max) => {
    if (e.target.value.length < min || e.target.value.length > max) {
      e.target.classList.add("invalid");
    } else {
      e.target.classList.remove("invalid");
    }
  }

  // 予約投稿処理
  const postAppointments = useCallback(async (startTime, endTime, _uuid) => {
    setIsLoaded(true);

    const data = {
      uuid: urlCode,
      time_min: startTime,
      time_max: endTime,
      guest_name: userNameRef.current.value,
      guest_company: userCompanyRef.current.value,
      guest_comment: userMessageRef.current.value,
      guest_email: userEmailRef.current.value,
      kind: mode === "uuid" ? "template" : "query",
    };

    try {
      const results = await axios.post(`${process.env.REACT_APP_API_BASE_URL}events/appointments`, data, {
        headers: {
          accept: "application/json",
        },
      });

      setStep(3);
      setCommitAt(moment(results.data.commit_at).format("YYYY/MM/DD HH:mm"));
      setDisplayTimePeriod(results.data.display_time_period);
      setRegistrationUrl(results.data.registration_url);
      setDisplayHost(`${results.data.host_nickname}（${results.data.host_email}）`);
      setMeetingUrl(results.data.meeting_url);
      setIsLoaded(false);
    } catch (error) {
      console.log(error);
      setIsLoaded(false);
      setIsError(true);
      setErrorMessage(error.response.data.message);
    }
  }, []);

  return (
    <Layout title="予定調整依頼">
      <HeaderElement>
        <CompanyLogoHeadings>
          <AppLogoWrap />
        </CompanyLogoHeadings>
        <Stepbar>
          <StepWrap>
            <Step className={step === 1 ? "active" : ""}>
              STEP1
              <br />
              日時選択
            </Step>
            <Step className={step === 2 ? "active" : ""}>
              STEP2
              <br />
              情報入力
            </Step>
            <Step className={step === 3 ? "active" : ""}>
              STEP3
              <br />
              日程確定
            </Step>
            <HelpLinkWrap href={helpPath} target="_blank" rel="noopener" >
              <IcoHelpWrap />
            </HelpLinkWrap>
          </StepWrap>
        </Stepbar>
      </HeaderElement>
      <MainContent>
        {step === 1 && <>
          <InfoContainerWrap>
            <InfoContainer>
              <InfoContent>
                <EmptyWrap>
                  <Headings>タイトル</Headings>
                  <TitleWrap>{title}</TitleWrap>
                </EmptyWrap>
                {mode === "url_code" &&
                <EmptyWrap>
                  <Headings>対応期限</Headings>
                  <ExpirWrap>{expire}まで</ExpirWrap>
                </EmptyWrap>
                }
                <EmptyWrap>
                  <TextWrap>{message}</TextWrap>
                </EmptyWrap>
              </InfoContent>
            </InfoContainer>
          </InfoContainerWrap>
          <TimelineWrap>
            {weekArray.map((week, index) =>
              !!week.data.length &&
              <TimelineItemWrap key={index}>
                <DateTimelineToSmartphone
                  data={week}
                  day={week.day}
                  addFunction={addSelected}
                  removeFunction={removeSelected}
                />
              </TimelineItemWrap>)
            }
          </TimelineWrap>
          <BottomnWrap>
            <BottonSelect
              disabled={isSelected}
              onClick={() => {
                setStep(2);
                const start = moment(fixTime.startTime).format("MM/DD(ddd) HH:mm");
                const end = moment(fixTime.endTime).format("HH:mm");
                setDisplayTimePeriod(`${start} - ${end}`);
              }}>
              決定
            </BottonSelect>
          </BottomnWrap>
        </>
        }
        { step === 2 &&
        <>
          <ConfirmContent>
            <ConfirmContentBody>
              <DateConfirmWrap>
                <PlanTime>{displayTimePeriod}</PlanTime>
                <InputHeadings>
                  お名前<Required>必須</Required>
                </InputHeadings>
                <TextFieldWrap>
                  <TextFieldWrapper min={1} max={15} count={userName.length}>
                    <TextFieldH32
                      type="text"
                      ref={userNameRef}
                      placeholder="タイムラボ太郎"
                      name="user-name"
                      id="user-name"
                      defaultValue={""}
                      onChange={(e) => {
                        confirmInputValidation();
                        charLengthValidation(e, 2, 15);
                      }}
                    />
                  </TextFieldWrapper>
                </TextFieldWrap>
                <InputHeadings>
                  会社名<Required>必須</Required>
                </InputHeadings>
                <TextFieldWrap>
                  <TextFieldWrapper min={1} max={30} count={userCompany.length}>
                    <TextFieldH32
                      type="text"
                      ref={userCompanyRef}
                      placeholder="株式会社タイムラボ"
                      name="user-company"
                      id="user-company"
                      defaultValue={""}
                      onChange={(e) => {
                        confirmInputValidation();
                        charLengthValidation(e, 1, 30);
                      }}
                    />
                  </TextFieldWrapper>
                </TextFieldWrap>
                <InputHeadings>
                  メールアドレス<Required>必須</Required>
                </InputHeadings>
                <TextFieldWrap>
                  <TextFieldWrapper min={1} max={254} count={userEmail.length}>
                    <TextFieldH32
                      type="email"
                      ref={userEmailRef}
                      placeholder="you@example.com"
                      name="user-email"
                      id="user-email"
                      defaultValue={""}
                      onChange={(e) => {
                        confirmInputValidation();
                        charLengthValidation(e, 6, 254);
                      }}
                    />
                  </TextFieldWrapper>
                </TextFieldWrap>
                <InputHeadings>事前の共有事項など</InputHeadings>
                <TextFieldWrapper min={-1} max={200} count={userMessage.length}>
                  <TextareaH162
                    ref={userMessageRef}
                    placeholder="事前の共有事項など"
                    maxLength={200}
                    onChange={() => {
                      confirmInputValidation();
                    }}
                  />
                </TextFieldWrapper>
              </DateConfirmWrap>
              <HorizontalWrap>
                <BottomButtonWrap
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  <ButtonPrimaryMiddle>戻る</ButtonPrimaryMiddle>
                </BottomButtonWrap>
                <BottomButtonWrap
                  onClick={() => {
                    postAppointments(fixTime.startTime, fixTime.endTime, uuid);
                  }}
                >
                  <ButtonPrimaryMiddle disabled={isConfirmInputValid}>決定</ButtonPrimaryMiddle>
                </BottomButtonWrap>
              </HorizontalWrap>
            </ConfirmContentBody>
          </ConfirmContent>
        </>
        }
        {
          step == 3 &&
          <>
            <ConfirmContent>
              <ConfirmContentBody>
                <PlanTime>{displayTimePeriod}</PlanTime>
                {userMessage && (
                  <PlanMessageText>
                    <strong>あなたからのメッセージ:</strong> {userMessage}
                  </PlanMessageText>
                )}
                <AttentionHostText>
                  <HostInfo>{displayHost}</HostInfo>のカレンダーに上記の日程で登録されました。
                  { !!meetingUrl && (
                    <MeetingUrlWrap>
                      <MeetingUrlTitle>参加用のURLはこちらになります。</MeetingUrlTitle>
                      <MeetingUrlText>{meetingUrl}</MeetingUrlText>
                    </MeetingUrlWrap>
                  )}
                </AttentionHostText>
                <Deadline>※日程を変更する場合は、ホストまでご連絡お願いします。</Deadline>
                <BottomButtonWrap>
                  <ButtonPrimaryAddCalendar href={registrationUrl} target="_blank">
                    <ButtonSubText>Googleカレンダーに登録</ButtonSubText>
                  </ButtonPrimaryAddCalendar>
                </BottomButtonWrap>
                <SubCopy>
                  TimeLab Apは、カレンダーと連携して
                  <br />
                  面倒な日程調整を自動化してくれる便利なサービスです。
                </SubCopy>
                <ButtonPrimaryAddCalendar href="/">
                  <ButtonSubText>TimeLab Apを無料で使ってみる</ButtonSubText>
                </ButtonPrimaryAddCalendar>
                <CompanyLogoContentWrap />
                <AppTitleWrap />
              </ConfirmContentBody>
            </ConfirmContent>
          </>
        }
      </MainContent>
      <Footer />
      {
        <Error
          isShow={isError}
          errorMessage={errorMessage}
          close={() => {
            setIsError(false);
          }}
        />
      }
      {isLoaded && <Loading />}
    </Layout>
  );
}

export default InvitationToSmartphone;