import React, { useEffect, useState, useContext } from "react";
import { Checkbox } from "../atoms/Checkbox";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { TimeContext } from "../../lib/CheckboxProvider";

type DateProps = {
  date?: string;
  data: {
    date: string;
    day: string;
    holiday: boolean;
    holiday_name: string;
    data: object[];
  };
  day: string;
  today?: string;
  isGuest?: boolean;
};

interface ContentProps {
  contentDay: string;
}

const Container = styled.div<ContentProps>`
  width: 100%;
  margin-right: ${(props) => (props.contentDay === "San." ? 0 : 12)}px;
`;

const DayWrap = styled.div`
  width: 100%;
  height: 26px;
  margin-top: 8px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: ${colors.gray.hex};
  &.sat {
    color: #2d9cdb;
  }
  &.sun {
    color: #eb5757;
  }
  &.today {
    color: ${colors.white.hex};
    background-color: ${colors.primary.hex};
  }
`;

export const Date: React.FC<DateProps> = (props) => {
  const { addBlockDays, removeBlockDays } = useContext(TimeContext);
  const [parentChecked, setParentChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  let dayElement;
  if (props.day === "土") {
    dayElement = <DayWrap className={props.today === props.data.date ? "today sat" : "sat"}>{props.day}</DayWrap>;
  } else if (props.day === "日") {
    dayElement = <DayWrap className={props.today === props.data.date ? "today sun" : "sun"}>{props.day}</DayWrap>;
  } else {
    dayElement = <DayWrap className={props.today === props.data.date ? "today" : ""}>{props.day}</DayWrap>;
  }
  const numberDate = String(Number(props.data.date.split("-")[2]));

  useEffect(() => {
    if (props.data.data.length > 0) {
      setParentChecked(true);
      setDisabled(false);
    } else {
      setParentChecked(false);
      setDisabled(true);
    }
  }, [props.data.data, props.data.data.length]);

  const updateCheckedFunc = (checked) => {
    if (checked) {
      removeBlockDays(props.data.date);
    } else {
      addBlockDays(props.data.date);
    }
  };

  return (
    <Container contentDay={props.day}>
      <Checkbox updateChaecked={parentChecked} label={numberDate} initChecked={false} disabled={disabled} onChange={updateCheckedFunc} isGuest={props.isGuest} />
      {dayElement}
    </Container>
  );
};
