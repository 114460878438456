import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ButtonPrimary } from "../atoms/Button";
import { ReactComponent as IcoTime } from "../../assets/ico_time.svg";
import { ReactComponent as IcoDelete } from "../../assets/ico_delete.svg";

type AppointmentScheduleProps = {
  appointment: {
    id: string;
    display_summary: string;
    display_time_period: string;
    display_short_comment: string;
    guest_email: string;
  };
  deleteAppointment?: Function;
};

const IcoTimeWrap = styled(IcoTime)`
  fill: ${colors.orange.hex};
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

const ScheduleBox = styled.div`
  min-height: 72px;
  background-color: ${colors.white.hex};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18);
  border-radius: 1px;
  margin: 20px auto 0;
  cursor: pointer;
`;

const ScheduleBoxTitleWrap = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: space-between;
  background-color: ${colors.white.hex};
  align-items: center;
  padding: 0 8px 0 9px;
`;

const ScheduleBoxTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

const ScheduleContentWrap = styled.div`
  position: relative;
  padding: 8px;
`;

const ButtonPrimarySuqure28x28 = styled(ButtonPrimary)`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 28px;
  height: 28px;
  background-color: ${colors.white.hex};
  border: 0;
`;

const IcoDeleteWrap = styled(IcoDelete)`
  fill: ${colors.keyColor.hex};
  width: 14px;
  height: 18px;
`;

const TimeWrap = styled.div`
  color: ${colors.orange.hex};
  line-height: 1;
  font-size: 14px;
  display: flex;
  align-items: center;
  svg {
    transform: translateY(1px);
  }
`;

const Message = styled.p`
  margin: 4px 0 0;
  font-size: 12px;
  &.close {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.open {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
  }
`;

const ScheduleContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GuestEmail = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
`;

export const AppointmentSchedule: React.FC<AppointmentScheduleProps> = (props) => {
  const [toggle, setToggle] = useState(true);

  return (
    <ScheduleBox
      key={props.appointment.id}
      onClick={() => {
        setToggle(!toggle);
      }}
    >
      <ScheduleBoxTitleWrap>
        <ScheduleBoxTitle>{props.appointment.display_summary}</ScheduleBoxTitle>
      </ScheduleBoxTitleWrap>
      <ScheduleContentWrap>
        <ScheduleContentHeader>
          <TimeWrap>
            <IcoTimeWrap />
            {props.appointment.display_time_period}
          </TimeWrap>
          <GuestEmail>{props.appointment.guest_email}</GuestEmail>
        </ScheduleContentHeader>
        <Message className={!toggle ? "open" : "close"}>{props.appointment.display_short_comment}</Message>
        <ButtonPrimarySuqure28x28
          onClick={() => {
            props.deleteAppointment(props.appointment);
          }}
        >
          <IcoDeleteWrap />
        </ButtonPrimarySuqure28x28>
      </ScheduleContentWrap>
    </ScheduleBox>
  );
};
