import React, { useRef, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { Layout } from "../components/Layout";
import { Header } from "../components/molecules/Header";
import Footer from "../components/molecules/Footer";
import styled from "styled-components";
import { TextField } from "../components/atoms/TextField";
import { ButtonPrimary } from "../components/atoms/Button";
import { Selectbox, SelectboxOption } from "../components/atoms/Selectbox";
import { app } from "../lib/Base";
import { colors } from "../data/colors";
import { useAppState, useSetAppState } from "../lib/Store";
import axios from "axios";
import { Loading } from "../components/molecules/Loading";
import { Error } from "../components/molecules/Error";

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 656px;
  height: 540px;
  background-color: ${colors.white.hex};
  margin-top: 60px;
  display: flex;
`;

const TabWrap = styled.ul`
  width: 152px;
  list-style-type: none;
  margin: 40px 20px 0 0;
  padding: 0;
`;

const TabList = styled.li``;

const TabLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.ground.hex};
  font-size: 14px;
  font-weight: bold;
  color: ${colors.keyColor.hex};
  width: 152px;
  height: 36px;
  text-decoration: none;
  border-bottom: 2px solid ${colors.keyColor.hex};
  &.active {
    background-color: ${colors.white.hex};
    color: ${colors.buttonGrayText.hex};
    border-bottom: 2px solid ${colors.buttonGrayText.hex};
  }
`;

const ContentInnter = styled.div`
  width: 504px;
  margin: 0 auto;
  padding: 0;
`;

const Headings = styled.h2`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black.hex};
  margin: 20px 0 8px;
`;

const TextFieldWrap = styled.div`
  margin-bottom: 16px;
`;

const SelectWrap = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
`;

const SelectboxCountry = styled(Selectbox)`
  font-size: 14px;
  width: 200px;
`;

const SelectCountryWrap = styled.div`
  width: 90px;
`;

const ButtonHorizontalWrap = styled.div`
  display: flex;
  width: 390px;
  justify-content: space-between;
  margin: 40px auto 0;
`;

const ButtonWrap = styled.div`
  margin: 40px auto 0;
  width: 184px;
`;

export const Profile: React.FC<{}> = () => {
  const history = useHistory();
  const setAppState = useSetAppState();
  const state = useAppState();
  const nicknameRef = useRef(null);
  const emailRef = useRef(null);
  const time_zoneRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInputValid, setIsInputValid] = useState(true);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSignOut = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await app
          .auth()
          .signOut()
          .then(() => {
            history.push("/");
          })
          .catch((error) => {
            alert(`ログアウト時にエラーが発生しました (${error})`);
          });
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const handleDetele = useCallback(
    async (event) => {
      event.preventDefault();
      const user = app.auth().currentUser;
      await user
        .delete()
        .then(function () {
          history.push("/");
        })
        .catch(function (error) {
          alert(error);
        });
    },
    [history]
  );

  const handleSubmitPreference = useCallback(
    async (event) => {
      setIsLoaded(true);
      event.preventDefault();
      try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        //idToken
        const results = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}me/accounts/preference`,
          {
            nickname: nicknameRef.current.value,
            email: emailRef.current.value,
            time_zone: time_zoneRef.current.value,
          },
          {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}` },
          }
        );
        setAppState({
          userInfo: { nickname: results.data.nickname, email: results.data.email, time_zone: results.data.time_zone },
          accessToken: state.accessToken,
          calendar: state.calendar,
          today: state.today,
          blockTime: {},
          calendarConnect: state.calendarConnect,
          baseTimeJson: JSON.parse(results.data.base_time_json),
          blockRange: {},
          blockDays: [],
          includingHoliday: state.includingHoliday,
          zoomConnect: state.zoomConnect,
        });
        setIsLoaded(false);
      } catch (error) {
        setIsLoaded(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
        console.log(error);
      }
    },
    [history]
  );

  const inputLengthValidation = (e) => {
    let isUserName = false;
    let isUserEmail = false;
    if (nicknameRef.current.value.length >= 2 && nicknameRef.current.value.length <= 15) {
      isUserName = true;
    }
    if (emailRef.current.value.length >= 6 && emailRef.current.value.length <= 254) {
      isUserEmail = true;
    }
    console.log(nicknameRef.current.value.length, emailRef.current.value.length);
    if (isUserName && isUserEmail) {
      setIsInputValid(false);
    } else {
      setIsInputValid(true);
    }
  };

  const charLengthValidation = (e, min, max) => {
    if (e.target.value.length < min || e.target.value.length > max) {
      e.target.classList.add("invalid");
    } else {
      e.target.classList.remove("invalid");
    }
  };

  return (
    <Layout title="プロフィール">
      <Header />
      <MainContent>
        <Content>
          <TabWrap>
            <TabList>
              <TabLink to="/profile" className="active">
                プロフィール
              </TabLink>
            </TabList>
            <TabList>
              <TabLink to="/setting">基本設定</TabLink>
            </TabList>
          </TabWrap>
          <ContentInnter>
            <Headings>ユーザー名</Headings>
            <TextFieldWrap>
              <TextField
                type="text"
                ref={nicknameRef}
                placeholder="表示名を入力ください"
                name="user-nickname"
                id="user-nickname"
                defaultValue={state.userInfo["nickname"]}
                onChange={(e) => {
                  charLengthValidation(e, 2, 15);
                  inputLengthValidation(e);
                }}
              />
            </TextFieldWrap>
            <Headings>メールアドレス</Headings>
            <TextFieldWrap>
              <TextField
                type="email"
                ref={emailRef}
                placeholder="連絡先として使用するメールアドレスを入力ください"
                name="user-email"
                id="user-email"
                defaultValue={state.userInfo["email"]}
                onChange={(e) => {
                  charLengthValidation(e, 6, 254);
                  inputLengthValidation(e);
                }}
              />
            </TextFieldWrap>
            <SelectCountryWrap>
              <Headings>国{state.userInfo["time_zone"]}</Headings>
              <SelectWrap>
                <SelectboxCountry value={state.userInfo["time_zone"]} disabled={true} ref={time_zoneRef}>
                  <SelectboxOption value="Asia/Tokyo">日本</SelectboxOption>
                  <SelectboxOption value="America/New_York">ニューヨーク</SelectboxOption>
                  <SelectboxOption value="Europe/London">ロンドン</SelectboxOption>
                  <SelectboxOption value="Europe/Paris">パリ</SelectboxOption>
                </SelectboxCountry>
              </SelectWrap>
            </SelectCountryWrap>
            <ButtonWrap>
              <ButtonPrimary disabled={isInputValid} onClick={handleSubmitPreference}>
                設定を保存
              </ButtonPrimary>
            </ButtonWrap>
            <ButtonHorizontalWrap>
              <ButtonPrimary disabled={false} onClick={handleSignOut}>
                ログアウト
              </ButtonPrimary>
              <ButtonPrimary disabled={false} type="submit" onClick={handleDetele}>
                アカウント削除
              </ButtonPrimary>
            </ButtonHorizontalWrap>
          </ContentInnter>
        </Content>
      </MainContent>
      <Footer />
      {
        <Error
          isShow={isError}
          errorMessage={errorMessage}
          close={() => {
            setIsError(false);
          }}
        />
      }
      {isLoaded && <Loading />}
    </Layout>
  );
};
