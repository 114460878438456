import React, { useState, useEffect, useRef, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Layout } from "../components/Layout";
import styled from "styled-components";
import { colors } from "../data/colors";
import { ReactComponent as AppLogo } from "../assets/app_logo.svg";
import { ReactComponent as AppTitle } from "../assets/app_title.svg";
import Footer from "../components/molecules/Footer";
import { ButtonPrimary, ButtonLink } from "../components/atoms/Button";
import { app } from "../lib/Base";
import { AppVersion } from "../components/atoms/AppVersion";
import { Error } from "../components/molecules/Error";
import { TextField } from "../components/atoms/TextField";
import { useHistory } from "react-router-dom";

interface PageProps extends RouteComponentProps<{ mode: string; oobCode: string; apiKey: string }> {}

const AppLogoWrap = styled(AppLogo)`
  width: auto;
  height: 108px;
`;

const AppTitleWrap = styled(AppTitle)`
  width: 396px;
  height: auto;
`;

const AppVersionWrap = styled.div`
  margin-bottom: 40px;
`;

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  padding-top: 40px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headings = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 216px;
  margin: 0 0 12px;
`;

const AttentionWrap = styled.div`
  margin: 0 0 40px;
  text-align: center;
  font-size: 8px;
`;

const AttentionText = styled.p`
  font-size: 16px;
  margin: 0;
`;

const TextFieldWrap = styled.div`
  width: 384px;
  margin-bottom: 16px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const Mailauthsuccess: React.FC<PageProps> = (props) => {
  const [mode, setMode] = useState(props.location.search.split("?")[1].split("=")[1].split("&")[0]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const oobCode = props.location.search.split("&")[1].split("=")[1];

  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);
  const [isSamePass, seIsSamePass] = useState(false);
  const [isComplated, seIsComplated] = useState(false);

  const samePasswordValidation = (e) => {
    if (passwordRef.current.value === passwordConfirmRef.current.value) {
      seIsSamePass(true);
    }
  };

  const handleUpdatePassword = useCallback(
    async (email) => {
      if (passwordRef.current.value !== passwordConfirmRef.current.value) {
        return;
      }
      await app
        .auth()
        .confirmPasswordReset(oobCode, passwordRef.current.value)
        .then((result) => {
          //リセットパスワードが完了してすぐにここでログインさせるならapp.auth().signInWithEmailAndPassword(accountEmail, newPassword);
          seIsComplated(true);
        })
        .catch((error) => {
          setIsError(true);
          setErrorMessage(error.message);
          console.log(error);
        });
    },
    [history]
  );

  const handleGetEmail = useCallback(
    async (event) => {
      await app
        .auth()
        .verifyPasswordResetCode(oobCode)
        .then((email) => {
          console.log(email);
          handleUpdatePassword(email);
        })
        .catch((error) => {
          setIsError(true);
          setErrorMessage(error.message);
          console.log(error);
        });
    },
    [history]
  );

  useEffect(() => {
    const fn = async () => {
      await app
        .auth()
        .applyActionCode(oobCode)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          setIsError(true);
          setErrorMessage(error.message);
          console.log(error);
        });
    };
    if (mode === "verifyEmail") fn();
  }, [history]);

  console.log(mode);
  //resetPassword, verifyEmail
  return (
    <Layout title="メール認証成功">
      <MainContent>
        <Content>
          <Headings>
            <AppLogoWrap />
            <AppTitleWrap />
          </Headings>
          <AppVersionWrap>
            <AppVersion />
          </AppVersionWrap>
          {mode === "verifyEmail" && (
            <>
              <AttentionWrap>
                <AttentionText>メール認証が完了しました。</AttentionText>
              </AttentionWrap>
              <ButtonLink to="/">ログインする</ButtonLink>
            </>
          )}
          {mode === "resetPassword" && !isComplated && (
            <>
              <AttentionWrap>
                <AttentionText>
                  パスワードの再設定を行います。
                  <br />
                  新たなパスワードを二回入力してください。
                </AttentionText>
              </AttentionWrap>
              <TextFieldWrap>
                <TextField
                  type="password"
                  ref={passwordRef}
                  placeholder="新しいパスワード"
                  name="password"
                  id="password"
                  onChange={(e) => {
                    samePasswordValidation(e);
                  }}
                />
              </TextFieldWrap>
              <TextFieldWrap>
                <TextField
                  type="password"
                  ref={passwordConfirmRef}
                  placeholder="新しいパスワード（確認）"
                  name="passwordconfirm"
                  id="passwordconfirm"
                  onChange={(e) => {
                    samePasswordValidation(e);
                  }}
                />
              </TextFieldWrap>
              <ButtonWrap>
                <ButtonPrimary
                  disabled={!isSamePass}
                  onClick={(e) => {
                    handleGetEmail(e);
                  }}
                >
                  再設定する
                </ButtonPrimary>
              </ButtonWrap>
            </>
          )}
          {mode === "resetPassword" && isComplated && (
            <>
              <AttentionWrap>
                <AttentionText>
                  パスワードを再設定しました。
                  <br />
                  ログインページよりログインしてください。
                </AttentionText>
              </AttentionWrap>
              <ButtonLink to="/">ログインする</ButtonLink>
            </>
          )}
        </Content>
      </MainContent>
      <Footer />
      {
        <Error
          isShow={isError}
          errorMessage={errorMessage}
          close={() => {
            setIsError(false);
          }}
        />
      }
    </Layout>
  );
};
