import React, { useRef, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Layout } from "../components/Layout";
import styled from "styled-components";
import { colors } from "../data/colors";
import { ReactComponent as AppLogo } from "../assets/app_logo.svg";
import { ReactComponent as AppTitle } from "../assets/app_title.svg";
import Footer from "../components/molecules/Footer";
import { ButtonPrimary } from "../components/atoms/Button";
import { TextField } from "../components/atoms/TextField";
import { AppVersion } from "../components/atoms/AppVersion";
import { AuthContext } from "../lib/AuthProvider";

const AppLogoWrap = styled(AppLogo)`
  width: auto;
  height: 108px;
`;

const AppTitleWrap = styled(AppTitle)`
  width: 396px;
  height: auto;
`;

const AppVersionWrap = styled.div`
  margin-bottom: 40px;
`;

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  padding-top: 40px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  width: 400px;
`;

const Headings = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 216px;
  margin: 0 0 12px;
`;

const TextFieldWrap = styled.div`
  margin-top: 16px;
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 40px;
  height: 60px;
`;

const AttentionWrap = styled.div`
  margin-top: 56px;
  text-align: center;
  font-size: 12px;
`;

const AttentionText = styled.p``;

const RelatedLink = styled.a`
  color: ${colors.orange.hex};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StrongText = styled.span`
  font-weight: bold;
`;

export const Signup: React.FC<{}> = () => {
  const history = useHistory();
  const emailRef = useRef(null);
  const passRef = useRef(null);

  const termsOfServiceUrl = 'https://timelab-kiyaku.netlify.app/terms_en.html';
  const privacyPolicyUrl = 'https://timelab-kiyaku.netlify.app/privacy_en.html';

  const { signup } = useContext(AuthContext);

  const handleSignUp = (event) => {
    event.preventDefault();
    signup(emailRef.current.value, passRef.current.value, history);
  };

  return (
    <Layout title="アカウント作成">
      <MainContent>
        <Content>
          <Headings>
            <AppLogoWrap />
            <AppTitleWrap />
          </Headings>
          <AppVersionWrap>
            <AppVersion />
          </AppVersionWrap>
          <TextFieldWrap>
            <TextField type="text" ref={emailRef} placeholder="Email" name="email" id="email" />
          </TextFieldWrap>
          <TextFieldWrap>
            <TextField type="password" ref={passRef} placeholder="Password" name="password" id="password" />
          </TextFieldWrap>
          <ButtonWrap>
            <ButtonPrimary disabled={false} onClick={handleSignUp}>
              アカウント作成
            </ButtonPrimary>
          </ButtonWrap>
          <AttentionWrap>
            <AttentionText>
              <StrongText>[アカウント作成]</StrongText>をクリックすることで、
              <br />
              <RelatedLink href={termsOfServiceUrl} target="_blank">
                サービス規約
              </RelatedLink>
              、
              <RelatedLink href={privacyPolicyUrl} target="_blank">
                プライバシーポリシー
              </RelatedLink>
              に、
              <br />
              同意するものとします。
            </AttentionText>
          </AttentionWrap>
        </Content>
      </MainContent>
      <Footer />
    </Layout>
  );
};
