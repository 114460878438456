import React from "react";
import styled from "styled-components";
import {ReactComponent as AppLogo} from "../assets/app_logo.svg";
import {ReactComponent as AppTitle} from "../assets/app_title.svg";
import {RelatedLink} from "../components/atoms/Button";
import {Layout} from "../components/Layout";
import Footer from "../components/molecules/Footer";
import {colors} from "../data/colors";


const AppLogoWrap = styled(AppLogo)`
  width: auto;
  height: 108px;
`;

const AppTitleWrap = styled(AppTitle)`
  width: 396px;
  height: auto;
`;

const TextWrap = styled.span`
  color: ${colors.orange.hex};
  display: inline-block;
  margin: 60px auto 40px;
  padding: 3px 4px 3px 19px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
`;

const SubTextWrap = styled.span`
  color: ${colors.gray.hex};
  display: inline-block;
  padding: 3px 4px 3px 19px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
`;

const AppVersionWrap = styled.div`
  margin-bottom: 40px;
`;

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  padding-top: 40px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  width: 400px;
`;

const PageTitle = styled.h1`
  font-size: 34px;
  letter-spacing: 0.28em;
  color: ${colors.keyColor.hex};
  margin: 0 4px 0;
  padding: 0;
  text-align: center;
`;

const PageTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: 8px;
  background-color: ${colors.white.hex};
  border-bottom: 1px solid ${colors.whiteAccent.hex};
`;

const MessagBoard = styled.div`
   background-color: white;
`


const ButtonPrimary = styled(RelatedLink)`
  margin: 30px auto 30px;
  width: 296px;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
`;

const ButtonSubText = styled.p`
  font-size: 14px;
`;


export const Success: React.FC<{}> = (props) => {

    return (
        <Layout title="SUCCESS">
            <MainContent>
                <Content>
                    <PageTitleWrap>
                        <PageTitle>SUCCESS</PageTitle>
                    </PageTitleWrap>
                    <MessagBoard>
                        <TextWrap>連携処理が正常に完了しました</TextWrap>
                        <SubTextWrap>
                            <p>Cooperation process has completed.</p>
                            <p>Please re-enter from the top page.</p>
                        </SubTextWrap>
                    </MessagBoard>
                    <ButtonPrimary href="/home">
                        <ButtonSubText>OK</ButtonSubText>
                    </ButtonPrimary>
                </Content>
            </MainContent>
            <Footer/>
        </Layout>
    )
}