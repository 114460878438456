import React, { useEffect, useState, useRef, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Layout } from "../components/Layout";
import styled from "styled-components";
import axios from "axios";
import moment, { now } from "moment";
import { colors } from "../data/colors";
import { ReactComponent as AppLogo } from "../assets/app_logo.svg";
import { ReactComponent as AppTitle } from "../assets/app_title.svg";
import Footer from "../components/molecules/Footer";
import { makeCalendar } from "../lib/Calendar";
import { ReactComponent as IcoArrow } from "../assets/ico_arrow.svg";
import { ButtonPrimary, RelatedLink } from "../components/atoms/Button";
import { Timeline } from "../components/atoms/Timeline";
import { DateTimeline } from "../components/molecules/DateTimeline";
import { Date } from "../components/molecules/Date";
import { TextField } from "../components/atoms/TextField";
import { Textarea } from "../components/atoms/Textarea";
import { TextFieldWrapper } from "../components/molecules/TextFiledWrapper";
import { whatWeek, addFreebusy } from "../lib/Calendar";
import { Loading } from "../components/molecules/Loading";
import { ReactComponent as IcoArrowRounded } from "../assets/ico_arrow-rounded.svg";
import { Error } from "../components/molecules/Error";

moment.locale("ja", {
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
});

interface PageProps extends RouteComponentProps<{}> {}

interface ContentProps {
  isInfoOpen: boolean;
}

const HeaderElement = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 800;
  background-color: ${colors.white.hex};
  border-bottom: 1px solid ${colors.groundDark.hex};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyLogoHeadings = styled.h1`
  display: block;
  width: 41px;
  height: 25px;
  margin: -14px 8px 0;
  padding: 0;
  font-size: 0;
`;

const AppLogoWrap = styled(AppLogo)`
  width: 40px;
  height: 40px;
`;

const CompanyLogoContentWrap = styled(AppLogo)`
  width: 70px;
  height: 45px;
  margin: 40px 0 30px;
`;

const AppTitleWrap = styled(AppTitle)`
  width: 141px;
  height: auto;
`;

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  padding-top: 56px;
  background-color: ${colors.white.hex};
`;

const ContentWrap = styled.div<ContentProps>`
  position: absolute;
  top: 140px;
  left: 120px;
  width: 100%;
  background-color: ${colors.white.hex};
  width: calc(100% - 240px);
  height: calc(100% - 88px);
  padding: 16px;
`;

const ContentHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ButtonPrimarySuqure30x30 = styled(ButtonPrimary)`
  width: 30px;
  height: 30px;
  min-width: 30px;
  border: 0;
`;

const DateWrap = styled.div`
  display: flex;
  width: 100%;
  margin: 0 16px;
`;

const IcoArrowPrevWrap = styled(IcoArrow)`
  fill: ${colors.keyColor.hex};
  width: 15px;
  height: 15px;
`;

const IcoArrowNextWrap = styled(IcoArrow)`
  fill: ${colors.keyColor.hex};
  width: 15px;
  height: 15px;
  transform: scaleX(-1);
`;

const TimelineWrap = styled.div`
  display: flex;
  height: calc(100% - 90px);
  overflow-y: scroll;
  padding: 8px 10px 0 0;
`;

const IcoArrowDRoundedWrap = styled(IcoArrowRounded)`
  fill: ${colors.keyColor.hex};
  height: 24px;
`;

const ButtonPrimarySquareLarge = styled(ButtonPrimary)`
  position: absolute;
  bottom: 46px;
  right: 26px;
  width: 64px;
  height: 64px;
  &:disabled {
    svg {
      fill: ${colors.buttonGrayText.hex};
    }
  }
`;

const ButtonIconSubText = styled.p`
  width: calc(100% / 0.8);
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  margin: 4px 0 0;
  padding: 0;
  transform: scale(0.8);
  transform-origin: center left;
`;

const Stepbar = styled.div`
  width: 100%;
  height: 56px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepWrap = styled.ul`
  width: 288px;
  height: 32px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

const Step = styled.li`
  width: 80px;
  height: 32px;
  background-color: ${colors.silverGray.hex};
  color: rgba(167, 164, 162, 1);
  text-align: center;
  font-size: 10px;
  line-height: 14px;
  padding-top: 2px;
  &.active {
    background-color: ${colors.white.hex};
    color: ${colors.orange.hex};
  }
`;

const InfoContainerWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  width: 100%;
  height: 65px;
  background-color: ${colors.ground.hex};
`;

const InfoContent = styled.div`
  width: 862px;
  margin: auto;
`;

const Headings = styled.p`
  color: #a7a4a2;
  font-size: 10px;
  margin: 0 0 4px;
`;

const TitleWrap = styled.div`
  width: 282px;
  height: 28px;
  font-size: 14px;
  line-height: 1;
  color: ${colors.orange.hex};
`;

const ExpirWrap = styled.div`
  width: 180px;
  height: 28px;
  font-size: 14px;
  line-height: 1;
  color: ${colors.orange.hex};
`;

const TextWrap = styled.div`
  width: 320px;
  height: 48px;
  font-size: 14px;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const InfoHorizontalWrap = styled.div`
  width: 862px;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HorizontalWrap = styled.div`
  width: 360px;
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

const EmptyWrap = styled.div``;

const ConfirmContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const ConfirmContent = styled.div`
  width: 620px;
  min-height: 487px;
  background-color: ${colors.white.hex};
  margin-top: 60px;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
`;

const ConfirmHeadings = styled.p`
  width: 100%;
  height: 40px;
  font-size: 20px;
  color: ${colors.keyColor.hex};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white.hex};
  margin: 0;
  border-bottom: 1px solid ${colors.whiteAccent.hex};
`;

const InputHeadings = styled.p`
  margin: 0 0 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  width: 100%;
  text-align: center;
`;

const InputHeadingsWrap = styled.div`
  width: 540px;
`;

const TextFieldWrap = styled.div`
  font-size: 10px;
  margin-bottom: 16px;
  color: ${colors.darkGray.hex};
  width: 100%;
`;

const ConfirmContentBody = styled.div`
  width: 540px;
  margin: 0 auto;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlanMessageText = styled.p`
  font-size: 12px;
  color: ${colors.darkGray.hex};
  margin: 0;
  width: 100%;
  strong {
    font-weight: bold;
  }
`;

const PlanTime = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin: 0 0 12px;
  text-align: center;
  &.fix {
    color: ${colors.darkGray.hex};
  }
`;

const ButtonPrimaryMiddle = styled(ButtonPrimary)`
  width: 140px;
  height: 48px;
`;

const BottomButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ButtonPrimaryAddCalendar = styled(RelatedLink)`
  width: 296px;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
`;

const ButtonSubText = styled.p`
  font-size: 14px;
`;

const SubCopy = styled.p`
  width: 100%;
  font-size: 12px;
  color: ${colors.middleGray.hex};
  text-align: center;
  margin: 20px 0 12px;
`;

const Required = styled.span`
  color: ${colors.white.hex};
  font-size: 10px;
  padding: 0 10px;
  background-color: ${colors.orange.hex};
  margin-left: 12px;
`;

const AttentionHostText = styled.div`
  font-size: 16px;
  width: 540px;
  padding: 12px 20px;
  border: 1px solid ${colors.orange.hex};
  color: ${colors.darkGray.hex};
  margin-top: 12px;
  text-align: center;
  background: #f0f0f5;
`;

const HostInfo = styled.p`
  color: ${colors.orange.hex};
  font-weight: bold;
  margin: 0;
`;

const MeetingUrlWrap = styled.div`
  margin: 10px 0 10px;
`;

const MeetingUrlTitle = styled.p`
  margin: 0;
`;

const MeetingUrlText = styled.div`
  color: ${colors.orange.hex};
  font-weight: bold;
  margin: 0;
`;

const Deadline = styled.p`
  width: 540px;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

const DateTimelineWrap = styled.div`
  width: 100%;
  margin: 0 16px 0 0;
  display: flex;
`;

const DateConfirmWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 537px;
`;

const WrapLeft = styled.div`
  width: 307px;
`;

const WrapRight = styled.div`
  width: 214px;
  padding-bottom: 14px;
`;

const TextFieldH32 = styled(TextField)`
  height: 32px;
`;

const TextareaH162 = styled(Textarea)`
  height: 162px;
`;

const Tooltip = styled.span`
  display: none;
`;

const TextWrapContainer = styled.div`
  position: relative;
  cursor: default;
  &:hover span {
    position: absolute;
    top: 0;
    left: 0;
    width: 600px;
    display: inline-block;
    padding: 10px 15px;
    background-color: ${colors.white.hex};
    z-index: 300;
    border-radius: 4px;
    border: 1px solid ${colors.gray.hex};
  }
`;

export const Invitation: React.FC<PageProps> = (props) => {
  const params = props.location.search;

  let urlCode: string;         // eventの場合はurl_code, templateの場合はuuid
  let mode: string;　          // url_codeの場合はevent, uuidの場合はtemplate
  let getRequestPath: string;  // eventとtemplateで異なるURLにGETするため

  if (params.indexOf("&") > -1) {
    //URLに想定外の&でパラメーターを付与されてしまった場合（messangerなどで勝手に付与される）
    const paramsArray = params.split("&");
    mode = paramsArray[0].split("=")[0];
    urlCode = paramsArray[0].split("=")[1];
  } else {
    mode = params.split("?")[1].split("=")[0];
    urlCode = params.split("?")[1].split("=")[1];
  }

  if (mode === "uuid") {
    getRequestPath = `${process.env.REACT_APP_API_BASE_URL}events/template_freebusy/${urlCode}`
  } else if (mode === "url_code") {
    getRequestPath = `${process.env.REACT_APP_API_BASE_URL}events/query_freebusy/${urlCode}`
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSelected, setIsSelected] = useState(true);
  const [isInfoOpen, setIsInfoOpen] = useState(true);
  const [weekCounter, setWeekCounter] = useState(0);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [expire, setExpire] = useState("");
  const [step, setStep] = useState(0);
  const [uuid, setUuid] = useState("");
  const [fixTime, setFixtime] = useState({ startTime: "", endTime: "" });

  const [commitAt, setCommitAt] = useState("");
  const [displayTimePeriod, setDisplayTimePeriod] = useState("");
  const [registrationUrl, setRegistrationUrl] = useState("");
  const [displayHost, setDisplayHost] = useState("");

  const [isConfirmInputValid, setIsConfirmValid] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const usernameRef = useRef(null);
  const userCompanyRef = useRef(null);
  const userEmailRef = useRef(null);
  const userMessageRef = useRef(null);
  const timelineRef = useRef(null);

  const [userName, setUserName] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const [meetingUrl, setMeetingUrl] = useState("");

  const [weekArray, setWeekArray] = useState([
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
  ]);
  const [calendar, setCalendar] = useState([]);

  const prevWeek = () => {
    const currentWeekIndex = weekCounter - 1;
    setWeekCounter(currentWeekIndex);
    setWeekArray(calendar[currentWeekIndex]);
  };
  const nextWeek = () => {
    const currentWeekIndex = weekCounter + 1;
    setWeekCounter(currentWeekIndex);
    setWeekArray(calendar[currentWeekIndex]);
  };

  const addSelected = (startTime, endTime, _uuid) => {
    console.log("removeSelected", _uuid);
    setUuid(_uuid);
    setFixtime({ startTime: startTime, endTime: endTime });
    setIsSelected(false);
  };

  const removeSelected = (startTime, endTime, _uuid) => {
    console.log("removeSelected", _uuid);
    setUuid("");
    setFixtime({ startTime: "", endTime: "" });
    setIsSelected(true);
  };

  useEffect(() => {
    if (step === 1) {
      setIsInfoOpen(true);
      timelineRef.current.scrollTo(0, 500);
    }
  }, [step]);

  useEffect(() => {
    const fn = async () => {
      setIsLoaded(true);

      try {
        const results = await axios.get(getRequestPath, {
          headers: {
            accept: "application/json",
          },
        });

        setStep(1);
        const _calendar = makeCalendar(moment(results.data.current_time).format("YYYY"));
        setWeekCounter(10);

        // freebusyが空配列（ホスト側で全ての曜日のenabledをfalseにした）の場合は本日の日付をセット。
        const firstDate = results.data.freebusy.length ?
          moment(results.data.freebusy[0]["start_time"]).format("YYYY-MM-DD") :
          moment(now()).format("YYYY-MM-DD");

        const currentWeekIndex = whatWeek(_calendar, firstDate);
        setWeekCounter(currentWeekIndex);
        setWeekArray(_calendar[currentWeekIndex]);

        const newCalendar = addFreebusy(_calendar, results.data.freebusy);
        setCalendar(newCalendar);
        setIsLoaded(false);
        setTitle(results.data.time_query.event_title);
        setMessage(results.data.time_query.event_message);
        setExpire(moment(results.data.time_query.expires_at).format("YYYY/MM/DD HH:mm"));
        setDisplayHost(`${results.data.time_query.host_nickname}（${results.data.time_query.host_email}）`);
      } catch (error) {
        console.log(error);
        setIsLoaded(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
      }
    };
    fn();
  }, []);

  const confirmInputValidation = (e) => {
    let isUserName = false;
    let isUserCompany = false;
    let isUserEmail = false;
    let isUserMessage = false;
    if (usernameRef.current.value.length >= 2 && usernameRef.current.value.length <= 15) {
      isUserName = true;
    }
    if (userCompanyRef.current.value.length >= 1 && userCompanyRef.current.value.length <= 30) {
      isUserCompany = true;
    }
    if (userEmailRef.current.value.length >= 6 && userEmailRef.current.value.length <= 254) {
      isUserEmail = true;
    }
    if (userMessageRef.current.value.length <= 2000) {
      isUserMessage = true;
    }
    if (isUserName && isUserCompany && isUserEmail && isUserMessage) {
      setIsConfirmValid(false);
    } else {
      setIsConfirmValid(true);
    }
    setUserName(usernameRef.current.value);
    setUserCompany(userCompanyRef.current.value);
    setUserEmail(userEmailRef.current.value);
    setUserMessage(userMessageRef.current.value);
  };

  const postAppointments = useCallback(async (startTime, endTime, _uuid) => {
    setIsLoaded(false);
    const data = {
      uuid: urlCode,
      time_min: startTime,
      time_max: endTime,
      guest_name: usernameRef.current.value,
      guest_company: userCompanyRef.current.value,
      guest_comment: userMessageRef.current.value,
      guest_email: userEmailRef.current.value,
      kind: mode === "uuid" ? "template" : "query",
    };

    try {
      const results = await axios.post(`${process.env.REACT_APP_API_BASE_URL}events/appointments`, data, {
        headers: {
          accept: "application/json",
        },
      });
      setStep(3);
      setCommitAt(moment(results.data.commit_at).format("YYYY/MM/DD HH:mm"));
      setDisplayTimePeriod(results.data.display_time_period);
      setRegistrationUrl(results.data.registration_url);
      setDisplayHost(`${results.data.host_nickname}（${results.data.host_email}）`);
      setMeetingUrl(results.data.meeting_url);
      setIsLoaded(false);
    } catch (error) {
      setIsLoaded(false);
      setIsError(true);
      setErrorMessage(error.response.data.message);
      console.log(error);
    }
  }, []);

  const charLengthValidation = (e, min, max) => {
    if (e.target.value.length < min || e.target.value.length > max) {
      console.log("invalid");
      e.target.classList.add("invalid");
    } else {
      console.log("valid");
      e.target.classList.remove("invalid");
    }
  };

  return (
    <Layout title="予定調整依頼">
      <HeaderElement>
        <CompanyLogoHeadings>
          <AppLogoWrap />
        </CompanyLogoHeadings>
        <Stepbar>
          <StepWrap>
            <Step className={step === 1 ? "active" : ""}>
              STEP1
              <br />
              日時選択
            </Step>
            <Step className={step === 2 ? "active" : ""}>
              STEP2
              <br />
              情報入力
            </Step>
            <Step className={step === 3 ? "active" : ""}>
              STEP3
              <br />
              日程確定
            </Step>
          </StepWrap>
        </Stepbar>
      </HeaderElement>
      <MainContent>
        <InfoContainerWrap>
          <InfoContainer>
            <InfoContent>
              <InfoHorizontalWrap>
                <EmptyWrap>
                  <Headings>タイトル</Headings>
                  <TitleWrap>{title}</TitleWrap>
                </EmptyWrap>
                {mode === "url_code" &&
                <EmptyWrap>
                  <Headings>対応期限</Headings>
                  <ExpirWrap>{expire}まで</ExpirWrap>
                </EmptyWrap>
                }
                <TextWrapContainer>
                  <TextWrap>{message}</TextWrap>
                  <Tooltip>{message}</Tooltip>
                </TextWrapContainer>
              </InfoHorizontalWrap>
            </InfoContent>
          </InfoContainer>
        </InfoContainerWrap>
        {step === 1 && (
          <>
            <ContentWrap isInfoOpen={isInfoOpen}>
              <ContentHeaderWrap>
                <ButtonPrimarySuqure30x30
                  onClick={() => {
                    prevWeek();
                  }}
                >
                  <IcoArrowPrevWrap />
                </ButtonPrimarySuqure30x30>
                <DateWrap>
                  <Date data={weekArray[0]} day="月" isGuest={true} />
                  <Date data={weekArray[1]} day="火" isGuest={true} />
                  <Date data={weekArray[2]} day="水" isGuest={true} />
                  <Date data={weekArray[3]} day="木" isGuest={true} />
                  <Date data={weekArray[4]} day="金" isGuest={true} />
                  <Date data={weekArray[5]} day="土" isGuest={true} />
                  <Date data={weekArray[6]} day="日" isGuest={true} />
                </DateWrap>
                <ButtonPrimarySuqure30x30
                  onClick={() => {
                    nextWeek();
                  }}
                >
                  <IcoArrowNextWrap />
                </ButtonPrimarySuqure30x30>
              </ContentHeaderWrap>
              <TimelineWrap ref={timelineRef}>
                <Timeline />
                <DateTimelineWrap>
                  <DateTimeline data={weekArray[0]} day="月" addFunction={addSelected} removeFunction={removeSelected} isGuest={true} isBusyOnly={true} />
                  <DateTimeline data={weekArray[1]} day="火" addFunction={addSelected} removeFunction={removeSelected} isGuest={true} isBusyOnly={true} />
                  <DateTimeline data={weekArray[2]} day="水" addFunction={addSelected} removeFunction={removeSelected} isGuest={true} isBusyOnly={true} />
                  <DateTimeline data={weekArray[3]} day="木" addFunction={addSelected} removeFunction={removeSelected} isGuest={true} isBusyOnly={true} />
                  <DateTimeline data={weekArray[4]} day="金" addFunction={addSelected} removeFunction={removeSelected} isGuest={true} isBusyOnly={true} />
                  <DateTimeline data={weekArray[5]} day="土" addFunction={addSelected} removeFunction={removeSelected} isGuest={true} isBusyOnly={true} />
                  <DateTimeline data={weekArray[6]} day="日" addFunction={addSelected} removeFunction={removeSelected} isGuest={true} isBusyOnly={true} />
                </DateTimelineWrap>
              </TimelineWrap>
            </ContentWrap>
            <ButtonPrimarySquareLarge
              disabled={isSelected}
              onClick={() => {
                setStep(2);
                setIsInfoOpen(false);
                const start = moment(fixTime.startTime).format("MM/DD(ddd) HH:mm");
                const end = moment(fixTime.endTime).format("HH:mm");
                setDisplayTimePeriod(`${start} - ${end}`);
              }}
            >
              <IcoArrowDRoundedWrap />
              <ButtonIconSubText>決定</ButtonIconSubText>
            </ButtonPrimarySquareLarge>
          </>
        )}
        {step === 2 && (
          <ConfirmContainer>
            <ConfirmContent>
              <ConfirmHeadings>情報の入力</ConfirmHeadings>
              <ConfirmContentBody>
                <DateConfirmWrap>
                  <WrapLeft>
                    <InputHeadings>開催日時</InputHeadings>
                    <PlanTime>{displayTimePeriod}</PlanTime>
                    <InputHeadings>
                      お名前<Required>必須</Required>
                    </InputHeadings>
                    <TextFieldWrap>
                      <TextFieldWrapper min={1} max={15} count={userName.length}>
                        <TextFieldH32
                          type="text"
                          ref={usernameRef}
                          placeholder="タイムラボ太郎"
                          name="user-name"
                          id="user-name"
                          defaultValue={""}
                          onChange={(e) => {
                            confirmInputValidation(e);
                            charLengthValidation(e, 2, 15);
                          }}
                        />
                      </TextFieldWrapper>
                    </TextFieldWrap>
                    <InputHeadings>
                      会社名<Required>必須</Required>
                    </InputHeadings>
                    <TextFieldWrap>
                      <TextFieldWrapper min={1} max={30} count={userCompany.length}>
                        <TextFieldH32
                          type="text"
                          ref={userCompanyRef}
                          placeholder="株式会社タイムラボ"
                          name="user-company"
                          id="user-company"
                          defaultValue={""}
                          onChange={(e) => {
                            confirmInputValidation(e);
                            charLengthValidation(e, 1, 30);
                          }}
                        />
                      </TextFieldWrapper>
                    </TextFieldWrap>
                    <InputHeadings>
                      メールアドレス<Required>必須</Required>
                    </InputHeadings>
                    <TextFieldWrap>
                      <TextFieldWrapper min={1} max={254} count={userEmail.length}>
                        <TextFieldH32
                          type="email"
                          ref={userEmailRef}
                          placeholder="you@example.com"
                          name="user-email"
                          id="user-email"
                          defaultValue={""}
                          onChange={(e) => {
                            confirmInputValidation(e);
                            charLengthValidation(e, 6, 254);
                          }}
                        />
                      </TextFieldWrapper>
                    </TextFieldWrap>
                  </WrapLeft>
                  <WrapRight>
                    <InputHeadings>事前の共有事項など</InputHeadings>
                    <TextFieldWrapper min={-1} max={200} count={userMessage.length}>
                      <TextareaH162
                        ref={userMessageRef}
                        placeholder="事前の共有事項など"
                        maxLength={200}
                        onChange={(e) => {
                          confirmInputValidation(e);
                        }}
                      />
                    </TextFieldWrapper>
                  </WrapRight>
                </DateConfirmWrap>
                <HorizontalWrap>
                  <BottomButtonWrap
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    <ButtonPrimaryMiddle>戻る</ButtonPrimaryMiddle>
                  </BottomButtonWrap>
                  <BottomButtonWrap
                    onClick={() => {
                      postAppointments(fixTime.startTime, fixTime.endTime, uuid);
                    }}
                  >
                    <ButtonPrimaryMiddle disabled={isConfirmInputValid}>決定</ButtonPrimaryMiddle>
                  </BottomButtonWrap>
                </HorizontalWrap>
              </ConfirmContentBody>
            </ConfirmContent>
          </ConfirmContainer>
        )}
        {step === 3 && (
          <ConfirmContainer>
            <ConfirmContent>
              <ConfirmHeadings>日程の確定</ConfirmHeadings>
              <ConfirmContentBody>
                <InputHeadingsWrap>
                  <InputHeadings>あなたが選択した日時</InputHeadings>
                  <PlanTime className="fix">{displayTimePeriod}</PlanTime>
                </InputHeadingsWrap>
                {userMessage && (
                  <PlanMessageText>
                    <strong>あなたからのメッセージ:</strong> {userMessage}
                  </PlanMessageText>
                )}
                <AttentionHostText>
                  <HostInfo>{displayHost}</HostInfo>のカレンダーに上記の日程で登録されました。
                  { !!meetingUrl && (
                    <MeetingUrlWrap>
                      <MeetingUrlTitle>参加用のURLはこちらになります。</MeetingUrlTitle>
                      <MeetingUrlText>{meetingUrl}</MeetingUrlText>
                    </MeetingUrlWrap>
                  )}
                </AttentionHostText>
                <Deadline>※日程を変更する場合は、ホストまでご連絡お願いします。</Deadline>
                <BottomButtonWrap>
                  <ButtonPrimaryAddCalendar href={registrationUrl} target="_blank">
                    <ButtonSubText>Googleカレンダーに登録</ButtonSubText>
                  </ButtonPrimaryAddCalendar>
                </BottomButtonWrap>
                <SubCopy>
                  TimeLab Apは、カレンダーと連携して
                  <br />
                  面倒な日程調整を自動化してくれる便利なサービスです。
                </SubCopy>
                <ButtonPrimaryAddCalendar href="/">
                  <ButtonSubText>TimeLab Apを無料で使ってみる</ButtonSubText>
                </ButtonPrimaryAddCalendar>
                <CompanyLogoContentWrap />
                <AppTitleWrap />
              </ConfirmContentBody>
            </ConfirmContent>
          </ConfirmContainer>
        )}
      </MainContent>
      <Footer />
      {
        <Error
          isShow={isError}
          errorMessage={errorMessage}
          close={() => {
            setIsError(false);
          }}
        />
      }
      {isLoaded && <Loading />}
    </Layout>
  );
};
