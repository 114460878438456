import React, { useState, useEffect, useCallback, useRef } from "react";
import { app } from "../lib/Base";
import { Link, useHistory } from "react-router-dom";
import { Layout } from "../components/Layout";
import { Header } from "../components/molecules/Header";
import Footer from "../components/molecules/Footer";
import styled from "styled-components";
import { colors } from "../data/colors";
import axios from "axios";
import { ButtonPrimary, RelatedLink } from "../components/atoms/Button";
import { Loading } from "../components/molecules/Loading";
import { ModalRemoveCalendar } from "../components/molecules/ModalRemoveCalendar";
import { ModalRemoveZoom } from "../components/molecules/ModalRemoveZoom";
import { Selectbox, SelectboxOption } from "../components/atoms/Selectbox";
import { ReactComponent as IcoAddSquare } from "../assets/ico_add-square.svg";
import { ReactComponent as IcoRemoveSquare } from "../assets/ico_remove-square.svg";
import { CheckboxDay } from "../components/atoms/CheckboxDay";
import { useAppState, useSetAppState } from "../lib/Store";
import { Error } from "../components/molecules/Error";

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 656px;
  height: 540px;
  background-color: ${colors.white.hex};
  margin-top: 60px;
  display: flex;
`;

const ContentInnter = styled.div`
  width: 504px;
  margin: 0 auto;
  padding: 0;
`;

const TabWrap = styled.ul`
  width: 152px;
  list-style-type: none;
  margin: 40px 20px 0 0;
  padding: 0;
`;

const TabList = styled.li``;

const TabLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.ground.hex};
  font-size: 14px;
  font-weight: bold;
  color: ${colors.keyColor.hex};
  width: 152px;
  height: 36px;
  text-decoration: none;
  border-bottom: 2px solid ${colors.keyColor.hex};
  &.active {
    background-color: ${colors.white.hex};
    color: ${colors.buttonGrayText.hex};
    border-bottom: 2px solid ${colors.buttonGrayText.hex};
  }
`;

const Headings = styled.h2`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black.hex};
  margin: 20px 0 8px;
`;

const TextWrap = styled.span`
  font-size: 12px;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 6px;
  width: 376px;
  height: 32px;
  background-color: ${colors.whiteAccent.hex};
  margin-right: 20px;
`;

const ButtonWrap = styled.div`
  margin: 40px auto 0;
  width: 184px;
`;

const SelectTimeWrap = styled.div`
  display: inline-block;
  width: 120px;
  margin-right: 20px;
`;

const SubHeadings = styled.h3`
  font-size: 10px;
  color: ${colors.middleGray.hex};
  margin: 0 12px 0 0;
  display: inline-block;
  line-height: 0;
  transform: translateY(-5px);
`;

const SelectWrap = styled.div`
  width: 85px;
  position: relative;
  display: inline-block;
`;

const SelectboxTime = styled(Selectbox)`
  font-size: 14px;
`;

const IcoAddSquareWrap = styled(IcoAddSquare)`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;

const IcoRemoveSquareWrap = styled(IcoRemoveSquare)`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;

const DaysWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 504px;
`;

const ButtonPrimarySquareAddCalendar = styled(RelatedLink)`
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  line-height: 0;
`;

const ButtonPrimarySquareRemoveCalendar = styled(ButtonPrimary)`
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  line-height: 0;
`;

// 追加：Zoom連携用の画面フォームにリダイレクトさせるボタン
const ButtonPrimarySquareAddZoom = styled(RelatedLink)`
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  line-height: 0;
`;

// 追加：Zoom連携解除用のボタン
const ButtonPrimarySquareRemoveZoom = styled(ButtonPrimary)`
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  line-height: 0;
`;

const HorizontalLayout = styled.div`
  width: 438px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Setting: React.FC<{}> = () => {
  const history = useHistory();
  const state = useAppState();
  const setAppState = useSetAppState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedRemoveCalenar, setIsLoadedRemoveCalenar] = useState(false);
  const [isLoadedRemoveZoom, setIsLoadedRemoveZoom] = useState(false);
  const userBusinessStartRef = useRef(null);
  const userBusinessEndRef = useRef(null);
  const [startTime, setStartTime] = useState("10:00");
  const [endTime, setEndTime] = useState("21:00");

  const [myAccessToken, setMyAccessToken] = useState("");

  const connectUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URL}&scope=https://www.googleapis.com/auth/calendar&response_type=code&access_type=offline&prompt=consent&state=${myAccessToken}`;

  // Zoom連携用の画面フォーム
  const zoomConnectUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_OAUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ZOOM_OAUTH_REDIRECT_URL}&state=${myAccessToken}`;

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [authorizationId, setAuthorizationId] = useState("");

  const [isOpenRemoveCalendar, setIsOpenRemoveCalendar] = useState(false);
  const [isOpenRemoveZoom, setIsOpenRemoveZoom] = useState(false);


  const [days, setDays] = useState([
    { label: "Mon.", weekindex: 1, name: "月曜", default: true },
    { label: "Tue.", weekindex: 2, name: "火曜", default: true },
    { label: "Wed.", weekindex: 3, name: "水曜", default: true },
    { label: "Thu.", weekindex: 4, name: "木曜", default: true },
    { label: "Fir.", weekindex: 5, name: "金曜", default: true },
    { label: "Sat.", weekindex: 6, name: "土曜", default: false },
    { label: "San.", weekindex: 0, name: "日曜", default: false },
    { label: "Holiday", weekindex: 7, name: "祝日", default: false },
  ]);

  const hours = ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];
  const changeStartHour = (event) => {
    setStartTime(event.target.value);
    let tmpBaseTimeJson = JSON.parse(JSON.stringify(state.baseTimeJson));
    tmpBaseTimeJson.map((item) => {
      item["start"] = event.target.value;
    });
    setAppState({
      userInfo: state.userInfo,
      accessToken: state.accessToken,
      calendar: state.calendar,
      today: state.today,
      blockTime: {},
      calendarConnect: state.calendarConnect,
      baseTimeJson: tmpBaseTimeJson,
      blockRange: {},
      blockDays: [],
      includingHoliday: state.includingHoliday,
      zoomConnect: state.zoomConnect,
    });
  };

  const changeEndHour = (event) => {
    setEndTime(event.target.value);
    let tmpBaseTimeJson = JSON.parse(JSON.stringify(state.baseTimeJson));
    tmpBaseTimeJson.map((item) => {
      item["end"] = event.target.value;
    });
    setAppState({
      userInfo: state.userInfo,
      accessToken: state.accessToken,
      calendar: state.calendar,
      today: state.today,
      blockTime: {},
      calendarConnect: state.calendarConnect,
      baseTimeJson: tmpBaseTimeJson,
      blockRange: {},
      blockDays: [],
      includingHoliday: state.includingHoliday,
      zoomConnect: state.zoomConnect,
    });
  };

  const changeBusinessDay = (weekindex, event) => {
    let tmpBaseTimeJson = JSON.parse(JSON.stringify(state.baseTimeJson));
    tmpBaseTimeJson.map((item, index) => {
      if (item["weekindex"] === weekindex) {
        item["enabled"] = event.target.checked;
      }
    });
    let includingHoliday = false;
    if (weekindex === 7) {
      includingHoliday = event.target.checked;
    }
    setAppState({
      userInfo: state.userInfo,
      accessToken: state.accessToken,
      calendar: state.calendar,
      today: state.today,
      blockTime: {},
      calendarConnect: state.calendarConnect,
      baseTimeJson: tmpBaseTimeJson,
      blockRange: {},
      blockDays: [],
      includingHoliday: includingHoliday,
      zoomConnect: state.zoomConnect,
    });
  };

  const removeCalendar = useCallback(
      async (event, id) => {
        setIsLoaded(true);
        try {
          //ここでGETメソッドを使用してgithubのプロフィールを取得します。
          //idToken
          const results = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}me/authorizations/${id}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}` },
          });
          setIsLoaded(false);
          setIsLoadedRemoveCalenar(true);

          // 不要と思われるの削除
          //handleSubmitPreference(event, state.baseTimeJson, state.includingHoliday, state.accessToken);
        } catch (error) {
          setIsLoaded(false);
          setIsError(true);
          setErrorMessage(error.response.data.message);
        }
      },
      [history]
  );

  const removeZoom = useCallback(
      async (event, id) => {
        setIsLoaded(true);
        try {
          //ここでGETメソッドを使用してgithubのプロフィールを取得します。
          //idToken
          const results = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}me/authorizations/zoom/${id}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}` },
          });
          setIsLoaded(false);
          setIsLoadedRemoveZoom(true);
        } catch (error) {
          setIsLoaded(false);
          setIsError(true);
          setErrorMessage(error.response.data.message);
        }
      },
      [history]
  );

  const getCalenarId = useCallback(
      async (event) => {
        setIsLoaded(true);
        try {
          //ここでGETメソッドを使用してgithubのプロフィールを取得します。
          //idToken
          const results = await axios.get(`${process.env.REACT_APP_API_BASE_URL}me/authorizations`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}` },
          });

          const googleAuthorization = results.data.find(x => x.provider === 'google')
          if (googleAuthorization !== undefined) {
            removeCalendar(event, googleAuthorization.id);
          }
        } catch (error) {
          setIsLoaded(false);
          setIsError(true);
          setErrorMessage(error.response.data.message);
        }
      },
      [history]
  );

  const getZoomId = useCallback(
      async (event) => {
        setIsLoaded(true);
        try {
          //ここでGETメソッドを使用してgithubのプロフィールを取得します。
          //idToken
          const results = await axios.get(`${process.env.REACT_APP_API_BASE_URL}me/authorizations`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}` },
          });

          const zoomAuthorization = results.data.find(x => x.provider === 'zoom');
          if (zoomAuthorization !== undefined) {
            removeZoom(event, zoomAuthorization.id);
          }
        } catch (error) {
          setIsLoaded(false);
          setIsError(true);
          setErrorMessage(error.response.data.message);
        }
      },
      [history]
  );

  const handleSubmitPreference = useCallback(
      async (event, baseTimeJson, includingHoliday, _token) => {
        console.log(baseTimeJson, _token, includingHoliday);
        if (!_token) {
          history.push("/home");
        }
        setIsLoaded(true);
        try {
          //ここでGETメソッドを使用してgithubのプロフィールを取得します。
          //idToken
          await axios.put(
              `${process.env.REACT_APP_API_BASE_URL}me/accounts/preference`,
              {
                base_time_json: JSON.stringify(baseTimeJson),
                including_holidays: includingHoliday,
              },
              {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${_token}` },
              }
          );
          setIsLoaded(false);
        } catch (error) {
          setIsLoaded(false);
          setIsError(true);
          setErrorMessage(error.response.data.message);
        }
      },
      [history]
  );

  const getPreference = useCallback(
      async (_token) => {
        setIsLoaded(true);
        try {
          const results = await axios.get(`${process.env.REACT_APP_API_BASE_URL}me/accounts/preference`, {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${_token}`,
            },
          });
          setAuthorizationId(results.data.authorization_resource_id);
          let baseTimeJson = [];
          if (JSON.parse(results.data.base_time_json).length < 1) {
            baseTimeJson = [
              { tab: "sun", name: "日曜", weekindex: 0, enabled: false, start: "9:00", end: "21:00" },
              { tab: "mon", name: "月曜", weekindex: 1, enabled: true, start: "9:00", end: "21:00" },
              { tab: "tue", name: "火曜", weekindex: 2, enabled: true, start: "9:00", end: "21:00" },
              { tab: "wed", name: "水曜", weekindex: 3, enabled: true, start: "9:00", end: "21:00" },
              { tab: "thu", name: "木曜", weekindex: 4, enabled: true, start: "9:00", end: "21:00" },
              { tab: "fri", name: "金曜", weekindex: 5, enabled: true, start: "9:00", end: "21:00" },
              { tab: "sat", name: "土曜", weekindex: 6, enabled: false, start: "9:00", end: "21:00" },
            ];
          } else {
            baseTimeJson = JSON.parse(results.data.base_time_json);
          }

          // URLパラメータに付与するaccessTokenの値をセットする。
          // state.accessTokenは、リロード時からの再呼び出し時に消えているので_tokenを退避しておく。
          setMyAccessToken(_token)

          setAppState({
            userInfo: { nickname: results.data.nickname, email: results.data.email, time_zone: results.data.time_zone },
            accessToken: state.accessToken,
            calendar: state.calendar,
            today: state.today,
            blockTime: {},
            calendarConnect: results.data.calendar_connected,
            baseTimeJson: baseTimeJson,
            blockRange: {},
            blockDays: [],
            includingHoliday: results.data.including_holidays,
            zoomConnect: results.data.zoom_connected,
          });
          if (JSON.parse(results.data.base_time_json).length > 0) {
            setStartTime(JSON.parse(results.data.base_time_json)[0]["start"]);
            setEndTime(JSON.parse(results.data.base_time_json)[0]["end"]);
          } else {
            setStartTime("10:00");
            setEndTime("19:00");
          }
          setDays([
            { label: "月", weekindex: 1, name: "月曜", default: baseTimeJson[0]["enabled"] },
            { label: "火", weekindex: 2, name: "火曜", default: baseTimeJson[1]["enabled"] },
            { label: "水", weekindex: 3, name: "水曜", default: baseTimeJson[2]["enabled"] },
            { label: "木", weekindex: 4, name: "木曜", default: baseTimeJson[3]["enabled"] },
            { label: "金", weekindex: 5, name: "金曜", default: baseTimeJson[4]["enabled"] },
            { label: "土", weekindex: 6, name: "土曜", default: baseTimeJson[5]["enabled"] },
            { label: "日", weekindex: 0, name: "日曜", default: baseTimeJson[6]["enabled"] },
            { label: "祝", weekindex: 7, name: "祝日", default: results.data.including_holidays },
          ]);
          setIsLoaded(false);
        } catch (error) {
          console.log(error);
          setIsLoaded(false);
          //setErrorMessage(error.response.data.message);
          history.push("/home");
        }
      },
      [history]
  );

  const isSettingCompleted = useCallback(
      async (_idToken) => {
        try {
          //ここでGETメソッドを使用してgithubのプロフィールを取得します。
          const results = await axios.post(`${process.env.REACT_APP_API_BASE_URL}accounts`, {
            idToken: _idToken,
          });
          getPreference(results.data.access_token);
        } catch (error) {
          setIsLoaded(false);
          setIsError(true);
          history.push("/home");
          //setErrorMessage(error.response.data.message);
        }
      },
      [history]
  );

  useEffect(() => {
    if (state.accessToken) {
      getPreference(state.accessToken);
    } else {
      (async () => {
        await app
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
              isSettingCompleted(idToken);
            })
            .catch(function (error) {
              setIsError(true);
              setErrorMessage(error.response.data.message);
            });
      })();
    }
  }, [history]);

  return (
      <Layout title="基本設定">
        <Header />
        <MainContent>
          <Content>
            <TabWrap>
              <TabList>
                <TabLink to="/profile">プロフィール</TabLink>
              </TabList>
              <TabList>
                <TabLink to="/setting" className="active">
                  基本設定
                </TabLink>
              </TabList>
            </TabWrap>
            <ContentInnter>
              <Headings>連携カレンダー</Headings>
              <HorizontalLayout>
                {state.calendarConnect && (
                    <>
                      <TextWrap>{authorizationId}</TextWrap>
                      <ButtonPrimarySquareRemoveCalendar
                          onClick={() => {
                            setIsOpenRemoveCalendar(true);
                          }}
                      >
                        <IcoRemoveSquareWrap />
                        カレンダーを解除
                      </ButtonPrimarySquareRemoveCalendar>
                    </>
                )}
                {!state.calendarConnect && (
                    <>
                      <TextWrap>カレンダー未連携</TextWrap>
                      <ButtonPrimarySquareAddCalendar href={connectUrl}>
                        <IcoAddSquareWrap />
                        カレンダーを追加
                      </ButtonPrimarySquareAddCalendar>
                    </>
                )}
              </HorizontalLayout>

              {/* 指摘箇所 */}
              <HorizontalLayout>
                <Headings>Zoom連携</Headings>
                {state.zoomConnect && (
                    <>
                      <ButtonPrimarySquareRemoveZoom
                          onClick={() => {
                            setIsOpenRemoveZoom(true);
                          }}
                      >
                        <IcoRemoveSquareWrap />
                        Zoomを解除
                      </ButtonPrimarySquareRemoveZoom>
                    </>
                )}
                {!state.zoomConnect && (
                    <>
                      <ButtonPrimarySquareAddZoom href={zoomConnectUrl}>
                        <IcoAddSquareWrap />
                        Zoom連携
                      </ButtonPrimarySquareAddZoom>
                    </>
                )}
              </HorizontalLayout>
              {/* 指摘箇所 */}

              <Headings>調整可能な時間帯</Headings>
              <SelectTimeWrap>
                <SubHeadings>Start</SubHeadings>
                <SelectWrap>
                  <SelectboxTime
                      ref={userBusinessStartRef}
                      onChange={(event) => {
                        changeStartHour(event);
                      }}
                      value={startTime}
                  >
                    {hours.map((hour, index) => (
                        <SelectboxOption key={`select-${index}`} value={hour}>
                          {hour}
                        </SelectboxOption>
                    ))}
                  </SelectboxTime>
                </SelectWrap>
              </SelectTimeWrap>
              <SelectTimeWrap>
                <SubHeadings>End</SubHeadings>
                <SelectWrap>
                  <SelectboxTime
                      ref={userBusinessEndRef}
                      onChange={(event) => {
                        changeEndHour(event);
                      }}
                      value={endTime}
                  >
                    {hours.map((hour, index) => (
                        <SelectboxOption key={`select-${index}`} value={hour}>
                          {hour}
                        </SelectboxOption>
                    ))}
                  </SelectboxTime>
                </SelectWrap>
              </SelectTimeWrap>
              <Headings>調整可能な曜日</Headings>
              <DaysWrap>
                {days.map((day, index) => (
                    <CheckboxDay
                        key={`day-${day.weekindex}`}
                        label={day.label}
                        initChecked={day.default}
                        weekindex={day.weekindex}
                        onChange={(event) => {
                          changeBusinessDay(day.weekindex, event);
                        }}
                    />
                ))}
              </DaysWrap>

              <ButtonWrap>
                <ButtonPrimary
                    disabled={false}
                    onClick={(e) => {
                      handleSubmitPreference(e, state.baseTimeJson, state.includingHoliday, state.accessToken);
                      e.preventDefault();
                    }}
                >
                  設定を保存
                </ButtonPrimary>
              </ButtonWrap>
            </ContentInnter>
          </Content>
        </MainContent>
        <Footer />
        {isOpenRemoveCalendar && (
            <ModalRemoveCalendar
                close={(show) => {
                  setIsOpenRemoveCalendar(show);
                  getPreference(state.accessToken);
                }}
                removeFunction={getCalenarId}
                done={isLoadedRemoveCalenar}
            />
        )}
        {
          <Error
              isShow={isError}
              errorMessage={errorMessage}
              close={() => {
                setIsError(false);
              }}
          />
        }
        {isLoaded && <Loading />}

        {isOpenRemoveZoom && (
            <ModalRemoveZoom
                close={(show) => {
                  setIsOpenRemoveZoom(show);
                  getPreference(state.accessToken);
                }}
                removeFunction={getZoomId}
                done={isLoadedRemoveZoom}
            />
        )}
        {
          <Error
              isShow={isError}
              errorMessage={errorMessage}
              close={() => {
                setIsError(false);
              }}
          />
        }
        {isLoaded && <Loading />}
      </Layout>
  );
};
