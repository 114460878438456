import React, { createContext, useEffect, useState } from "react";
import { app } from "./Base";

// contextの作成
export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  //ユーザーをログインさせる関数
  const signin = async (email, password, history) => {
    try {
      const res = await app.auth().signInWithEmailAndPassword(email, password);
      if (res.user.emailVerified) {
        history.push("/home");
      } else {
        alert("Emailによる認証が未完了です。");
      }
    } catch (error) {
      alert(error);
    }
  };

  // 新しいユーザーを作成しメール認証させる
  const signup = async (email, password, history) => {
    try {
      const res = await app.auth().createUserWithEmailAndPassword(email, password);
      res.user.sendEmailVerification();
      history.push("/signup-confirm");
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    app.auth().onAuthStateChanged(setCurrentUser);
  }, []);

  return (
    // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
    <AuthContext.Provider
      value={{
        signin: signin,
        signup: signup,
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
