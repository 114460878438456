import React from "react";
import { Layout } from "../components/Layout";

export const Apiconnectfinish: React.FC<{}> = () => {
  (() => {
    console.log("close");
    window.close();
  })();
  return <Layout title="カレンダー連携成功"></Layout>;
};
