import React, { useEffect, useRef, useState } from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import axios from "axios";
import { colors } from "../data/colors";
import {addFreebusy, removeEvent, removeFreebusy, whatWeek} from "../lib/Calendar";
import { useAppState, useSetAppState } from "../lib/Store";
import { ReactComponent as IcoDropDown } from "../assets/ico_arrow-drop-down.svg";
import { ReactComponent as IcoCreate } from "../assets/ico_create.svg";
import { ReactComponent as IcoTime } from "../assets/ico_time.svg";
import { ReactComponent as IcoDirectionsWalk } from "../assets/ico_directions-walk.svg";
import { ReactComponent as IcoArrow } from "../assets/ico_arrow.svg";
import { ReactComponent as IcoAccount } from "../assets/ico_account.svg";
import { Selectbox, SelectboxOption } from "../components/atoms/Selectbox";
import { Layout } from "../components/Layout";
import { TextField } from "../components/atoms/TextField";
import { ButtonPrimary } from "../components/atoms/Button";
import { Timeline } from "../components/atoms/Timeline";
import { Header } from "../components/molecules/Header";
import Footer from "../components/molecules/Footer";
import { DateTimeline } from "../components/molecules/DateTimeline";
import { Loading } from "../components/molecules/Loading";
import { Error } from "../components/molecules/Error";
import { TextFieldWrapper } from "../components/molecules/TextFiledWrapper";
import { CheckboxDay } from "../components/atoms/CheckboxDay";
import { Textarea } from "../components/atoms/Textarea";
import { NotificationAlert } from "../components/molecules/NotificationAlert";
import { DateNoCheckbox } from "../components/molecules/DateNoCheckbox";

interface TemplateProps extends RouteComponentProps<{}> {}

// TODO: 一時的にany型を使っている箇所があるので、確認次第置換する。
type TemplateParams = {
  base_time_json:       string;
  buffer_param:         number;
  date_range_param:     number;
  effective_at_param:   any;
  event_message_param:  string;
  event_title_param:    string;
  expires_at_param:     any;
  including_holidays:   boolean;
  kind:                 string;
  main_param:           number;
  meeting_tool:         any;
  place:                any;
  start_hour_param:     number;
  title:                string;
  uuid:                 string;
}

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  padding-top: 40px;
  background-color: ${colors.white.hex};
`;

const TextWrap = styled.span`
  width: 104px;
  padding-left: 8px;
  color: ${colors.keyColor.hex};
`;

const IcoCreateWrap = styled(IcoCreate)`
  fill: ${colors.keyColor.hex};
  height: 16px;
  margin-right: 11px;
`;

const IcoTimeWrap = styled(IcoTime)`
  fill: ${colors.keyColor.hex};
  height: 16px;
  margin-right: 16px;
`;

const IcoDirectionsWalkWrap = styled(IcoDirectionsWalk)`
  fill: ${colors.keyColor.hex};
  height: 23px;
  margin-right: 11px;
`;

const IconSubHeadings = styled.h2`
  font-size: 14px;
  color: ${colors.darkGray.hex};
  display: flex;
  align-items: center;
`;

const Required = styled.span`
  color: ${colors.white.hex};
  font-size: 10px;
  padding: 0 10px;
  background-color: ${colors.orange.hex};
  margin-left: 12px;
`;

const SelectWrap = styled.div`
  width: 97px;
  position: relative;
  display: inline-block;
`;

const SelectboxTime = styled(Selectbox)`
  font-size: 14px;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin: 20px 0 0;
  padding: 0 40px 0 0;
  justify-content: center;
`;

const ButtonPrimarySquare30x30 = styled(ButtonPrimary)`
  width: 30px;
  height: 30px;
  min-width: 30px;
  border: 0;
`;

const ContentHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const IcoArrowPrevWrap = styled(IcoArrow)`
  fill: ${colors.keyColor.hex};
  width: 15px;
  height: 15px;
`;

const IcoArrowNextWrap = styled(IcoArrow)`
  fill: ${colors.keyColor.hex};
  width: 15px;
  height: 15px;
  transform: scaleX(-1);
`;

const DateTimelineWrap = styled.div`
  width: 100%;
  margin: 0 16px 0 0;
  display: flex;
`;

const IcoDropDownWrap = styled(IcoDropDown)`
  fill: ${colors.keyColor.hex};
  width: 16px;
  height: auto;
  margin-right: 10px;
`;

const IcoAccountWrap = styled(IcoAccount)`
  fill: ${colors.lightGray.hex};
  width: 16px;
  height: auto;
  margin-right: 10px;
  margin-left: 20px;
`;

const SettingContents = styled.div`
  width: 360px;
  padding: 16px; 
  position: absolute;
  top: 40px;
  background-color: ${colors.white.hex};
`;

const DropDownTitle = styled.div`
  margin-top: 10px;
  padding-bottom: 5px;
  border-bottom: 3px solid ${colors.keyColor.hex};
  cursor: pointer;
`;

const DropDownContent = styled.div<{open: boolean}>`
  max-height: ${(props) => (props.open ? '' : '0')};
  margin-top: ${(props) => (props.open ? '15px' : '0')};
  margin-bottom: ${(props) => (props.open ? '25px' : '0')};
  overflow: hidden;
 `;

const ButtonSetting = styled(ButtonPrimary)`
  color: ${colors.white.hex};
  background-color: ${colors.keyColor.hex};
  width: 108px;
  height: 32px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 auto;
  &:disabled {
    svg {
      fill: ${colors.buttonGrayText.hex};
    }
  }
`;

const SelectboxMeetingTool = styled(Selectbox)`
  font-size: 16px;
  height: 40px;
`;

const DropDownHorizontal = styled.div`
  display: flex;
`;

const DropDownHorizontalItem = styled.div`
  width: 150px;
`;

const ContentWrap = styled.div`
  position: absolute;
  top: 64px;
  left: 400px;
  width: 100%;
  background-color: ${colors.white.hex};
  width: calc(100% - 460px);
  height: calc(100% - 88px);
  padding: 16px;
`;

const TimelineWrap = styled.div`
  display: flex;
  height: calc(100% - 90px);
  overflow-y: scroll;
  padding: 8px 10px 0 0;
`;

const DateWrap = styled.div`
  display: flex;
  width: 100%;
  margin: 0 16px;
`;

const GuestViewWrap = styled.div`
  top: 64px;
  left: 400px;
  width: 100%;
  background-color: ${colors.white.hex};
  padding: 16px;
`;

const GuestViewHeader = styled.div`
  border-bottom: 3px solid ${colors.keyColor.hex};
  padding-bottom: 10px;
  display: flex;
  align-items: center;
`;

const GuestViewHeaderTextWrap = styled.span`
  padding-left: 8px;
  color: ${colors.lightGray.hex};
  font-size: 18px;
`;

const GuestViewContent = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-around;
`;

const GuestViewTitle = styled.div`
  width: 20%;
`;

const GuestViewMessage = styled.div`
  width: 60%; 
`;

const GuestViewContentHeadWrap = styled.span`
  font-size: 14px;
  color : ${colors.lightGray.hex};
`;

const GuestViewContentTitleWrap = styled.div`
  color: ${colors.keyColor.hex};
`;

const AdjustableTimeItemsWrap = styled.div`
  margin-bottom: 20px;
`;

const TimeHyphen = styled.span`
  margin-right: 5px;
  margin-left: 5px;
`;

const AdjustableTimeItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const AdjustableTimeSelectWrap = styled.div`
  display: inline;
`;

const AdjustableTimeButtonWrap = styled.div`
  display: inline;
  width: 30px;
`;

const MessageTextarea = styled(Textarea)`
  height: 150px;
  background-color: ${colors.whiteAccent.hex};
  font-size: 16px;
`;

export const Template: React.FC<TemplateProps> = (props) => {

  // TemplateCardコンポーネントから渡ってきた情報が存在する場合のみparamsに値をセット
  // TODO: 一時的にParamsにany型を用いているので修正する。
  const templateParams: TemplateParams | any = props.location.state ? props.location.state : null;

  const state = useAppState();
  const setAppState = useSetAppState();
  const history = useHistory();

  const minutes = ["15", "30", "45", "60", "90", "120"];
  const move = ["0", "15", "30", "45", "60"];

  const hours = ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00",
    "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];

  // 調整開始起点
  const startHours: { text: string, hour: number }[] = [
    { text: "12時間後", hour: 12 },
    { text: "翌日", hour: 24 },
    { text: "翌々日", hour: 24 * 2 },
    { text: "3日後", hour: 24 * 3 },
  ];
  // 調整期間
  const dateRanges: {text: string, day: number}[] = [
    { text: "1週間", day: 7 },
    { text: "2週間", day: 14 },
    { text: "3週間", day: 21 },
    { text: "4週間", day: 28 },
  ];
  // ミーティングツール
  const meetingTools: {text: string, value: string} [] = [
    {text: "なし", value: "nothing"},
    {text: "Zoom", value: "zoom"},
  ].filter(x => {
    if (!state.zoomConnect && x.value === "zoom") {
        return false;
    }
    return true;
  });

  const [isBasicOpen, setIsBasicOpen] = useState(true);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [isGuestOpen, setIsGuestOpen] = useState(false);
  const [weekCounter, setWeekCounter] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [weekArray, setWeekArray] = useState([
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
  ]);

  // テンプレートの入力項目
  const [mainMinutes, setMainMinutes] // 所要時間
    = useState(templateParams ? templateParams.main_param : Number(minutes[3]));
  const [bufferMinutes, setBufferMinutes] // 移動時間
    = useState(templateParams ? templateParams.buffer_param : Number(move[0]));
  const [templateTitle, setTemplateTitle] // テンプレートのタイトル
    = useState(templateParams ? templateParams.title : "");
  const [eventTitle, setEventTitle] // イベントのタイトル
    = useState(templateParams ? templateParams.event_title_param : "");
  const [eventMessage, setEventMessage] // ゲストに表示するメッセージ
    = useState(templateParams ? templateParams.event_message_param : "");
  const [templateStartHour, setTemplateStartHour] // 調整開始起点
    = useState(templateParams ? templateParams.start_hour_param : startHours[1].hour);
  const [templateDateRange, setTemplateDateRange] // 調整期間
    = useState(templateParams ? templateParams.date_range_param : dateRanges[0].day);
  const [baseTimeJson, setBaseTimeJson] // 調整可能な時間帯
    // templateParamsが存在し、かつbase_time_jsonが空白でない場合のみSet
    = useState(templateParams && templateParams.base_time_json !== "" ?
    JSON.parse(templateParams.base_time_json) : [
      { label: "日", tab: "sun", name: "日曜", weekindex: 0, enabled: false, start: "9:00", end: "21:00" },
      { label: "月", tab: "mon", name: "月曜", weekindex: 1, enabled: true, start: "9:00", end: "21:00" },
      { label: "火", tab: "tue", name: "火曜", weekindex: 2, enabled: true, start: "9:00", end: "21:00" },
      { label: "水", tab: "wed", name: "水曜", weekindex: 3, enabled: true, start: "9:00", end: "21:00" },
      { label: "木", tab: "thu", name: "木曜", weekindex: 4, enabled: true, start: "9:00", end: "21:00" },
      { label: "金", tab: "fri", name: "金曜", weekindex: 5, enabled: true, start: "9:00", end: "21:00" },
      { label: "土", tab: "sat", name: "土曜", weekindex: 6, enabled: false, start: "9:00", end: "21:00" },
      { label: "祝日", tab: "holidays", name: "祝日", weekindex: 7, enabled: false, start: "9:00", end: "21:00" },
    ]);
  const [includingHolidays, setIsIncludingHolidays] // 休日を含むかどうか
    = useState(templateParams ? templateParams.including_holidays : false);
  const [meetingTool, setMeetingTool] // イベントのタイトル
      = useState(templateParams ? templateParams.meeting_tool : "");

  // 更新処理の場合は、バリデーション、ボタンをtrueにしておく。
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(!templateParams);
  const [isTemplateTitleCheck, setIsTemplateTitleCheck] = useState(!!templateParams);
  const [isEventTitleCheck, setIsEventTitleCheck] = useState(!!templateParams);

  // 保存処理が完了したかどうかのフラグ
  const [isSavedTemplate, setIsSavedTemplate] = useState(false); // 新規作成
  const [isUpdatedTemplate, setIsUpdatedTemplate] = useState(false); // 更新

  const timelineRef = useRef(null);
  const templateTitleRef = useRef(null);
  const eventTitleRef = useRef(null);
  const mainMinutesRef = useRef(null);
  const bufferMinutesRef = useRef(null);
  const eventMessageRef = useRef(null);
  const startHourRef = useRef(null);
  const dataRangeRef = useRef(null);
  const meetingToolRef = useRef(null);

  useEffect(() => {
    const fn = async () => {
      setIsLoaded(true);

      // TODO: 画面render時にstateを取得するように変更する。
      if (state.accessToken === "") {
        // stateのaccessTokenが存在しない場合、home画面に遷移
        return history.push("/home");
      }

      // 今日の日付を含めて30日間のイベントを取得
      const paramTimeMin: string = state.today;
      const paramTimeMax: string = moment(`${paramTimeMin}`).add(29, "days").format("YYYY-MM-DD").toString();

      const params = {
        time_min: moment(`${paramTimeMin}`).format("YYYY-MM-DD"),
        time_max: moment(`${paramTimeMax}`).format("YYYY-MM-DD"),
      };

      try {
        const results = await axios.get(`${process.env.REACT_APP_API_BASE_URL}me/events/calendar`,
          {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}`},
            params: { time_min: params.time_min, time_max: params.time_max },
          }
        );

        // カレンダーの日付・週表示をstateから取得した値を用いてセット
        const currentWeekIndex = whatWeek(state.calendar, state.today);
        setWeekCounter(currentWeekIndex);
        setWeekArray(state.calendar[currentWeekIndex]);

        setIsLoaded(false);

        //カレンダーに空いている時間とつまっている時間を送るとカレンダーに追加して返してくれる。
        removeFreebusy(state.calendar); // Calendarの初期化
        const newCalendar = addFreebusy(state.calendar, results.data);
        setAppState({
          userInfo: state.userInfo,
          accessToken: state.accessToken,
          calendar: newCalendar,
          today: state.today,
          blockTime: {},
          calendarConnect: state.calendarConnect,
          baseTimeJson: state.baseTimeJson,
          blockRange: {},
          blockDays: [],
          includingHoliday: state.includingHoliday,
          zoomConnect: state.zoomConnect,
        });

      } catch (error) {
        setIsLoaded(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
        console.log(error);
      }
    };
    fn();
  }, [history]);

  const prevWeek = () => {
    const currentWeekIndex = weekCounter - 1;
    setWeekCounter(currentWeekIndex);
    setWeekArray(state.calendar[currentWeekIndex]);
  };

  const nextWeek = () => {
    const currentWeekIndex = weekCounter + 1;
    setWeekCounter(currentWeekIndex);
    setWeekArray(state.calendar[currentWeekIndex]);
  };

  const updateVariables = () => {
    setTemplateTitle(templateTitleRef.current.value);
    setEventTitle(eventTitleRef.current.value);
    setEventMessage(eventMessageRef.current.value)
    setMainMinutes(Number(mainMinutesRef.current.value));
    setBufferMinutes(Number(bufferMinutesRef.current.value));
    setTemplateDateRange(Number(dataRangeRef.current.value));
    setTemplateStartHour(Number(startHourRef.current.value));
    setMeetingTool(meetingToolRef.current.value)
  };

  // 文字数のバリデーション
  const charLengthValidation = (e, min, max, setCheck: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (e.target.value.length < min || e.target.value.length > max) {
      e.target.classList.add("invalid");
      setCheck(false)
    } else {
      e.target.classList.remove("invalid");
      setCheck(true)
    }
    updateVariables();
  };

  // 全てのバリデーションが通っているかのチェック
  // TODO: charLengthValidationの処理も含めて、やや煩雑になっているのでリファクタリングしたい。
  const formCheck = () => {
    if ( isTemplateTitleCheck === false || isEventTitleCheck === false ) {
      setIsSaveBtnDisabled(true);
    } else {
      setIsSaveBtnDisabled(false);
    }
  };

  // 指定したイベント情報をフィルターするための関数条件
  const filterEventFunc = (data) => {
      // 空き時間 = free と移動時間 = bufferはクリアするのでそれ以外を残す
      // 意図しないものを出さないようにホワイトリスト式に判定する
    return data.event_type === 'default' || data.event_type === 'busy'
  }

  const saveTemplate = async () => {
    setIsLoaded(true);
    updateVariables();

    const data = {
      title: templateTitle,
      main_param: mainMinutes,
      buffer_param: bufferMinutes,
      start_hour_param: templateStartHour,
      date_range_param: templateDateRange,
      event_title_param: eventTitle,
      event_message_param: eventMessage,
      base_time_json: JSON.stringify(baseTimeJson),
      including_holidays: includingHolidays,
      meeting_tool: meetingTool,
    };

    try {
      const results = await axios.post(`${process.env.REACT_APP_API_BASE_URL}time_templates`, data, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}` },
      });
      // 空き時間と移動時間のイベント情報は一度クリアする
      removeEvent(state.calendar, filterEventFunc);

      // postリクエストから返ってきたfreebusyの配列を追加する
      const newCalendar = addFreebusy(state.calendar, results.data.freebusy);
      setAppState({
        userInfo: state.userInfo,
        accessToken: state.accessToken,
        calendar: newCalendar,
        today: state.today,
        blockTime: {},
        calendarConnect: state.calendarConnect,
        baseTimeJson: state.baseTimeJson,
        blockRange: {},
        blockDays: [],
        includingHoliday: state.includingHoliday,
        zoomConnect: state.zoomConnect,
      });

      setIsLoaded(false);

      setIsSavedTemplate(true)

      // 保存後は編集画面になるように、自分自身を（propsを付与して）、呼び出す。
      history.push({
        pathname: '/template',
        state: results.data.template,
      });

    } catch (error) {
      setIsLoaded(false);
      setIsError(true);
      setErrorMessage(error.response.data.message);
      console.log(error);
    }
  };

  const updateTemplate = async () => {
    setIsLoaded(true);
    updateVariables();

    const data = {
      title: templateTitle,
      main_param: mainMinutes,
      buffer_param: bufferMinutes,
      start_hour_param: templateStartHour,
      date_range_param: templateDateRange,
      event_title_param: eventTitle,
      event_message_param: eventMessage,
      base_time_json: JSON.stringify(baseTimeJson),
      including_holidays: includingHolidays,
      meeting_tool: meetingTool,
    };

    try {
      const results = await axios.put(`${process.env.REACT_APP_API_BASE_URL}time_templates/${templateParams.uuid}`, data, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}` },
      });
      // 空き時間と移動時間のイベント情報は一度クリアする
      removeEvent(state.calendar, filterEventFunc);

      // postリクエストから返ってきたfreebusyの配列を追加する
      const newCalendar = addFreebusy(state.calendar, results.data.freebusy);
      setAppState({
        userInfo: state.userInfo,
        accessToken: state.accessToken,
        calendar: newCalendar,
        today: state.today,
        blockTime: {},
        calendarConnect: state.calendarConnect,
        baseTimeJson: state.baseTimeJson,
        blockRange: {},
        blockDays: [],
        includingHoliday: state.includingHoliday,
        zoomConnect: state.zoomConnect,
      });

      setIsLoaded(false);
      setIsUpdatedTemplate(true)
    } catch (error) {
      setIsLoaded(false);
      setIsError(true);
      setErrorMessage(error.response.data.message);
      console.log(error);
    }
  };

  const changeBusinessDay = (weekindex: number, event) => {
    let tmpBaseTimeJson = JSON.parse(JSON.stringify(baseTimeJson));

    // 祝日の場合、isIncludingHolidays フラグも変更する
    if (weekindex === 7) {
      setIsIncludingHolidays(event.target.checked);
    }

    tmpBaseTimeJson.map((item) => {
      if (item["weekindex"] === weekindex) {
        item["enabled"] = event.target.checked;
      }
    });
    setBaseTimeJson(tmpBaseTimeJson);
  };

  const changeStartHour = (weekindex: number, event) => {
    let tmpBaseTimeJson = JSON.parse(JSON.stringify(baseTimeJson));

    tmpBaseTimeJson.map((item) => {
      if (item["weekindex"] === weekindex) {
        item["start"] = event.target.value;
      }
    });
    setBaseTimeJson(tmpBaseTimeJson);
  };

  const changeEndHour = (weekindex: number, event) => {
    let tmpBaseTimeJson = JSON.parse(JSON.stringify(baseTimeJson));

    tmpBaseTimeJson.map((item) => {
      if (item["weekindex"] === weekindex) {
        item["end"] = event.target.value;
      }
    });
    setBaseTimeJson(tmpBaseTimeJson);
  };


  return (
    <Layout title="テンプレート">
      <Header />
      <MainContent>
        <SettingContents>
          {/* 基本設定 */}
          <DropDownTitle
            onClick={() => {
              isBasicOpen === true ? setIsBasicOpen(false) : setIsBasicOpen(true)
            }}
          >
            <IcoDropDownWrap />
            <TextWrap>基本設定</TextWrap>
          </DropDownTitle>
          <DropDownContent open={isBasicOpen}>
            <IconSubHeadings>
              <IcoCreateWrap />
              予約テンプレートのタイトル
              <Required>必須</Required>
            </IconSubHeadings>
            <TextFieldWrapper min={2} max={30} count={templateTitle.length}>
              <TextField
                onChange={(e) => {
                  charLengthValidation(e, 2, 30, setIsTemplateTitleCheck);
                  formCheck();
                }}
                ref={templateTitleRef}
                defaultValue={templateTitle}
                placeholder="打ち合わせテンプレ"
              />
            </TextFieldWrapper>

            <IconSubHeadings>
              <IcoCreateWrap />
              イベントのタイトル
              <Required>必須</Required>
            </IconSubHeadings>
            <TextFieldWrapper min={2} max={30} count={eventTitle.length}>
              <TextField
                onChange={(e) => {
                  charLengthValidation(e, 2, 30, setIsEventTitleCheck);
                  formCheck();
                }}
                ref={eventTitleRef}
                defaultValue={eventTitle}
                placeholder="打ち合わせ"
              />
            </TextFieldWrapper>

            <DropDownHorizontal>
              <DropDownHorizontalItem>
                <IconSubHeadings>
                  <IcoTimeWrap />
                  所要時間
                  <Required>必須</Required>
                </IconSubHeadings>
                <SelectWrap>
                  <SelectboxTime
                    value={mainMinutes}
                    ref={mainMinutesRef}
                    onChange={(e) => {
                      setMainMinutes(Number(e.target.value));
                    }}
                  >
                    {minutes.map((minute, index) => (
                      <SelectboxOption key={`select-${index}`} value={minute}>
                        {minute}min
                      </SelectboxOption>
                    ))}
                  </SelectboxTime>
                </SelectWrap>
              </DropDownHorizontalItem>
              <DropDownHorizontalItem>
                <IconSubHeadings>
                  <IcoDirectionsWalkWrap />
                  移動時間
                </IconSubHeadings>
                <SelectWrap>
                  <SelectboxTime
                    ref={bufferMinutesRef}
                    value={bufferMinutes}
                    onChange={(e) => {
                      setBufferMinutes(Number(e.target.value));
                    }}

                  >
                    {move.map((minute, index) => (
                      <SelectboxOption key={`select-${index}`} value={minute}>
                        {minute}min
                      </SelectboxOption>
                    ))}
                  </SelectboxTime>
                </SelectWrap>
              </DropDownHorizontalItem>
            </DropDownHorizontal>

            <DropDownHorizontal>
              <DropDownHorizontalItem>
                <IconSubHeadings>
                  調整期間
                </IconSubHeadings>
                <SelectWrap>
                  <SelectboxTime
                    ref={dataRangeRef}
                    onChange={(e) => {
                      setTemplateDateRange(Number(e.target.value));
                    }}
                    value={templateDateRange}
                  >
                    {dateRanges.map((dateRange, index) => (
                      <SelectboxOption key={`select-${index}`} value={dateRange.day}>
                        {dateRange.text}
                      </SelectboxOption>
                    ))}
                  </SelectboxTime>
                </SelectWrap>
              </DropDownHorizontalItem>
              <DropDownHorizontalItem>
                <IconSubHeadings>
                  調整開始起点
                </IconSubHeadings>
                <SelectWrap>
                  <SelectboxTime
                    ref={startHourRef}
                    value={templateStartHour}
                    onChange={(e) => {
                      setTemplateStartHour(Number(e.target.value));
                    }}
                  >
                    {startHours.map((startHour, index) => (
                      <SelectboxOption key={`select-${index}`} value={startHour.hour}>
                        {startHour.text}
                      </SelectboxOption>
                    ))}
                  </SelectboxTime>
                </SelectWrap>
              </DropDownHorizontalItem>
            </DropDownHorizontal>
            <ButtonWrap>
              {(!templateParams || templateParams.uuid === "") && <ButtonSetting
                onClick={saveTemplate}
                disabled={isSaveBtnDisabled}
              >
                保存する
              </ButtonSetting>}
              {(templateParams && templateParams.uuid !== "") && <ButtonSetting
                onClick={updateTemplate}
                disabled={isSaveBtnDisabled}
              >
                更新する
              </ButtonSetting>}
            </ButtonWrap>
          </DropDownContent>

          {/* 詳細条件設定 */}
          <DropDownTitle
            onClick={() => {
              isAdvancedOpen === true ? setIsAdvancedOpen(false) : setIsAdvancedOpen(true)
            }}
          >
            <IcoDropDownWrap />
            <TextWrap>詳細条件設定</TextWrap>
          </DropDownTitle>
          <DropDownContent open={isAdvancedOpen}>
            {/* 調整可能な時間帯 - Component */}
            <IconSubHeadings>
              調整可能な時間帯
            </IconSubHeadings>
            <AdjustableTimeItemsWrap>
              {baseTimeJson.map((day) => (
                <AdjustableTimeItemWrap key={`day-${day.weekindex}`}>
                  <CheckboxDay
                    label={day.label}
                    initChecked={day.enabled}
                    onChange={(event) => {
                      changeBusinessDay(day.weekindex, event);
                    }}
                  />
                  <AdjustableTimeSelectWrap>
                    <SelectWrap>
                      <SelectboxTime
                        defaultValue={day.start}
                        onChange={(event) => {
                          changeStartHour(day.weekindex, event);
                        }}
                      >
                        {hours.map((hour, index) => (
                          <SelectboxOption key={`select-${index}`} value={hour}>
                            {hour}
                          </SelectboxOption>
                        ))}
                      </SelectboxTime>
                    </SelectWrap>
                    <TimeHyphen>
                      -
                    </TimeHyphen>
                    <SelectWrap>
                      <SelectboxTime
                        defaultValue={day.end}
                        onChange={(event) => {
                          changeEndHour(day.weekindex, event);
                        }}
                      >
                        {hours.map((hour, index) => (
                          <SelectboxOption key={`select-${index}`} value={hour}>
                            {hour}
                          </SelectboxOption>
                        ))}
                      </SelectboxTime>
                    </SelectWrap>
                  </AdjustableTimeSelectWrap>
                  <AdjustableTimeButtonWrap>
                    {/* TODO: "+", "-" ボタン設置箇所 */}
                  </AdjustableTimeButtonWrap>
                </AdjustableTimeItemWrap>
              ))}
            </AdjustableTimeItemsWrap>
            <IconSubHeadings>
              場所
            </IconSubHeadings>
            <TextField />
            <IconSubHeadings>
              ミーティングツール
            </IconSubHeadings>
            {!state.zoomConnect &&
            <p>"zoom is not linked"</p>
            }
            <SelectboxMeetingTool
              ref={meetingToolRef}
              value={meetingTool}
              onChange={(e) =>
                  setMeetingTool(e.target.value)
              }
            >
              {meetingTools.map((meetingTool, index) => (
                <SelectboxOption key={`select-${index}`} value={meetingTool.value}>
                  {meetingTool.text}
                </SelectboxOption>
              ))}
            </SelectboxMeetingTool>
            <ButtonWrap>
              {(!templateParams || templateParams.uuid === "") && <ButtonSetting
                onClick={saveTemplate}
                disabled={isSaveBtnDisabled}
              >
                保存する
              </ButtonSetting>}
              {(templateParams && templateParams.uuid !== "") && <ButtonSetting
                onClick={updateTemplate}
                disabled={isSaveBtnDisabled}
              >
                更新する
              </ButtonSetting>}
            </ButtonWrap>
          </DropDownContent>

          {/* ゲスト設定 */}
          <DropDownTitle
            onClick={() => {
              isGuestOpen === true ? setIsGuestOpen(false) : setIsGuestOpen(true)
            }}
          >
            <IcoDropDownWrap />
            <TextWrap>ゲスト設定</TextWrap>
          </DropDownTitle>
          <DropDownContent open={isGuestOpen}>
            <IconSubHeadings>
              ゲストへ表示するメッセージ
            </IconSubHeadings>
            <MessageTextarea
              placeholder="場所は弊社オフィスにてお願い致します。"
              maxLength={200}
              ref={eventMessageRef}
              defaultValue={eventMessage}
              onChange={(e) => {
                setEventMessage(e.target.value);
              }}
            />
            <ButtonWrap>
              {(!templateParams || templateParams.uuid === "") && <ButtonSetting
                onClick={saveTemplate}
                disabled={isSaveBtnDisabled}
              >
                保存する
              </ButtonSetting>}
              {(templateParams && templateParams.uuid !== "") && <ButtonSetting
                onClick={updateTemplate}
                disabled={isSaveBtnDisabled}
              >
                更新する
              </ButtonSetting>}
            </ButtonWrap>
          </DropDownContent>
        </SettingContents>

        <ContentWrap>
          {/* ゲストビュー */}
          <GuestViewWrap>
            <GuestViewHeader>
              <IcoAccountWrap />
              <GuestViewHeaderTextWrap>
                ゲストビュー
              </GuestViewHeaderTextWrap>
            </GuestViewHeader>
            <GuestViewContent>
              <GuestViewTitle>
                <GuestViewContentHeadWrap>
                  タイトル
                </GuestViewContentHeadWrap>
                <GuestViewContentTitleWrap>
                  {eventTitle}
                </GuestViewContentTitleWrap>
              </GuestViewTitle>
              <GuestViewMessage>
                {eventMessage}
              </GuestViewMessage>
            </GuestViewContent>
          </GuestViewWrap>

          {/* カレンダー */}
          <ContentHeaderWrap>
            <ButtonPrimarySquare30x30
              onClick={() => {
                prevWeek();
              }}
            >
              <IcoArrowPrevWrap />
            </ButtonPrimarySquare30x30>

            <DateWrap>
              <DateNoCheckbox data={weekArray[0]} day="月" today={state.today} />
              <DateNoCheckbox data={weekArray[1]} day="火" today={state.today} />
              <DateNoCheckbox data={weekArray[2]} day="水" today={state.today} />
              <DateNoCheckbox data={weekArray[3]} day="木" today={state.today} />
              <DateNoCheckbox data={weekArray[4]} day="金" today={state.today} />
              <DateNoCheckbox data={weekArray[5]} day="土" today={state.today} />
              <DateNoCheckbox data={weekArray[6]} day="日" today={state.today} />
            </DateWrap>
            <ButtonPrimarySquare30x30
              onClick={() => {
                nextWeek();
              }}
            >
              <IcoArrowNextWrap ref={timelineRef}/>
            </ButtonPrimarySquare30x30>
          </ContentHeaderWrap>
          <TimelineWrap>
            <Timeline />
            <DateTimelineWrap>
              <DateTimeline data={weekArray[0]} day="月" isGuest={false} isBusyOnly={false} isTemplate={true} />
              <DateTimeline data={weekArray[1]} day="火" isGuest={false} isBusyOnly={false} isTemplate={true} />
              <DateTimeline data={weekArray[2]} day="水" isGuest={false} isBusyOnly={false} isTemplate={true} />
              <DateTimeline data={weekArray[3]} day="木" isGuest={false} isBusyOnly={false} isTemplate={true} />
              <DateTimeline data={weekArray[4]} day="金" isGuest={false} isBusyOnly={false} isTemplate={true} />
              <DateTimeline data={weekArray[5]} day="土" isGuest={false} isBusyOnly={false} isTemplate={true} />
              <DateTimeline data={weekArray[6]} day="日" isGuest={false} isBusyOnly={false} isTemplate={true} />
            </DateTimelineWrap>
          </TimelineWrap>
        </ContentWrap>
      </MainContent>
      <Footer />
      {
        <Error
          isShow={isError}
          errorMessage={errorMessage}
          close={() => {
            setIsError(false);
          }}
        />
      }
      <NotificationAlert
        show={isSavedTemplate}
        callback={() => {
          setIsSavedTemplate(false);
        }}
        text={"テンプレートを保存しました"}
      />
      <NotificationAlert
        show={isUpdatedTemplate}
        callback={() => {
          setIsUpdatedTemplate(false);
        }}
        text={"テンプレートを更新しました"}
      />
      {isLoaded && <Loading />}
    </Layout>
  );
};
