import React from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { RelatedLink } from "../atoms/Button";
import { useAppState } from "../../lib/Store";

const ModalMask = styled.div`
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.modalBg.rgba};
  display: table;
  transition: opacity 0.3s ease;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 496px;
  min-height: 260px;
  background-color: ${colors.white.hex};
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
`;

const ButtonWrap = styled.div`
  margin: 40px auto 0;
  width: 320px;
`;

const ContentInnter = styled.div`
  width: 496px;
  margin: 0 auto;
  padding: 20px 0;
`;

const Img = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 56px;
`;

const ButtonPrimaryLarge = styled(RelatedLink)`
  width: 320px;
  height: 48px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
`;

const ButtonInner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const PageTitle = styled.h1`
  font-size: 20px;
  letter-spacing: 0.28em;
  color: ${colors.keyColor.hex};
  margin: 0 4px 0;
  padding: 0;
  text-align: center;
`;

const PageTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: 8px;
  background-color: ${colors.white.hex};
  border-bottom: 1px solid ${colors.whiteAccent.hex};
`;

const Text = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
`;

export const ModalCalendarConnect: React.FC<{}> = () => {
  const state = useAppState();
  const connectUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URL}&scope=https://www.googleapis.com/auth/calendar&response_type=code&access_type=offline&prompt=consent&state=${state.accessToken}`;
  return (
    <ModalMask>
      <ModalWrap>
        <ModalContainer>
          <PageTitleWrap>
            <PageTitle>カレンダー連携</PageTitle>
          </PageTitleWrap>
          <ContentInnter>
            <Text>
              お使いのカレンダーを連携しましょう！
              <br />
              カレンダーを連携することで
              <br />
              自動で日程調整を行うことができます。
            </Text>
            <ButtonWrap>
              <ButtonPrimaryLarge href={connectUrl}>
                <ButtonInner>Googleカレンダーを連携</ButtonInner>
              </ButtonPrimaryLarge>
            </ButtonWrap>
          </ContentInnter>
        </ModalContainer>
      </ModalWrap>
    </ModalMask>
  );
};
