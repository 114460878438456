export interface Color {
  name: string;
  rgba: string;
  hex?: string;
}

export const colors: { [s: string]: Color } = {
  paleGray: {
    name: "Pale Gray",
    rgba: "rgba(244, 245, 250, 1)",
    hex: "#f4f5fa",
  },
  lightGray: {
    name: "Light Gray",
    rgba: "rgba(191, 191, 191, 1)",
    hex: "#bfbfbf",
  },
  brown: {
    name: "Brown",
    rgba: "rgba(102, 60, 43, 1)",
    hex: "#663c2b",
  },
  darkOrange: {
    name: "Dark Orange",
    rgba: "rgba(179, 66, 21, 1)",
    hex: "#b34215",
  },
  orange: {
    name: "Orange",
    rgba: "rgba(233, 85, 27, 1)",
    hex: "#e9551b",
  },
  lightOrange: {
    name: "Light Orange",
    rgba: "rgba(255, 147, 107, 1)",
    hex: "#ff936b",
  },
  black: {
    name: "Black",
    rgba: "rgba(0, 0, 0, 1)",
    hex: "#000000",
  },
  darkGray: {
    name: "DarkGray",
    rgba: "rgba(57, 57, 57, 1)",
    hex: "#393939",
  },
  gray: {
    name: "Gray",
    rgba: "rgba(175, 165, 166, 1)",
    hex: "#afa5a6",
  },
  middleGray: {
    name: "Middle Gray",
    rgba: "rgba(180, 180, 195, 1)",
    hex: "#b4b4c3",
  },
  silverGray: {
    name: "Silver Gray",
    rgba: "rgba(219, 219, 227, 1)",
    hex: "#dbdbe3",
  },
  ultraPaleGray: {
    name: "Ultra Pale Gray",
    rgba: "rgba(244, 245, 250, 1)",
    hex: "#f4f5fa",
  },
  white: {
    name: "White",
    rgba: "rgba(255, 255, 255, 1)",
    hex: "#ffffff",
  },
  gray2: {
    name: "gray2",
    rgba: "rgba(75, 75, 75, 1)",
    hex: "#4B4B4B",
  },
  keyColor: {
    name: "keyColor",
    rgba: "rgba(233, 85, 27, 1)",
    hex: "#E9551B",
  },
  primary: {
    name: "primary",
    rgba: "rgba(16, 152, 232, 1)",
    hex: "#1098E8",
  },
  text: {
    name: "text",
    rgba: "rgba(0, 0, 0, 1)",
    hex: "#000000",
  },
  textLight: {
    name: "textLight",
    rgba: "rgba(155, 155, 155, 1)",
    hex: "#9B9B9B",
  },
  whiteAccent: {
    name: "whiteAccent",
    rgba: "rgba(240, 240, 245, 1)",
    hex: "#F0F0F5",
  },
  buttonGrayText: {
    name: "buttonGrayText",
    rgba: "rgba(167, 164, 162, 1)",
    hex: "#A7A4A2",
  },
  buttonGrayGround: {
    name: "buttonGrayGround",
    rgba: "rgba(220, 220, 220, 1)",
    hex: "#DCDCDC",
  },
  ground: {
    name: "ground",
    rgba: "rgba(250, 248, 245, 1)",
    hex: "#FAF8F5",
  },
  groundDark: {
    name: "groundDark",
    rgba: "rgba(235, 230, 223, 1)",
    hex: "#EBE6DF",
  },
  modalBg: {
    name: "modalBg",
    rgba: "rgba(255, 255, 255, 0.82)",
  },
};
