import React, { useContext, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ButtonPrimary } from "../../components/atoms/Button";
import { SelectboxNormal, SelectboxOption } from "../../components/atoms/Selectbox";
import { ReactComponent as IcoFilter } from "../../assets/ico_filter.svg";
import { ReactComponent as IcoClose } from "../../assets/ico_close.svg";
import { ReactComponent as IcoArrowDropDown } from "../../assets/ico_arrow-drop-down.svg";
import { ReactComponent as IcoArrowDRounded } from "../../assets/ico_arrow-rounded.svg";
import { TimeContext } from "../../lib/CheckboxProvider";
import { useAppState } from "../../lib/Store";

type SidebarProps = {
  timeQueries: Function;
  isSearch: boolean;
  blockTimeArray: object[];
  blockDaysArray: string[];
  rangeObject: object;
  otherObject: object;
};

const SidebarWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${colors.white.hex};
  width: 104px;
  height: 100%;
  padding: 60px 8px 20px;
`;

const IcoFilterWrap = styled(IcoFilter)`
  fill: ${colors.primary.hex};
  width: 13px;
  height: 16px;
`;

const HeadingsIcon = styled.h3`
  color: ${colors.primary.hex};
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const Headings = styled.h3`
  color: ${colors.gray.hex};
  font-size: 9px;
  font-weight: bold;
  margin-bottom: 0;
`;

const ButtonPrimarySquareLarge = styled(ButtonPrimary)`
  background-color: ${colors.ground.hex};
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.primary.hex};
  border: 0;
  &.active {
    border: 1px solid ${colors.primary.hex};
  }
`;

const ButtonPrimaryPublishUrl = styled(ButtonPrimary)`
  width: 64px;
  height: 64px;
  &:disabled {
    svg {
      fill: ${colors.buttonGrayText.hex};
    }
  }
`;

const FilterSelectbox = styled(SelectboxNormal)`
  color: ${colors.primary.hex};
  background-color: ${colors.ground.hex};
`;

const FilterWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
  background-color: ${colors.white.hex};
  padding: 10px 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
`;

const SelectWrap = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
`;

const IcoArrowDropDownWrap = styled(IcoArrowDropDown)`
  position: absolute;
  top: 14px;
  right: 6px;
  width: 6px;
  height: 6px;
  fill: ${colors.primary.hex};
`;

const IcoCloseWrap = styled(IcoClose)`
  fill: ${colors.keyColor.hex};
  height: 20px;
`;

const IcoArrowDRoundedWrap = styled(IcoArrowDRounded)`
  fill: ${colors.keyColor.hex};
  height: 24px;
`;

const ButtonPrimarySquareCancel = styled(ButtonPrimary)`
  background-color: ${colors.ground.hex};
  width: 64px;
  height: 64px;
`;

const ButtonIconSubText = styled.p`
  width: calc(100% / 0.8);
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  margin: 4px 0 0;
  padding: 0;
  transform: scale(0.8);
  transform-origin: center left;
  font-size: 10px;
`;

const NavSidebar = styled.div`
  position: absolute;
  bottom: 40px;
  left: 20px;
  height: 143px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const history = useHistory();
  const state = useAppState();
  const hours = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "24:00"];
  const { addRange, removeRange } = useContext(TimeContext);
  const [startRange, setStartRange] = useState("00:00");
  const [endRange, setEndRange] = useState("24:00");
  const [oldElement, setOldElement] = useState(null);
  const [startHours, setStartHours] = useState(hours);
  const [endHours, setEndHours] = useState(hours);

  const allBtnRef = useRef(null);
  const amBtnRef = useRef(null);
  const pmBtnRef = useRef(null);
  const startRef = useRef(null);
  const endRef = useRef(null);

  const handleAddRange = (start, end) => {
    setStartRange(start);
    setEndRange(end);
    addRange(start, end);
    handleLimitEndRange(start);
  };

  const handleRemoveRange = () => {
    removeRange();
    setStartRange("00:00");
    setEndRange("24:00");
    handleLimitEndRange("00:00");
    addRange("00:00", "24:00");
  };

  const handleLimitEndRange = (start) => {
    let tmpHours = JSON.parse(JSON.stringify(hours));
    const index = hours.indexOf(start) + 1;
    const endIndex = tmpHours.length - 1;
    if (index > endIndex) {
      setEndHours(tmpHours.splice(index - 1, endIndex));
    } else {
      setEndHours(tmpHours.splice(index, endIndex));
    }
  };

  const handleChangeSelect = (e) => {
    setStartRange(startRef.current.value);
    setEndRange(endRef.current.value);
    handleLimitEndRange(startRef.current.value);
    addRange(startRef.current.value, endRef.current.value);
  };

  const handleActiveControll = (e) => {
    if (oldElement !== null && oldElement.classList.contains("active")) {
      oldElement.classList.remove("active");
    } else {
      allBtnRef.current.classList.remove("active");
    }
    setOldElement(e.target);
    e.target.classList.add("active");
  };

  //isSearch
  useEffect(() => {
    handleRemoveRange();
    allBtnRef.current.classList.add("active");
    if (oldElement !== null) {
      if (oldElement.classList.contains("active")) {
        oldElement.classList.remove("active");
      }
    }
  }, [props.isSearch]);

  return (
    <SidebarWrap>
      <HeadingsIcon>
        <IcoFilterWrap />
        Filter
      </HeadingsIcon>
      <FilterWrap>
        <ButtonPrimarySquareLarge
          onClick={(e) => {
            handleRemoveRange();
            handleActiveControll(e);
          }}
          className="active"
          ref={allBtnRef}
        >
          ALL
        </ButtonPrimarySquareLarge>
        <ButtonPrimarySquareLarge
          onClick={(e) => {
            handleAddRange("00:00", "12:00");
            handleActiveControll(e);
          }}
          ref={amBtnRef}
        >
          AM
        </ButtonPrimarySquareLarge>
        <ButtonPrimarySquareLarge
          onClick={(e) => {
            handleAddRange("12:00", "24:00");
            handleActiveControll(e);
          }}
          ref={pmBtnRef}
        >
          PM
        </ButtonPrimarySquareLarge>
        <Headings>Start</Headings>
        <SelectWrap>
          <FilterSelectbox
            onChange={(e) => {
              handleActiveControll(e);
              handleChangeSelect(e);
            }}
            value={startRange}
            ref={startRef}
          >
            {startHours.map((hour, index) => (
              <SelectboxOption key={`select-${index}`} value={hour}>
                {hour}
              </SelectboxOption>
            ))}
          </FilterSelectbox>
          <IcoArrowDropDownWrap />
        </SelectWrap>

        <Headings>end</Headings>
        <SelectWrap>
          <FilterSelectbox
            onChange={(e) => {
              handleActiveControll(e);
              handleChangeSelect(e);
            }}
            value={endRange}
            ref={endRef}
          >
            {endHours.map((hour, index) => (
              <SelectboxOption key={`select-${index}`} value={hour}>
                {hour}
              </SelectboxOption>
            ))}
          </FilterSelectbox>
          <IcoArrowDropDownWrap />
        </SelectWrap>
      </FilterWrap>
      <NavSidebar>
        <ButtonPrimarySquareCancel
          onClick={() => {
            history.push("/home");
          }}
        >
          <IcoCloseWrap />
          <ButtonIconSubText>
            日程調整を
            <br />
            やめる
          </ButtonIconSubText>
        </ButtonPrimarySquareCancel>
        <ButtonPrimaryPublishUrl
          onClick={(e) => {
            props.timeQueries(props.blockTimeArray, props.blockDaysArray, props.rangeObject, props.otherObject, e);
          }}
          disabled={props.isSearch}
        >
          <IcoArrowDRoundedWrap />
          <ButtonIconSubText>URL発行</ButtonIconSubText>
        </ButtonPrimaryPublishUrl>
      </NavSidebar>
    </SidebarWrap>
  );
};
