import styled from "styled-components";
import { colors } from "../../data/colors";

export const TextField = styled.input`
  background-color: ${colors.whiteAccent.hex};
  border: none;
  box-sizing: border-box;
  color: ${colors.darkGray.hex};
  font-size: 16px;
  width: 100%;
  height: 40px;
  outline: none;
  padding: 0px 8px;
  margin-bottom: 6px;
  &::placeholder {
    color: ${colors.white.hex};
  }

  &:invalid {
    border: 1px solid ${colors.orange.hex};
  }

  &.invalid {
    border: 1px solid ${colors.orange.hex};
  }
`;

export const TextFieldBgWhite = styled.input`
  background-color: ${colors.white.hex};
  border: none;
  box-sizing: border-box;
  color: ${colors.darkGray.hex};
  font-size: 14px;
  width: 100%;
  height: 24px;
  outline: none;
  padding: 0px 8px;
  &::placeholder {
    color: ${colors.darkGray.hex};
  }
  &:invalid {
    border: 1px solid ${colors.orange.hex};
  }
  cursor: pointer;
`;
