import React, { useRef, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ButtonPrimary } from "../atoms/Button";
import { TextField } from "../atoms/TextField";
import { CheckboxDay } from "../atoms/CheckboxDay";
import { Selectbox, SelectboxOption } from "../atoms/Selectbox";
import { useSetAppState, useAppState } from "../../lib/Store";
import axios from "axios";

type ModalFirstSettingProps = {
  close: Function;
};

const ModalMask = styled.div`
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.modalBg.rgba};
  display: table;
  transition: opacity 0.3s ease;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 700px;
  min-height: 646px;
  background-color: ${colors.white.hex};
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
`;

const PageTitle = styled.h1`
  font-size: 20px;
  letter-spacing: 0.28em;
  color: ${colors.keyColor.hex};
  margin: 0 4px 0;
  padding: 0;
  text-align: center;
`;

const PageTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: 8px;
  background-color: ${colors.white.hex};
  border-bottom: 1px solid ${colors.whiteAccent.hex};
`;

const TextFieldWrap = styled.div`
  margin-bottom: 16px;
`;

const SelectWrap = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
`;

const SelectboxCountry = styled(Selectbox)`
  font-size: 14px;
`;

const SelectboxTime = styled(Selectbox)`
  font-size: 14px;
`;

const DaysWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 504px;
`;

const ContentInnter = styled.div`
  width: 504px;
  margin: 0 auto;
  padding: 40px 0 0;
`;

const Headings = styled.h2`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black.hex};
  margin: 20px 0 8px;
`;

const SubHeadings = styled.h3`
  font-size: 10px;
  color: ${colors.middleGray.hex};
  margin: 8px 0 4px;
`;

const SelectTimeWrap = styled.div`
  display: inline-block;
  width: 90px;
  margin-right: 40px;
`;

const SelectCountryWrap = styled.div`
  width: 90px;
`;

const ButtonWrap = styled.div`
  margin: 40px auto 0;
  width: 184px;
`;

const SubText = styled.p`
  font-size: 12px;
  color: ${colors.middleGray.hex};
`;

const Required = styled.span`
  color: ${colors.white.hex};
  font-size: 10px;
  padding: 0 10px;
  background-color: ${colors.orange.hex};
  margin-left: 12px;
`;

export const ModalFirstSetting: React.FC<ModalFirstSettingProps> = (props) => {
  const history = useHistory();
  const state = useAppState();
  const setAppState = useSetAppState();
  const nicknameRef = useRef(null);
  const time_zoneRef = useRef(null);
  const userBusinessStartRef = useRef(null);
  const userBusinessEndRef = useRef(null);
  const [startTime, setStartTime] = useState("10:00");
  const [endTime, setEndTime] = useState("19:00");

  console.log(state.userInfo, state.accessToken);
  const [accountsPreference, setAccountsPreference] = useState({
    user_id: 1,
    nickname: state.userInfo["nickname"],
    email: state.userInfo["email"],
    including_holidays: false,
    base_time_json: [
      { tab: "sun", name: "日曜", weekindex: 0, enabled: false, start: "10:00", end: "19:00" },
      { tab: "mon", name: "月曜", weekindex: 1, enabled: false, start: "10:00", end: "19:00" },
      { tab: "tue", name: "火曜", weekindex: 2, enabled: false, start: "10:00", end: "19:00" },
      { tab: "wed", name: "水曜", weekindex: 3, enabled: false, start: "10:00", end: "19:00" },
      { tab: "thu", name: "木曜", weekindex: 4, enabled: false, start: "10:00", end: "19:00" },
      { tab: "fri", name: "金曜", weekindex: 5, enabled: false, start: "10:00", end: "19:00" },
      { tab: "sat", name: "土曜", weekindex: 6, enabled: false, start: "10:00", end: "19:00" },
    ],
    time_zone: state.userInfo["timezone"],
  });
  /*const accountsPreference = {
    user_id: 1,
    nickname: state.userInfo["nickname"],
    email: state.userInfo["email"],
    including_holidays: true,
    base_time_json: [
      { tab: "sun", name: "日曜", weekindex: 0, enabled: false, start: "10:00", end: "19:00" },
      { tab: "mon", name: "月曜", weekindex: 1, enabled: false, start: "10:00", end: "19:00" },
      { tab: "tue", name: "火曜", weekindex: 2, enabled: false, start: "10:00", end: "19:00" },
      { tab: "wed", name: "水曜", weekindex: 3, enabled: false, start: "10:00", end: "19:00" },
      { tab: "thu", name: "木曜", weekindex: 4, enabled: false, start: "10:00", end: "19:00" },
      { tab: "fri", name: "金曜", weekindex: 5, enabled: false, start: "10:00", end: "19:00" },
      { tab: "sat", name: "土曜", weekindex: 6, enabled: false, start: "10:00", end: "19:00" },
    ],
    time_zone: state.userInfo["timezone"],
  };*/
  const days = [
    { label: "Mon.", weekindex: 1, name: "月曜", default: true },
    { label: "Tue.", weekindex: 2, name: "火曜", default: true },
    { label: "Wed.", weekindex: 3, name: "水曜", default: true },
    { label: "Thu.", weekindex: 4, name: "木曜", default: true },
    { label: "Fri.", weekindex: 5, name: "金曜", default: true },
    { label: "Sat.", weekindex: 6, name: "土曜", default: false },
    { label: "San.", weekindex: 0, name: "日曜", default: false },
    { label: "Holiday", weekindex: 7, name: "祝日", default: false },
  ];

  const hours = ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];

  const changeStartHour = (event) => {
    setStartTime(event.target.value);
    const tmpAccountsPreference = JSON.parse(JSON.stringify(accountsPreference));
    tmpAccountsPreference.base_time_json.map((days, index) => {
      tmpAccountsPreference.base_time_json[index].start = userBusinessStartRef.current.value;
    });
    setAccountsPreference(tmpAccountsPreference);
  };

  const changeEndHour = (event) => {
    setEndTime(event.target.value);
    const tmpAccountsPreference = JSON.parse(JSON.stringify(accountsPreference));
    tmpAccountsPreference.base_time_json.map((days, index) => {
      tmpAccountsPreference.base_time_json[index].end = userBusinessEndRef.current.value;
    });
    setAccountsPreference(tmpAccountsPreference);
  };

  const changeBusinessDay = (weekindex, event) => {
    const tmpAccountsPreference = JSON.parse(JSON.stringify(accountsPreference));
    if (weekindex > 6) {
      tmpAccountsPreference.including_holidays = event.target.checked;
    } else {
      tmpAccountsPreference.base_time_json[weekindex].enabled = event.target.checked;
    }
    setAccountsPreference(tmpAccountsPreference);
  };

  const handleSubmitPreference = useCallback(
    async (accountsPreference, userInfo, accessToken, event) => {
      const tmpAccountsPreference = JSON.parse(JSON.stringify(accountsPreference));
      tmpAccountsPreference.nickname = nicknameRef.current.value;
      tmpAccountsPreference.email = userInfo["email"];
      tmpAccountsPreference.time_zone = userInfo["timezone"];
      console.log(tmpAccountsPreference, state.accessToken);
      try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        //idToken
        const results = await axios.put(`${process.env.REACT_APP_API_BASE_URL}me/accounts/preference`, tmpAccountsPreference, {
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        });
        setAppState({
          userInfo: { nickname: results.data.nickname, email: results.data.email, time_zone: results.data.time_zone },
          accessToken: state.accessToken,
          calendar: state.calendar,
          today: state.today,
          blockTime: {},
          calendarConnect: state.calendarConnect,
          baseTimeJson: JSON.parse(results.data.base_time_json),
          blockRange: {},
          blockDays: [],
          includingHoliday: state.includingHoliday,
          zoomConnect: state.zoomConnect,
        });
        props.close(true);
      } catch (error) {
        console.log(error);
      }
    },
    [history]
  );

  const charLengthValidation = (e, min, max) => {
    if (e.target.value.length < min || e.target.value.length > max) {
      console.log("invalid");
      e.target.classList.add("invalid");
    } else {
      console.log("valid");
      e.target.classList.remove("invalid");
    }
  };

  return (
    <ModalMask>
      <ModalWrap>
        <ModalContainer>
          <PageTitleWrap>
            <PageTitle>初期設定</PageTitle>
          </PageTitleWrap>
          <ContentInnter>
            <Headings>
              ユーザー名<Required>必須</Required>
            </Headings>
            <TextFieldWrap>
              <TextField
                type="text"
                ref={nicknameRef}
                placeholder="ユーザー名"
                name="user-name"
                id="user-name"
                defaultValue={state.userInfo["nickname"]}
                onChange={(e) => {
                  charLengthValidation(e, 2, 15);
                }}
              />
            </TextFieldWrap>
            <SelectCountryWrap>
              <Headings>国</Headings>
              <SelectWrap>
                <SelectboxCountry ref={time_zoneRef}>
                  <SelectboxOption value="Asia/Tokyo">日本</SelectboxOption>
                </SelectboxCountry>
              </SelectWrap>
            </SelectCountryWrap>
            <Headings>調整可能な時間帯</Headings>
            <SelectTimeWrap>
              <SubHeadings>Start</SubHeadings>
              <SelectWrap>
                <SelectboxTime
                  onChange={(event) => {
                    changeStartHour(event);
                  }}
                  value={startTime}
                  ref={userBusinessStartRef}
                >
                  {hours.map((hour, index) => (
                    <SelectboxOption key={`select-${index}`} value={hour}>
                      {hour}
                    </SelectboxOption>
                  ))}
                </SelectboxTime>
              </SelectWrap>
            </SelectTimeWrap>
            <SelectTimeWrap>
              <SubHeadings>End</SubHeadings>
              <SelectWrap>
                <SelectboxTime
                  ref={userBusinessEndRef}
                  onChange={(event) => {
                    changeEndHour(event);
                  }}
                  value={endTime}
                >
                  {hours.map((hour, index) => (
                    <SelectboxOption key={`select-${index}`} value={hour}>
                      {hour}
                    </SelectboxOption>
                  ))}
                </SelectboxTime>
              </SelectWrap>
            </SelectTimeWrap>
            <SubText>早朝や夜、休日などに予定を調整する場合はその時間帯も含めて設定してください。調整可能な時間帯や曜日は設定でいつでも変更可能です。</SubText>
            <Headings>調整可能な曜日</Headings>
            <DaysWrap>
              {days.map((day, index) => (
                <CheckboxDay
                  key={`day-${index}`}
                  label={day.label}
                  initChecked={day.default}
                  weekindex={day.weekindex}
                  onChange={(event) => {
                    changeBusinessDay(day.weekindex, event);
                  }}
                />
              ))}
            </DaysWrap>
            <ButtonWrap>
              <ButtonPrimary
                disabled={false}
                onClick={(e) => {
                  handleSubmitPreference(accountsPreference, state.userInfo, state.accessToken, e);
                }}
              >
                設定を保存
              </ButtonPrimary>
            </ButtonWrap>
          </ContentInnter>
        </ModalContainer>
      </ModalWrap>
    </ModalMask>
  );
};
