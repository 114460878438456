import React from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";

const Container = styled.div`
  margin: -2px 15px 0 0;
  width: 30px;
`;

const Colum = styled.p`
  line-height: 0;
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: ${colors.black.hex};
  /*border-bottom: 2px solid ${colors.black.hex};*/
  height: 80px;
`;

const TextWrap = styled.span`
  display: block;
`;

export const Timeline: React.FC<{}> = () => {
  return (
    <Container>
      <Colum>
        <TextWrap>00:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>01:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>02:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>03:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>04:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>05:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>06:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>07:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>08:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>09:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>10:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>11:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>12:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>13:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>14:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>15:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>16:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>17:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>18:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>19:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>20:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>21:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>22:00</TextWrap>
      </Colum>
      <Colum>
        <TextWrap>23:00</TextWrap>
      </Colum>
    </Container>
  );
};
