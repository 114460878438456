import moment from "moment";

//昨年、来年、今年のカレンダーを作ってくれる。昨年年始と来年末の週で月曜から不足しているところを補完もしてくれる。
export const makeCalendar = (currentYear) => {
  let months = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const lastYearDate = ["12-25", "12-26", "12-27", "12-28", "12-29", "12-30", "12-31"].reverse();
  const nextYearDate = ["01-01", "01-02", "01-03", "01-04", "01-05", "01-06", "01-07"];
  const days = ["Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat.", "San."];
  //ただし4で割り切れる年はうるう年で100で割り切れる年は平年、400で割り切れる年はうるう年。（当面考えなくてもいいんですが。。。）
  //1.まず昨年、今年、来年の日付の配列を単純に作る
  //2.昨年の1/1の曜日を調べて前前年の年末の日付を補完する、来年の年末の足りない日も再来年から補完する
  //3.3年間の日付の配列を1週間ごとにひとつの配列（このときにオブジェクトに変えていく。フォーマットは以下な感じ）
  //ポイントは月曜日はじまりの週ごとの配列を作ること。ひょっとしたら3年も不要で今年と来年のカレンダーがあれば十分かも。
  //前後1年（計3年分のカレンダーをもつ）、重要なのは曜日で日付の配列ができたら前年の1月1日の曜日を調べてそこから月、火、水、木、金、土、日で完結する配列を作る（ただし前年の1週目は月曜日はじまりじゃない限り空きの配列にしておく。来年の最終週はその翌年の日を入れておく）
  /*[{date: "2020-10-5", day: "Mon.", holiday:false, holiday_name:"", data: [{ここにサーバーからもらった空き情報を突っ込む}]},
      {date: "2020-10-6", day: "Tue.", holiday:false, holiday_name:"", data: [{ここにサーバーからもらった空き情報を突っ込む}]},
      {date: "2020-10-7", day: "Wed.", holiday:false, holiday_name:"", data: [{ここにサーバーからもらった空き情報を突っ込む}]},
      {date: "2020-10-8", day: "Thu.", holiday:false, holiday_name:"", data: [{ここにサーバーからもらった空き情報を突っ込む}]},
      {date: "2020-10-9", day: "Fri.", holiday:false, holiday_name:"", data: [{ここにサーバーからもらった空き情報を突っ込む}]},
      {date: "2020-10-10", day: "Sat.", holiday:false, holiday_name:"", data: [{ここにサーバーからもらった空き情報を突っ込む}]},
      {date: "2020-10-11", day: "San.", holiday:false, holiday_name:"", data: [{ここにサーバーからもらった空き情報を突っ込む}]}]*/
  //うるう年だけ2月を29日にする
  let calendar = [];
  let year = 0;
  let dateLength = 0;
  for (let i = 0; i < 3; i++) {
    year = currentYear - 1 + i;
    for (let h = 0; h < months.length; h++) {
      if (h === 1) {
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
          dateLength = 29;
        } else {
          dateLength = 28;
        }
      } else {
        dateLength = months[h];
      }
      for (let j = 0; j < dateLength; j++) {
        const month = h < 9 ? `0${h + 1}` : h + 1;
        const date = j < 9 ? `0${j + 1}` : j + 1;
        //console.log(`${currentYear - 1 + i}-${month}-${date}`);
        calendar.push(`${currentYear - 1 + i}-${month}-${date}`);
      }
    }
  }
  //昨年の第一週を補完する
  for (let i = 0; i < moment(calendar[0]).day(); i++) {
    const date = `${currentYear - 2}-${lastYearDate[i]}`;
    //console.log(date);
    calendar.unshift(date);
  }
  //来年の年末の不足分を補完する
  for (let i = 0; i < 7 - moment(calendar.slice(-1)[0]).day(); i++) {
    const date = `${Number(currentYear) + 1}-${nextYearDate[i]}`;
    //console.log(date);
    calendar.push(date);
  }
  let counter = 0;
  let tmpArray = [];
  let tmpCalendarArray = [];
  calendar.map((date) => {
    if (counter > 6) {
      counter = 0;
      tmpCalendarArray.push(tmpArray);
      tmpArray = [];
    }
    const dateObj = { date: date, day: days[counter], holiday: false, holiday_name: "", data: [] };
    tmpArray.push(dateObj);
    counter++;
  });
  //祝日と空き日程などもこれに追加していく。
  return tmpCalendarArray;
};

//日付を送るとそれがカレンダーの何周目か返してくれる
export const whatWeek = (calendar, today) => {
  let weekIndex = 0;
  calendar.some((week, index) => {
    const target = week.find((dateObj) => {
      return dateObj.date === today;
    });
    if (target !== undefined) {
      weekIndex = index;
      return index;
    }
  });
  return weekIndex;
};

export const addFreebusy = (calendar, freebusies) => {
  freebusies.map((freebusy) => {
    const date = moment(freebusy.start_time).format("YYYY-MM-DD");
    calendar.map((week) => {
      const target = week.find((dateObj) => {
        return dateObj.date === date;
      });
      if (target !== undefined) {
        target.data.push(freebusy);
      }
    });
  });
  return calendar;
};

export const removeFreebusy = (calendar) => {
  calendar.map((week) => {
    week.map((date) => {
      date.data = [];
    });
  });
  return calendar;
};

export const removeEvent = (calendar, filterFunc) => {
  calendar.map((week) => {
    week.map((date) => {
      date.data = date.data.filter(x => filterFunc(x));
    });
  });
  return calendar;
};
