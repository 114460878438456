import React, { useState, useRef, useCallback, useEffect, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Layout } from "../components/Layout";
import { Header } from "../components/molecules/Header";
import Footer from "../components/molecules/Footer";
import { Sidebar } from "../components/molecules/Sidebar";
import { ButtonPrimary, ButtonRound, RelatedLink } from "../components/atoms/Button";
import { Timeline } from "../components/atoms/Timeline";
import { Textarea } from "../components/atoms/Textarea";
import { TextFieldBgWhite } from "../components/atoms/TextField";
import { DateTimeline } from "../components/molecules/DateTimeline";
import { Date } from "../components/molecules/Date";
import { Selectbox, SelectboxOption } from "../components/atoms/Selectbox";
import { ModalCalendar } from "../components/molecules/ModalCalendar";
import { Error } from "../components/molecules/Error";
import { Loading } from "../components/molecules/Loading";
import { NotificationAlert } from "../components/molecules/NotificationAlert";
import { TextFieldWrapper } from "../components/molecules/TextFiledWrapper";
import { colors } from "../data/colors";
import { ReactComponent as IcoArrow } from "../assets/ico_arrow.svg";
import { ReactComponent as IcoCreate } from "../assets/ico_create.svg";
import { ReactComponent as IcoEventNote } from "../assets/ico_event-note.svg";
import { ReactComponent as IcoTime } from "../assets/ico_time.svg";
import { ReactComponent as IcoDirectionsWalk } from "../assets/ico_directions-walk.svg";
import { ReactComponent as IcoEmail } from "../assets/ico_email.svg";
import { ReactComponent as IcoColorfulSchedule } from "../assets/ico_colorful-schedule.svg";
import { ReactComponent as IcoArrowDRounded } from "../assets/ico_arrow-rounded.svg";
import { ReactComponent as IcoOpenClose } from "../assets/ico_open-close.svg";
import { ReactComponent as IcoCopy } from "../assets/ico_copy.svg";
import { whatWeek, addFreebusy, removeFreebusy } from "../lib/Calendar";
import { useAppState, useSetAppState } from "../lib/Store";
import { TimeContext } from "../lib/CheckboxProvider";
import BgPopover from "../assets/bg_popover.png";

interface ContentProps {
  isSearch: boolean;
  isSearchBarOpen: boolean;
}

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  padding-top: 40px;
  background-color: ${colors.white.hex};
`;

const IcoCreateWrap = styled(IcoCreate)`
  fill: ${colors.black.hex};
  height: 16px;
  margin-right: 11px;
`;

const IcoEventNoteWrap = styled(IcoEventNote)`
  fill: ${colors.black.hex};
  height: 17px;
  margin-right: 11px;
`;

const IcoTimeWrap = styled(IcoTime)`
  fill: ${colors.black.hex};
  height: 16px;
  margin-right: 16px;
`;

const IcoDirectionsWalkWrap = styled(IcoDirectionsWalk)`
  fill: ${colors.black.hex};
  height: 23px;
  margin-right: 11px;
`;

const IcoEmailWrap = styled(IcoEmail)`
  fill: ${colors.black.hex};
  height: 11px;
  margin-right: 11px;
`;

const IcoEmailWhiteWrap = styled(IcoEmail)`
  fill: ${colors.keyColor.hex};
  height: 14px;
  margin-right: 10px;
`;

const IcoCopyWrap = styled(IcoCopy)`
  fill: ${colors.white.hex};
  height: 14px;
`;

const IcoColorfulScheduleWrap = styled(IcoColorfulSchedule)`
  height: 22px;
`;

const NavSearchWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 312px;
  height: 104px;
  background-color: ${colors.modalBg.rgba};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonPrimarySearch = styled(ButtonPrimary)`
  width: 108px;
  height: 32px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 4px;
  &:disabled {
    svg {
      fill: ${colors.buttonGrayText.hex};
    }
  }
`;

const ContentWrap = styled.div<ContentProps>`
  position: absolute;
  top: 64px;
  left: ${(props) => (props.isSearchBarOpen ? 335 : 26)}px;
  width: 100%;
  background-color: ${colors.white.hex};
  width: calc(100% - ${(props) => (props.isSearchBarOpen ? 460 : 152)}px);
  height: calc(100% - 88px);
  padding: 16px;
`;

const ContentWrapCover = styled.div<ContentProps>`
  position: absolute;
  top: 64px;
  left: ${(props) => (props.isSearchBarOpen ? 335 : 26)}px;
  width: 100%;
  background-color: ${colors.modalBg.rgba};
  width: calc(100% - ${(props) => (props.isSearch ? 340 : 152)}px);
  height: calc(100% - 88px);
  z-index: 700;
  display: ${(props) => (props.isSearch ? `block` : `none`)};
`;

const Popover = styled.div`
  font-size: 10px;
  color: ${colors.keyColor.hex};
  top: 40px;
  left: -30px;
  padding: 12px 16px 12px 23px;
  position: absolute;
  width: 154px;
  height: 58px;
  background-image: url(${BgPopover});
`;

const TimelineWrap = styled.div`
  display: flex;
  height: calc(100% - 90px);
  overflow-y: scroll;
  padding: 8px 10px 0 0;
`;

const DateWrap = styled.div`
  display: flex;
  width: 100%;
  margin: 0 16px;
`;

const IcoArrowPrevWrap = styled(IcoArrow)`
  fill: ${colors.keyColor.hex};
  width: 15px;
  height: 15px;
`;

const IcoArrowNextWrap = styled(IcoArrow)`
  fill: ${colors.keyColor.hex};
  width: 15px;
  height: 15px;
  transform: scaleX(-1);
`;

const IcoOpenCloseWrap = styled(IcoOpenClose)`
  fill: ${colors.white.hex};
  width: 12px;
  height: 8px;
`;

const ButtonPrimarySuqure30x30 = styled(ButtonPrimary)`
  width: 30px;
  height: 30px;
  min-width: 30px;
  border: 0;
`;

const ContentHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SearchContent = styled.div<ContentProps>`
  width: 312px;
  padding: 16px;
  height: calc(100% - 64px);
  position: absolute;
  top: 40px;
  left: ${(props) => (props.isSearchBarOpen ? 0 : -312)}px;
  background-color: ${colors.white.hex};
  border-right: 2px solid ${colors.keyColor.hex};
`;

const RotateWrap = styled.div<ContentProps>`
  transform: rotate(${(props) => (props.isSearchBarOpen ? 0 : 180)}deg);
`;

const IconSubHeadings = styled.h2`
  font-size: 14px;
  color: ${colors.darkGray.hex};
  display: flex;
  align-items: center;
`;

const Required = styled.span`
  color: ${colors.white.hex};
  font-size: 10px;
  padding: 0 10px;
  background-color: ${colors.orange.hex};
  margin-left: 12px;
`;

const SelectWrap = styled.div`
  width: 97px;
  position: relative;
  display: inline-block;
`;

const SelectboxTime = styled(Selectbox)`
  font-size: 14px;
`;

const DurationWrap = styled.div`
  position: relative;
  background-color: ${colors.silverGray.hex};
  width: 253px;
  height: 80px;
  padding: 12px 8px;
  cursor: pointer;
`;

const ButtonRoundCalendar = styled(ButtonRound)`
  position: absolute;
  right: 16px;
  top: 28px;
  width: 24px;
  height: 22px;
  border-radius: 0;
  padding: 0;
  background-color: transparent;
`;

const TextFieldBgWhiteYearWrap = styled.div`
  width: 88px;
`;

const TextFieldBgWhiteMonthDateWrap = styled.div`
  width: 32px;
`;

const MiniHeadings = styled.span`
  display: inline-block;
  width: 25px;
  font-size: 9px;
  font-weight: bold;
  color: ${colors.gray.hex};
`;

const DateFieldWrap = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IcoArrowDRoundedWrap = styled(IcoArrowDRounded)`
  fill: ${colors.keyColor.hex};
  height: 10px;
`;

const ButtonIconSubText = styled.p`
  width: calc(100% / 0.8);
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  //transform: scale(0.8);
  //transform-origin: center left;
`;

const SendContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 60px;
`;

const SendContent = styled.div`
  width: 560px;
  min-height: 352px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
`;

const ButtonPrimarySendEmail = styled(RelatedLink)`
  width: 159px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonPrimaryCopy = styled(ButtonPrimary)`
  width: 34px;
  height: 34px;
  background-color: ${colors.orange.hex};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(1px, 0px);
`;

const CopyUrlBox = styled.div`
  border: 2px solid ${colors.orange.hex};
  width: 485px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
`;

const CopyUrl = styled.input`
  background-color: ${colors.white.hex};
  border: none;
  box-sizing: border-box;
  width: 449px;
  height: 33px;
  margin: 0;
  padding: 5px 18px;
  font-size: 12px;
`;

const Headings = styled.h1`
  font-size: 24px;
  font-weight: bold;
`;

const SubCopy1 = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 8px 0 30px;
`;

const SubCopy2 = styled.p`
  font-size: 12px;
  text-align: center;
  margin: 8px 0 30px;
`;

const DateTimelineWrap = styled.div`
  width: 100%;
  margin: 0 16px 0 0;
  display: flex;
`;

const MessageTextarea = styled(Textarea)`
  height: 106px;
`;

const ButtonOpenClose = styled(ButtonPrimary)`
  width: 18px;
  height: 38px;
  background-color: ${colors.keyColor.hex};
`;

const ButtonOpenCloseWrap = styled.div`
  position: absolute;
  top: 50%;
  right: -19px;
  margin-top: -19px;
`;

export const Schedule: React.FC<{}> = () => {
  const { range, blockDays, clearBlockDays } = useContext(TimeContext);
  const history = useHistory();
  const state = useAppState();
  const setAppState = useSetAppState();
  const [mode, setMode] = useState("search");
  const [isSearch, setIsSearch] = useState(true);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [uuid, setUuid] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [invitationUrl] = useState(`${process.env.REACT_APP_HOST_URL}/invitation?url_code=`);
  const [gmailUrl] = useState("https://mail.google.com/mail/?view=cm&");
  const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(true);

  const minutes = ["15", "30", "45", "60", "90", "120"];
  const move = ["0", "15", "30", "45", "60"];

  // ミーティングツール
  const meetingTools: {text: string, value: string} [] = [
    {text: "なし", value: "nothing"},
    {text: "Zoom", value: "zoom"},
  ].filter(x => {
    if (!state.zoomConnect && x.value === "zoom") {
      return false;
    }
    return true;
  });;

  const [eventTitle, setEventTitle] = useState("");
  const [eventMessage, setEventMessage] = useState("");
  const [mainMinutes, setMainMinutes] = useState(Number(minutes[3]));
  const [bufferMinutes, setBufferMinutes] = useState(Number(move[0]));
  const [timeMin, setTimeMin] = useState("");
  const [timeMax, setTimeMax] = useState("");
  const [meetingTool, setMeetingTool] = useState("");

  const [blockTime, setBlockTime] = useState([]);

  const [weekCounter, setWeekCounter] = useState(0);
  const [weekArray, setWeekArray] = useState([
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
    { date: "-", day: "-", holiday: false, holiday_name: "", data: [] },
  ]);

  const [timeMinYear, setTimeMinYear] = useState("");
  const [timeMinMonth, setTimeMinMonth] = useState("");
  const [timeMinDate, setTimeMinDate] = useState("");
  const [timeMaxYear, setTimeMaxYear] = useState("");
  const [timeMaxMonth, setTimeMaxMonth] = useState("");
  const [timeMaxDate, setTimeMaxDate] = useState("");

  const mainMinutesRef = useRef(null);
  const bufferMinutesRef = useRef(null);
  const eventTitleRef = useRef(null);
  const eventMessageRef = useRef(null);
  const meetingToolRef = useRef(null);

  const timeMinYearRef = useRef(null);
  const timeMinMonthRef = useRef(null);
  const timeMinDateRef = useRef(null);
  const timeMaxYearRef = useRef(null);
  const timeMaxMonthRef = useRef(null);
  const timeMaxDateRef = useRef(null);
  const timelineRef = useRef(null);

  const invitationUrlRef = useRef(null);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isCopyClipboard, setIsCopyClipboard] = useState(false);

  const [isBusyOnly, setIsBusyOnly] = useState(false);
  const [isShowPopover, setIsShowPopover] = useState(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(true);

  const updateValiable = () => {
    setMainMinutes(Number(mainMinutesRef.current.value));
    setBufferMinutes(Number(bufferMinutesRef.current.value));
    setEventTitle(eventTitleRef.current.value);
    setEventMessage(eventMessageRef.current.value);
    setMeetingTool(meetingToolRef.current.value);
    setTimeMin(moment(`${timeMinYearRef.current.value}-${timeMinMonthRef.current.value}-${timeMinDateRef.current.value} 00:00:00`).format("YYYY-MM-DDTHH:mm:ss.000Z"));
    setTimeMax(moment(`${timeMaxYearRef.current.value}-${timeMaxMonthRef.current.value}-${timeMaxDateRef.current.value} 00:00:00`).format("YYYY-MM-DDTHH:mm:ss.000Z"));
  };

  const backToSearch = (calendar) => {
    const newCalendar = removeFreebusy(calendar);
    setAppState({
      userInfo: state.userInfo,
      accessToken: state.accessToken,
      calendar: newCalendar,
      today: state.today,
      blockTime: {},
      calendarConnect: state.calendarConnect,
      baseTimeJson: state.baseTimeJson,
      blockRange: {},
      blockDays: [],
      includingHoliday: state.includingHoliday,
      zoomConnect: state.zoomConnect,
    });
    setIsSearch(true);
    clearBlockDays();
  };

  const searchFreebusy = useCallback(
    async (event) => {
      backToSearch(state.calendar);
      setIsLoaded(true);
      setIsBusyOnly(false);
      updateValiable();

      const data = {
        main_minutes: Number(mainMinutesRef.current.value),
        buffer_minutes: Number(bufferMinutesRef.current.value),
        time_min: moment(`${timeMinYearRef.current.value}-${timeMinMonthRef.current.value}-${timeMinDateRef.current.value} 00:00:00`).format("YYYY-MM-DDTHH:mm:ss.000Z"),
        time_max: moment(`${timeMaxYearRef.current.value}-${timeMaxMonthRef.current.value}-${timeMaxDateRef.current.value} 00:00:00`).format("YYYY-MM-DDTHH:mm:ss.000Z"),
      };
      console.log(data, state.accessToken, mainMinutesRef.current.value);
      try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const results = await axios.post(`${process.env.REACT_APP_API_BASE_URL}me/events/freebusy`, data, {
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}` },
        });
        const firstDate = moment(results.data.freebusy[0]["start_time"]).format("YYYY-MM-DD");
        const currentWeekIndex = whatWeek(state.calendar, firstDate);
        setWeekCounter(currentWeekIndex);
        setWeekArray(state.calendar[currentWeekIndex]);
        setIsLoaded(false);
        setIsSearch(false);
        setIsShowPopover(false);
        //カレンダーに空いている時間とつまっている時間を送るとカレンダーに追加して返してくれる。
        const newCalendar = addFreebusy(state.calendar, results.data.freebusy);
        setAppState({
          userInfo: state.userInfo,
          accessToken: state.accessToken,
          calendar: newCalendar,
          today: state.today,
          blockTime: {},
          calendarConnect: state.calendarConnect,
          baseTimeJson: state.baseTimeJson,
          blockRange: {},
          blockDays: [],
          includingHoliday: state.includingHoliday,
          zoomConnect: state.zoomConnect,
        });
      } catch (error) {
        setIsLoaded(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
        console.log(error);
      }
    },
    [history]
  );

  const timeQueries = useCallback(
    async (blockTimeArray, blockDaysArray, rangeObject, otherObject, event) => {
      setIsLoaded(true);

      const data = {
        main: otherObject.mainMinutes,
        buffer: otherObject.bufferMinutes,
        time_min: otherObject.timeMin,
        time_max: otherObject.timeMax,
        event_title: otherObject.eventTitle,
        event_message: otherObject.eventMessage,
        meeting_tool: otherObject.meetingTool,
        block_time_json: JSON.stringify(blockTimeArray),
        block_range_start: rangeObject.start,
        block_range_end: rangeObject.end,
        block_days: blockDaysArray,
      };

      try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const results = await axios.post(`${process.env.REACT_APP_API_BASE_URL}time_queries`, data, {
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}` },
        });
        setIsLoaded(false);
        setUuid(results.data.uuid);
        setExpiresAt(moment(results.data.expires_at).format("YYYY/MM/DD HH:mm"));
        setMode("send");
      } catch (error) {
        setIsLoaded(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
      }
    },
    [history]
  );

  const concatTimeMin = (y, m, d) => {
    setTimeMinYear(y);
    setTimeMinMonth(m);
    setTimeMinDate(d);
    console.log(y, m, d);
  };

  const concatTimeMax = (y, m, d) => {
    setTimeMaxYear(y);
    setTimeMaxMonth(m);
    setTimeMaxDate(d);
    console.log(y, m, d);
  };

  //最初にアポイントがあるかどうか取得
  useEffect(() => {
    const fn = () => {
      if (state.accessToken === "") {
        return history.push("/home");
      }
      setTimeMinYear(moment(state.today).add(1, "d").format("YYYY"));
      setTimeMinMonth(moment(state.today).add(1, "d").format("MM"));
      setTimeMinDate(moment(state.today).add(1, "d").format("DD"));
      setTimeMaxYear(moment(state.today).add(15, "d").format("YYYY"));
      setTimeMaxMonth(moment(state.today).add(15, "d").format("MM"));
      setTimeMaxDate(moment(state.today).add(15, "d").format("DD"));

      const currentWeekIndex = whatWeek(state.calendar, state.today);
      setWeekCounter(currentWeekIndex);
      setWeekArray(state.calendar[currentWeekIndex]);
      timelineRef.current.scrollTo(0, 500);
    };
    fn();
  }, [history]);

  const prevWeek = () => {
    const currentWeekIndex = weekCounter - 1;
    setWeekCounter(currentWeekIndex);
    setWeekArray(state.calendar[currentWeekIndex]);
  };
  const nextWeek = () => {
    const currentWeekIndex = weekCounter + 1;
    setWeekCounter(currentWeekIndex);
    setWeekArray(state.calendar[currentWeekIndex]);
  };

  //フリーの枠を追加でブロックする
  const addBlockTime = (startTime, endTime, uuid) => {
    let array = JSON.parse(JSON.stringify(blockTime));
    array.push({ start_time: startTime, end_time: endTime });
    setBlockTime(array);
    blockTime.push({ start_time: startTime, end_time: endTime });
  };

  //フリーの枠を追加でブロックする
  const removeBlockTime = (startTime, endTime, uuid) => {
    blockTime.map((item, index) => {
      if (item.start_time === startTime && item.end_time === endTime) {
        blockTime.splice(index, 1);
      }
    });
  };

  const inputCheck = (e) => {
    if (eventTitleRef.current.value.length > 0) {
      setIsSearchBtnDisabled(false);
    }
  };

  const clipboardCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(invitationUrlRef.current.value);
    }
  };

  const charLengthValidation = (e, min, max) => {
    if (e.target.value.length < min || e.target.value.length > max) {
      console.log("invalid");
      e.target.classList.add("invalid");
    } else {
      console.log("valid");
      e.target.classList.remove("invalid");
    }
    updateValiable();
  };

  //最初にアポイントがあるかどうか取得
  useEffect(() => {
    setIsLoaded(true);
    setIsBusyOnly(true);
    if (!state.today) {
      return;
    }
    const fn = async () => {
      const data = {
        main_minutes: 60,
        buffer_minutes: 0,
        time_min: moment(`${state.today} 00:00:00`).format("YYYY-MM-DDTHH:mm:ss.000Z"),
        time_max: moment(`${state.today} 00:00:00`).add(7, "days").format("YYYY-MM-DDTHH:mm:ss.000Z"),
      };
      try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const results = await axios.post(`${process.env.REACT_APP_API_BASE_URL}me/events/freebusy`, data, {
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.accessToken}` },
        });
        const firstDate = moment(results.data.freebusy[0]["start_time"]).format("YYYY-MM-DD");
        const currentWeekIndex = whatWeek(state.calendar, firstDate);
        setWeekCounter(currentWeekIndex);
        setWeekArray(state.calendar[currentWeekIndex]);
        setIsLoaded(false);
        //カレンダーに空いている時間とつまっている時間を送るとカレンダーに追加して返してくれる。
        const newCalendar = addFreebusy(state.calendar, results.data.freebusy);
        setAppState({
          userInfo: state.userInfo,
          accessToken: state.accessToken,
          calendar: newCalendar,
          today: state.today,
          blockTime: {},
          calendarConnect: state.calendarConnect,
          baseTimeJson: state.baseTimeJson,
          blockRange: {},
          blockDays: [],
          includingHoliday: state.includingHoliday,
          zoomConnect: state.zoomConnect,
        });
      } catch (error) {
        setIsLoaded(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
        console.log(error);
      }
    };
    fn();
  }, [history]);

  return (
    <Layout title="新規日程調整">
      <Header />
      {mode === "search" && (
        <MainContent>
          <SearchContent isSearch={isSearch} isSearchBarOpen={isSearchBarOpen}>
            <IconSubHeadings>
              <IcoCreateWrap />
              タイトル
              <Required>必須</Required>
            </IconSubHeadings>
            <TextFieldWrapper min={2} max={30} count={eventTitle.length}>
              <Textarea
                ref={eventTitleRef}
                onChange={(e) => {
                  inputCheck(e);
                  charLengthValidation(e, 2, 30);
                }}
                defaultValue={eventTitle}
                placeholder="打ち合わせ"
              />
            </TextFieldWrapper>
            <IconSubHeadings>
              <IcoEventNoteWrap />
              調整期間
              <Required>必須</Required>
            </IconSubHeadings>
            <DurationWrap
              onClick={() => {
                setIsCalendarOpen(true);
              }}
            >
              <DateFieldWrap>
                <MiniHeadings>Start</MiniHeadings>
                <TextFieldBgWhiteYearWrap>
                  <TextFieldBgWhite ref={timeMinYearRef} value={timeMinYear} disabled={true} />
                </TextFieldBgWhiteYearWrap>
                <TextFieldBgWhiteMonthDateWrap>
                  <TextFieldBgWhite ref={timeMinMonthRef} value={timeMinMonth} disabled={true} />
                </TextFieldBgWhiteMonthDateWrap>
                <TextFieldBgWhiteMonthDateWrap>
                  <TextFieldBgWhite ref={timeMinDateRef} value={timeMinDate} disabled={true} />
                </TextFieldBgWhiteMonthDateWrap>
              </DateFieldWrap>
              <DateFieldWrap>
                <MiniHeadings>End</MiniHeadings>
                <TextFieldBgWhiteYearWrap>
                  <TextFieldBgWhite ref={timeMaxYearRef} value={timeMaxYear} disabled={true} />
                </TextFieldBgWhiteYearWrap>
                <TextFieldBgWhiteMonthDateWrap>
                  <TextFieldBgWhite ref={timeMaxMonthRef} value={timeMaxMonth} disabled={true} />
                </TextFieldBgWhiteMonthDateWrap>
                <TextFieldBgWhiteMonthDateWrap>
                  <TextFieldBgWhite ref={timeMaxDateRef} value={timeMaxDate} disabled={true} />
                </TextFieldBgWhiteMonthDateWrap>
              </DateFieldWrap>
              <ButtonRoundCalendar>
                <IcoColorfulScheduleWrap />
              </ButtonRoundCalendar>
            </DurationWrap>
            <IconSubHeadings>
              <IcoTimeWrap />
              所要時間
              <Required>必須</Required>
            </IconSubHeadings>
            <SelectWrap>
              <SelectboxTime
                ref={mainMinutesRef}
                value={mainMinutes}
                onChange={(e) => {
                  setMainMinutes(Number(e.target.value));
                }}
              >
                {minutes.map((minute, index) => (
                  <SelectboxOption key={`select-${index}`} value={minute}>
                    {minute}min
                  </SelectboxOption>
                ))}
              </SelectboxTime>
            </SelectWrap>
            <IconSubHeadings>
              <IcoDirectionsWalkWrap />
              移動時間
            </IconSubHeadings>
            <SelectWrap>
              <SelectboxTime
                ref={bufferMinutesRef}
                value={bufferMinutes}
                onChange={(e) => {
                  setBufferMinutes(Number(e.target.value));
                }}
              >
                {move.map((minute, index) => (
                  <SelectboxOption key={`select-${index}`} value={minute}>
                    {minute}min
                  </SelectboxOption>
                ))}
              </SelectboxTime>
            </SelectWrap>

            <IconSubHeadings>
              ミーティングツール
            </IconSubHeadings>
            {!state.zoomConnect &&
            <p>"zoom is not linked"</p>
            }
            <Selectbox
              ref={meetingToolRef}
              value={meetingTool}
              onChange={(e) =>
                setMeetingTool(e.target.value)
              }
            >
              {meetingTools.map((meetingTool, index) => (
                  <SelectboxOption key={`select-${index}`} value={meetingTool.value}>
                    {meetingTool.text}
                  </SelectboxOption>
              ))}
            </Selectbox>

            <IconSubHeadings>
              <IcoEmailWrap />
              メッセージ
            </IconSubHeadings>
            <TextFieldWrapper min={-1} max={200} count={eventMessage.length}>
              <MessageTextarea
                ref={eventMessageRef}
                value={eventMessage}
                placeholder="場所は弊社オフィスにてお願い致します。"
                maxLength={200}
                onChange={(e) => {
                  setEventMessage(e.target.value);
                }}
              />
            </TextFieldWrapper>
            <NavSearchWrap>
              <ButtonPrimarySearch onClick={searchFreebusy} disabled={isSearchBtnDisabled}>
                <ButtonIconSubText>候補を探す</ButtonIconSubText>
                <IcoArrowDRoundedWrap />
              </ButtonPrimarySearch>
            </NavSearchWrap>
            <ButtonOpenCloseWrap>
              <ButtonOpenClose
                onClick={(e) => {
                  if (isSearchBarOpen) {
                    setIsSearchBarOpen(false);
                  } else {
                    setIsSearchBarOpen(true);
                  }
                }}
              >
                <RotateWrap isSearch={isSearch} isSearchBarOpen={isSearchBarOpen}>
                  <IcoOpenCloseWrap />
                </RotateWrap>
              </ButtonOpenClose>
            </ButtonOpenCloseWrap>
          </SearchContent>

          <ContentWrap isSearch={isSearch} isSearchBarOpen={isSearchBarOpen}>
            <ContentHeaderWrap>
              <ButtonPrimarySuqure30x30
                onClick={() => {
                  prevWeek();
                }}
              >
                <IcoArrowPrevWrap />
              </ButtonPrimarySuqure30x30>

              <DateWrap>
                <Date data={weekArray[0]} day="月" today={state.today} />
                <Date data={weekArray[1]} day="火" today={state.today} />
                <Date data={weekArray[2]} day="水" today={state.today} />
                <Date data={weekArray[3]} day="木" today={state.today} />
                <Date data={weekArray[4]} day="金" today={state.today} />
                <Date data={weekArray[5]} day="土" today={state.today} />
                <Date data={weekArray[6]} day="日" today={state.today} />
              </DateWrap>
              <ButtonPrimarySuqure30x30
                onClick={() => {
                  nextWeek();
                }}
              >
                <IcoArrowNextWrap />
              </ButtonPrimarySuqure30x30>
            </ContentHeaderWrap>
            <TimelineWrap ref={timelineRef}>
              <Timeline />
              <DateTimelineWrap>
                <DateTimeline data={weekArray[0]} day="月" addFunction={addBlockTime} removeFunction={removeBlockTime} isGuest={false} isBusyOnly={isBusyOnly} />
                <DateTimeline data={weekArray[1]} day="火" addFunction={addBlockTime} removeFunction={removeBlockTime} isGuest={false} isBusyOnly={isBusyOnly} />
                <DateTimeline data={weekArray[2]} day="水" addFunction={addBlockTime} removeFunction={removeBlockTime} isGuest={false} isBusyOnly={isBusyOnly} />
                <DateTimeline data={weekArray[3]} day="木" addFunction={addBlockTime} removeFunction={removeBlockTime} isGuest={false} isBusyOnly={isBusyOnly} />
                <DateTimeline data={weekArray[4]} day="金" addFunction={addBlockTime} removeFunction={removeBlockTime} isGuest={false} isBusyOnly={isBusyOnly} />
                <DateTimeline data={weekArray[5]} day="土" addFunction={addBlockTime} removeFunction={removeBlockTime} isGuest={false} isBusyOnly={isBusyOnly} />
                <DateTimeline data={weekArray[6]} day="日" addFunction={addBlockTime} removeFunction={removeBlockTime} isGuest={false} isBusyOnly={isBusyOnly} />
              </DateTimelineWrap>
            </TimelineWrap>
          </ContentWrap>
          <ContentWrapCover
            isSearch={isSearch}
            isSearchBarOpen={isSearchBarOpen}
            onClick={() => {
              setIsShowPopover(true);
            }}
          >
            {isShowPopover && <Popover>左側の必須項目を入力してください。</Popover>}
          </ContentWrapCover>
          <Sidebar timeQueries={timeQueries} isSearch={isSearch} blockTimeArray={blockTime} blockDaysArray={blockDays} rangeObject={range} otherObject={{ mainMinutes: mainMinutes, bufferMinutes: bufferMinutes, timeMin: timeMin, timeMax: timeMax, eventTitle: eventTitle, eventMessage: eventMessage, meetingTool: meetingTool }} />
        </MainContent>
      )}
      {mode === "send" && (
        <MainContent>
          <SendContainer>
            <SendContent>
              <Headings>URL作成完了</Headings>
              <SubCopy1>日程調整用のURLを発行しました。コピーしてゲストにお送りください。</SubCopy1>
              <CopyUrlBox>
                <CopyUrl ref={invitationUrlRef} type="text" defaultValue={`${invitationUrl}${uuid}`} name="url" id="url" />
                <ButtonPrimaryCopy
                  onClick={() => {
                    clipboardCopy();
                    setIsCopyClipboard(true);
                  }}
                >
                  <IcoCopyWrap />
                </ButtonPrimaryCopy>
              </CopyUrlBox>
              <SubCopy2>URL有効期限: {expiresAt}</SubCopy2>
              <ButtonPrimarySendEmail href={`${gmailUrl}&su=【TimeLab】日程調整のお願い&body=${state.userInfo["nickname"]}さんより日程調整依頼が届いております。%0D%0A以下のURLよりご都合の良い日時を選択してください。%0D%0A%0D%0A${invitationUrl}${uuid}`} target="_blank">
                <IcoEmailWhiteWrap />
                GMAILで送信
              </ButtonPrimarySendEmail>
            </SendContent>
          </SendContainer>
        </MainContent>
      )}
      <Footer />
      {isCalendarOpen && <ModalCalendar close={setIsCalendarOpen} concatTimeMinFunc={concatTimeMin} concatTimeMaxFunc={concatTimeMax} today={state.today} />}
      <NotificationAlert
        show={isCopyClipboard}
        callback={() => {
          setIsCopyClipboard(false);
        }}
        text={"URLをクリップボードにコピーしました"}
      />
      {
        <Error
          isShow={isError}
          errorMessage={errorMessage}
          close={() => {
            setIsError(false);
          }}
        />
      }
      {isLoaded && <Loading />}
    </Layout>
  );
};
