import * as React from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 24px;
  background-color: ${colors.groundDark.hex};
  width: 100%;
  font-size: 8px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CopyrightMark = styled.span``;

export default () => (
  <Footer>
    <CopyrightMark>©</CopyrightMark>TimeLab Co., Ltd.
  </Footer>
);
