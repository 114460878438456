import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ButtonPrimary } from "../atoms/Button";
import { DateRangePicker } from "react-date-range";
import ja from "date-fns/locale/ja";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

type ModalCalendarProps = {
  today: string;
  close: Function;
  concatTimeMinFunc: Function;
  concatTimeMaxFunc: Function;
};

const ModalMask = styled.div`
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.modalBg.rgba};
  display: table;
  transition: opacity 0.3s ease;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 700px;
  min-height: 460px;
`;

const ButtonWrap = styled.div`
  margin: 40px auto 0;
  width: 390px;
  display: flex;
  justify-content: space-between;
`;

const ContentInnter = styled.div`
  width: 694px;
  margin: 0 auto;
  padding: 20px 0;
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrMonths {
    justify-content: space-between;
    width: 694px;
  }
  .rdrMonth {
    background-color: ${colors.white.hex};
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
  }
  .rdrNextPrevButton,
  .rdrDayToday .rdrDayNumber span:after {
    background: ${colors.orange.hex};
  }
  .rdrPprevButton i {
    border-color: transparent ${colors.white.hex} transparent transparent;
  }
  .rdrNextButton i {
    border-color: transparent transparent transparent ${colors.white.hex};
  }
  .rdrMonthAndYearWrapper {
    position: relative;
    .rdrPprevButton {
      position: absolute;
      top: 190px;
      left: -56px;
    }
    .rdrNextButton {
      position: absolute;
      top: 190px;
      right: -56px;
    }
  }
`;

export const ModalCalendar: React.FC<ModalCalendarProps> = (props) => {
  console.log(new Date(), moment(props.today).format(), new Date(moment(props.today).add(1, "d").format("YYYY-MM-DD")));
  const [maxDay, setMaxDay] = useState(new Date(moment(props.today).add(30, "d").format("YYYY-MM-DD")));
  const [state, setState] = useState([
    {
      startDate: new Date(moment(props.today).add(1, "d").format("YYYY-MM-DD")),
      endDate: new Date(moment(props.today).add(1, "d").format("YYYY-MM-DD")),
      key: "selection",
    },
  ]);

  const handleSubmitDuration = (event) => {
    //
    props.concatTimeMinFunc(moment(state[0].startDate).format("YYYY"), moment(state[0].startDate).format("MM"), moment(state[0].startDate).format("DD"));
    props.concatTimeMaxFunc(moment(state[0].endDate).format("YYYY"), moment(state[0].endDate).format("MM"), moment(state[0].endDate).format("DD"));
    props.close(false);
  };

  const handleCalendarChange = (item) => {
    setState([item.selection]);
    setMaxDay(new Date(moment(item.selection.startDate).add(29, "d").format("YYYY-MM-DD")));
  };

  const handleModalClose = () => {
    props.close(false);
  };

  return (
    <ModalMask>
      <ModalWrap>
        <ModalContainer>
          <ContentInnter>
            <DateRangePicker
              locale={ja}
              minDate={new Date(moment(props.today).add(1, "d").format("YYYY-MM-DD"))}
              maxDate={maxDay}
              rangeColors={[colors.primary.hex]}
              color={colors.primary.hex}
              onChange={(item) => {
                handleCalendarChange(item);
              }}
              showSelectionPreview={true}
              showMonthAndYearPickers={true}
              moveRangeOnFirstSelection={true}
              showDateDisplay={true}
              showPreview={true}
              months={2}
              ranges={state}
              direction="horizontal"
            />
            <ButtonWrap>
              <ButtonPrimary disabled={false} onClick={handleModalClose}>
                閉じる
              </ButtonPrimary>
              <ButtonPrimary disabled={false} onClick={handleSubmitDuration}>
                設定を反映する
              </ButtonPrimary>
            </ButtonWrap>
          </ContentInnter>
        </ModalContainer>
      </ModalWrap>
    </ModalMask>
  );
};
