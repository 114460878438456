import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ButtonPrimary } from "../atoms/Button";

type ModalRemoveCalendarProps = {
  close: Function;
  removeFunction: Function;
  done: boolean;
};

const ModalMask = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 496px;
  min-height: 220px;
  background-color: ${colors.white.hex};
`;

const ButtonWrap = styled.div`
  margin: 40px auto 0;
  width: 350px;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapCenter = styled.div`
  margin: 40px auto 0;
  width: 350px;
  display: flex;
  justify-content: center;
`;

const ContentInnter = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
`;

const ButtonPrimaryLarge = styled(ButtonPrimary)`
  width: 160px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
`;

const PageTitle = styled.h1`
  font-size: 20px;
  letter-spacing: 0.28em;
  color: ${colors.keyColor.hex};
  margin: 0 4px 0;
  padding: 0;
  text-align: center;
`;

const PageTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: 8px;
  background-color: ${colors.white.hex};
  border-bottom: 1px solid ${colors.whiteAccent.hex};
`;

const Text = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
`;

export const ModalRemoveCalendar: React.FC<ModalRemoveCalendarProps> = (props) => {
  const [isComplated, setIsComplated] = useState(false);

  useEffect(() => {
    setIsComplated(props.done);
  }, [props.done]);

  return (
    <ModalMask>
      <ModalWrap>
        <ModalContainer>
          <PageTitleWrap>
            <PageTitle>カレンダー連携解除</PageTitle>
          </PageTitleWrap>
          {!isComplated && (
            <ContentInnter>
              <Text>カレンダーの連携を解除します。 </Text>
              <ButtonWrap>
                <ButtonPrimaryLarge
                  onClick={() => {
                    props.close(false);
                  }}
                >
                  やめる
                </ButtonPrimaryLarge>
                <ButtonPrimaryLarge
                  onClick={(e) => {
                    props.removeFunction(e);
                  }}
                >
                  解除する
                </ButtonPrimaryLarge>
              </ButtonWrap>
            </ContentInnter>
          )}
          {isComplated && (
            <ContentInnter>
              <Text>カレンダーの連携を解除しました。</Text>
              <ButtonWrapCenter>
                <ButtonPrimaryLarge
                  onClick={() => {
                    props.close(false);
                  }}
                >
                  設定画面に戻る
                </ButtonPrimaryLarge>
              </ButtonWrapCenter>
            </ContentInnter>
          )}
        </ModalContainer>
      </ModalWrap>
    </ModalMask>
  );
};
