import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";

export interface RadioFilterGroupProps {
  title?: string;
  defaultId: string;
  radioGroup: string;
  radioContents: {
    id: string;
    value: string;
    label: string;
  }[];
}

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  color: ${colors.paleGray.hex};
  background-color: ${colors.silverGray.hex};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 1px;
  &.checked {
    color: ${colors.white.hex};
    background-color: ${colors.orange.hex};
  }
`;

const StyledListWrap = styled.ul`
  list-style-type: none;
`;

const StyledList = styled.li`
  display: inline-block;
  padding: 0 8px 8px 0;
  &:last-child {
    padding-bottom: 0;
  }
`;

const StyledTitle = styled.p`
  font-size: 10px;
  margin: 0 0 4px;
`;

export const RadioGroup: React.FC<RadioFilterGroupProps> = (props) => {
  const [layout, setLayout] = useState(props.defaultId);
  return (
    <>
      <StyledListWrap>
        {props.title && <StyledTitle>{props.title}</StyledTitle>}
        {props.radioContents.map((radioContent: { id: string; value: string; label: string }) => (
          <StyledList key={radioContent.id}>
            <Label className={layout === radioContent.id ? "checked" : ""}>
              <Input
                type="radio"
                id={radioContent.id}
                name={props.radioGroup}
                value={radioContent.value}
                onChange={() => {
                  setLayout(radioContent.id);
                }}
              />
              <div>{radioContent.label}</div>
            </Label>
          </StyledList>
        ))}
      </StyledListWrap>
    </>
  );
};
