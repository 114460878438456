import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ReactComponent as IcoDone } from "../../assets/ico_done.svg";
import { useAppState } from "../../lib/Store";

type CheckboxDayProps = {
  label: string;
  initChecked: boolean;
  weekindex?: number;
  onChange?: Function;
};

interface ContentProps {
  weekindex?: number;
}

const Input = styled.input`
  display: none;
`;

const Label = styled.label<ContentProps>`
  color: ${colors.gray.hex};
  display: flex;
  align-items: center;
  background-color: ${colors.silverGray.hex};
  cursor: pointer;
  width: 56px;
  height: 32px;
  font-size: ${(props) => (props.weekindex === 7 ? 9 : 12)}px;
  font-weight: bold;
  user-select: none;
  padding: 4px;
  &.checked {
    color: ${colors.keyColor.hex};
    background-color: ${colors.ground.hex};
    border: 1px solid ${colors.keyColor.hex};
  }
`;

const IcoDoneWrap = styled(IcoDone)`
  fill: ${colors.white.hex};
  width: 8px;
  height: auto;
`;

const IconDoneFrame = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  background-color: ${colors.buttonGrayGround.hex};
  border-radius: 50%;
  &.checked {
    background-color: ${colors.primary.hex};
  }
`;

const LabelWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 32px;
  text-transform: capitalize;
`;

export const CheckboxDay: React.FC<CheckboxDayProps> = (props) => {
  const [checked, setChecked] = useState(props.initChecked);
  const state = useAppState();

  useEffect(() => {
    if (state.baseTimeJson.length > 0 && state.baseTimeJson[props.weekindex]) {
      setChecked(state.baseTimeJson[props.weekindex]["enabled"]);
    }
    if (props.weekindex === 7) {
      setChecked(state.includingHoliday);
    }
  }, [state]);
  return (
    <Label className={checked ? "checked" : ""} weekindex={props.weekindex}>
      <Input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          setChecked(e.currentTarget.checked);
          props.onChange?.(e);
        }}
      />
      <IconDoneFrame className={checked ? "checked" : ""}>
        <IcoDoneWrap />
      </IconDoneFrame>
      <LabelWrap>{props.label}</LabelWrap>
    </Label>
  );
};
