import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { app } from "../lib/Base";
import { useSetAppState } from "../lib/Store";
import { makeCalendar } from "../lib/Calendar";
import { Layout } from "../components/Layout";
import { Header } from "../components/molecules/Header";
import Footer from "../components/molecules/Footer";
import styled from "styled-components";
import { colors } from "../data/colors";
import { Loading } from "../components/molecules/Loading";
import { AppointmentSchedule } from "../components/molecules/AppointmentSchedule";
import { Error } from "../components/molecules/Error";
import TemplateCard from "../components/molecules/TemplateCard";
import ModalDeleteTemplate from "../components/molecules/ModalDeleteTemplate";
import { ReactComponent as IcoSchedule } from "../assets/ico_schedule.svg";
import { ReactComponent as IcoTemplate } from "../assets/ico_template.svg";
import { ModalFirstSetting } from "../components/molecules/ModalFirstSetting";
import { ModalCalendarConnect } from "../components/molecules/ModalCalendarConnect";
import { ModalFirstAlert } from "../components/molecules/ModalFirstAlert";
import { ModalDeleteSchedule } from "../components/molecules/ModalDeleteSchedule";
import {NotificationAlert} from "../components/molecules/NotificationAlert";

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  padding-top: 40px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: space-around;
`;

const PageTitle = styled.h1`
  font-size: 20px;
  letter-spacing: 0.28em;
  color: ${colors.orange.hex};
  margin: 0 4px 0;
  padding: 0;
`;

const PageTitleWrap = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 8px;
  border-bottom: 2px solid ${colors.keyColor.hex};
`;

const Content = styled.section`
  width: 45%;
  min-height: 608px;
  background-color: ${colors.white.hex};
  margin: 40px 0 48px;
`;

const IcoScheduleWrap = styled(IcoSchedule)`
  fill: ${colors.orange.hex};
  height: 19px;
`;

const IcoTemplateWrap = styled(IcoTemplate)`
  fill: ${colors.orange.hex};
  height: 19px;
  margin-right: 5px;
`;

const AttentionMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${colors.middleGray.hex};
`;

export const Home: React.FC<{}> = () => {
  const history = useHistory();

  //初期設定を終わらせたかどうかのフラグ　true: 設定済み、false: 未設定
  const [isSettings, setIsSettings] = useState(true);
  //カレンダー連携済みかどうかのフラグ　true: 設定済み、false: 未設定
  const [isCalendarConnected, setIsCalendarConnected] = useState(true);

  const [isFirst, setIsFirst] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isModalDeleteSchedule, setIsModalDeleteSchedule] = useState(false);
  const [deleteAppointment, setDeleteAppointment] = useState({ id: "", display_summary: "", display_time_period: "", display_short_comment: "", guest_email: "" });

  const [isModalDeleteTemplate, setIsModalDeleteTemplate] = useState(false);
  const [deletedTemplate, setDeletedTemplate] = useState({ uuid: "" ,title: "", main_param: 0, buffer_param: 0 });

  const [isCopyClipboard, setIsCopyClipboard] = useState(false);

  const setAppState = useSetAppState();
  //初期設定を終えているかどうか判断してしていなかったらモーダルを出す
  const isSettingCompleted = useCallback(
    async (_idToken) => {
      try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const results = await axios.post(`${process.env.REACT_APP_API_BASE_URL}accounts`, {
          idToken: _idToken,
        });

        console.log("-----------------")
        console.dir(results.data)
        
        //calendar_connected カレンダー連携がされているかされていないか
        setIsCalendarConnected(results.data.calendar_connected);
        //setIsCalendarConnected(true);

        if (results.data.calendar_connected) {
          setIsSettings(results.data.setting_completed);
          //setIsSettings(false);
        }

        getTemplates(results.data.access_token);
        getAppointments(results.data.access_token);
        const calendar = makeCalendar(moment(results.data.current_time).format("YYYY"));
        setAppState({
          userInfo: { nickname: results.data.nickname, email: results.data.email, time_zone: results.data.time_zone },
          accessToken: results.data.access_token,
          calendar: calendar,
          today: moment(results.data.current_time).format("YYYY-MM-DD"),
          blockTime: {},
          calendarConnect: results.data.calendar_connected,
          baseTimeJson: [],
          blockRange: {},
          blockDays: [],
          includingHoliday: results.data.including_holiday,
          zoomConnect: results.data.zoom_connected,
        });
      } catch (error) {
        setIsLoaded(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
      }
    },
    [history]
  );

  const getAppointments = useCallback(
    async (token) => {
      try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const results = await axios.get(`${process.env.REACT_APP_API_BASE_URL}me/events/appointments`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setAppointments(results.data);
        setIsLoaded(false);

        //初期設定を終えているかどうか判定するためにまずはidTokenを取得してそれからsetting_completedで判断する
      } catch (error) {
        setIsLoaded(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
      }
    },
    [history]
  );

  // テンプレート一覧を取得
  const getTemplates = useCallback(
    async (token) => {
      try {
        const results = await axios.get(`${process.env.REACT_APP_API_BASE_URL}me/time_templates`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setTemplates(results.data);

      } catch(error) {
        setIsLoaded(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
        console.log(error);
      }
    },
    [history]
  )

  // 削除するデータを専用のStateに開いて、確認用ダイアログを開く。
  const deleteAppintment = (appointment) => {
    setDeleteAppointment(appointment);
    setIsModalDeleteSchedule(true);
  };
  const deleteTemplate = (template) => {
    console.log(template)
    setDeletedTemplate(template);
    setIsModalDeleteTemplate(true);
  };

  // 子コンポーネントからデータをセットできるようにする
  const childSetAppointments = (data) => {
    setAppointments(data);
  };
  const childSetTemplates = (data) => {
    setTemplates(data);
  };

  //最初にアポイントがあるかどうか取得
  useEffect(() => {
    setIsLoaded(true);
    const fn = async () => {
      await app
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          isSettingCompleted(idToken);
        })
        .catch(function (error) {
          //setIsError(true);
          console.log(error);
          //setErrorMessage(error.response);
        });
    };
    fn();
  }, [history]);

  return (
    <Layout title="ホーム">
      <Header />
      <MainContent>
        <Content>
          <PageTitleWrap>
            <IcoScheduleWrap />
            <PageTitle>確定済みスケジュール</PageTitle>
          </PageTitleWrap>
          {appointments.length === 0 && <AttentionMessage>確定しているスケジュールはありません。</AttentionMessage>}
          {appointments.length > 0 && appointments.map((appointment, index) =>
            <AppointmentSchedule
              key={`key-${index}`}
              appointment={appointment}
              deleteAppointment={deleteAppintment}
            />)}
        </Content>
        <Content>
          <PageTitleWrap>
            <IcoTemplateWrap />
            <PageTitle>テンプレート一覧</PageTitle>
          </PageTitleWrap>
          {templates.length === 0 && <AttentionMessage>テンプレートがありません。</AttentionMessage>}
          {templates.length > 0 && templates.map((template, index) =>
            <TemplateCard
              template={template}
              key={`template-${index}`}
              deleteTemplate={deleteTemplate}
              setIsCopyClipboard={setIsCopyClipboard}
            />)}
        </Content>
      </MainContent>
      <Footer />
      {!isSettings && (
        <ModalFirstSetting
          close={(show) => {
            setIsSettings(show);
            setIsFirst(true);
          }}
        />
      )}
      {!isCalendarConnected && <ModalCalendarConnect />}
      {
        <Error
          isShow={isError}
          errorMessage={errorMessage}
          close={() => {
            setIsError(false);
          }}
        />
      }
      {isFirst && <ModalFirstAlert />}
      {isModalDeleteSchedule && (
        <ModalDeleteSchedule
          appointment={deleteAppointment}
          close={() => {
            setIsModalDeleteSchedule(false);
          }}
          setAppointments={childSetAppointments}
        />
      )}
      {isModalDeleteTemplate && (
        <ModalDeleteTemplate
          template={deletedTemplate}
          close={() => {
            setIsModalDeleteTemplate(false);
          }}
          setTemplates={childSetTemplates}
        />
      )}
      <NotificationAlert
        show={isCopyClipboard}
        callback={() => {
          setIsCopyClipboard(false);
        }}
        text={"URLをクリップボードにコピーしました"}
      />
      {isLoaded && <Loading />}
    </Layout>
  );
};
