import React from "react";
import styled, { keyframes, css } from "styled-components";
import { colors } from "../../data/colors";

interface ContentProps {
  isAnimationStart: boolean;
}

type NotificationAlertProps = {
  show: boolean;
  callback: Function;
  text?: string;
};

const ShowAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(80px);
  }
  90% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(0);
  }
`;

const Container = styled.div<ContentProps>`
  position: absolute;
  top: -80px;
  left: 50%;
  width: 400px;
  height: 80px;
  z-index: 999;
  margin-left: -200px;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  animation: ${props => props.isAnimationStart && css`${ShowAnimation} 3s normal forwards ease-in-out`};
`;

const Text = styled.p`
  color: ${colors.white.hex};
`;

export const NotificationAlert: React.FC<NotificationAlertProps> = (props) => {
  return (
    <Container isAnimationStart={props.show} onAnimationEnd={() => {
      props.callback()
    }}>
    <Text>{props.text}</Text>
    </Container>
  );
};
