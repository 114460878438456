import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../data/colors";

type TextFieldWrapperProps = {
  min?: number;
  max?: number;
  count?: number;
  init?: boolean;
};

interface ContentProps {
  min?: number;
  max?: number;
  count?: number;
  init?: boolean;
}

const TextFieldWrap = styled.div`
  position: relative;
  width: 100%;
`;

const TextCounter = styled.div<ContentProps>`
  position: absolute;
  font-size: 8px;
  bottom: 8px;
  right: 5px;
  color: ${(props) =>
    (props.min < props.count && props.max >= props.count) || !props.init
      ? css`
          ${colors.darkGray.hex}
        `
      : css`
          ${colors.orange.hex}
        `};
`;

const TextMinRequired = styled.div<ContentProps>`
  position: absolute;
  font-size: 8px;
  bottom: -10px;
  left: 2px;
  color: ${colors.orange.hex};
  display: ${(props) => (props.min < props.count || !props.init ? css`none` : css`block`)};
`;

const TextMaxRequired = styled.div<ContentProps>`
  position: absolute;
  font-size: 8px;
  bottom: -10px;
  left: 2px;
  color: ${colors.orange.hex};
  display: ${(props) => (props.max >= props.count || !props.init ? css`none` : css`block`)};
`;

export const TextFieldWrapper: React.FC<TextFieldWrapperProps> = (props) => {
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (props.count > 0) {
      setInit(true);
    }
  }, [props.count]);
  return (
    <TextFieldWrap>
      {props.children}
      <TextCounter count={props.count} max={props.max} min={props.min} init={init}>
        {String(props.count)}/{String(props.max)}
      </TextCounter>
      <TextMinRequired count={props.count} min={props.min} init={init}>
        文字数が足りません
      </TextMinRequired>
      <TextMaxRequired count={props.count} max={props.max} init={init}>
        文字数がオーバーしています
      </TextMaxRequired>
    </TextFieldWrap>
  );
};
