import React, { Dispatch, SetStateAction, useContext, useState } from "react";

export type AppState = {
  userInfo: object;
  accessToken: string;
  calendar: any[];
  today: string;
  blockTime: object;
  blockRange: object;
  blockDays: any[];
  calendarConnect: boolean;
  baseTimeJson: any[];
  includingHoliday: boolean;
  zoomConnect: boolean;
};
//:TODO any[]は詳細に落とし込んでリファクタリングしたい

const initialState: AppState = {
  userInfo: {},
  accessToken: "",
  calendar: [],
  today: "",
  blockTime: {},
  blockRange: {},
  blockDays: [],
  calendarConnect: false,
  baseTimeJson: [],
  includingHoliday: false,
  zoomConnect: false,
};

const AppStateContext = React.createContext<AppState>(initialState);
const SetAppStateContext = React.createContext<Dispatch<SetStateAction<AppState>>>(() => {});

export function useAppState() {
  return useContext(AppStateContext);
}
export function useSetAppState() {
  return useContext(SetAppStateContext);
}

export function AppStateProvider(props: { initialState?: AppState; children: React.ReactNode }) {
  const [state, setState] = useState<AppState>(props.initialState ?? initialState);
  return (
    <AppStateContext.Provider value={state}>
      <SetAppStateContext.Provider value={setState}>{props.children}</SetAppStateContext.Provider>
    </AppStateContext.Provider>
  );
}
