import React from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ReactComponent as IcoTag } from "../../assets/ico_tag.svg";

const IcoTagWrap = styled(IcoTag)`
  fill: ${colors.white.hex};
  width: 8px;
  height: 9px;
`;

const IcoTagBox = styled.span`
  position: absolute;
  background-color: ${colors.orange.hex};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
`;

const TagWrap = styled.div`
  position: relative;
  display: inline-block;
  align-items: center;
  border-radius: 1px;
  background-color: ${colors.white.hex};
  line-height: 0;
  height: 16px;
  margin-left: 7px;
`;

const TextWrap = styled.span`
  color: ${colors.orange.hex};
  display: inline-block;
  padding: 3px 4px 3px 19px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
`;

export const Tag: React.FC<{}> = (props) => {
  return (
    <TagWrap>
      <IcoTagBox>
        <IcoTagWrap />
      </IcoTagBox>
      <TextWrap>{props.children}</TextWrap>
    </TagWrap>
  );
};
