/**
 * 既存のCellFreeは時間が連続でない場合、セルとセルの間隔が入るようになっている。
 * スマートフォンでは成るべくheight方向の隙間をなくしたいので、新しく作成する。
 */
import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {colors} from "../../data/colors";
import {TimeContext} from "../../lib/CheckboxProvider";
import moment from "moment";

type CellFreeProps = {
  startTime: string;
  endTime: string;
  initChecked: boolean;
  addFunction?: Function;
  removeFunction?: Function;
  isGuest: boolean;
  uuid: string;
};

interface ContentProps {
  guest?: boolean;
}

const Input = styled.input`
  display: none;
`;

const Cell = styled.label<ContentProps>`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 19px;
  color: ${colors.primary.hex};
  background-color: ${colors.ground.hex};
  border-radius: 1px;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid ${colors.keyColor.hex};
  box-sizing: border-box;
  &:hover {
    color: ${colors.keyColor.hex};
    background-color: ${colors.white.hex};
    border: 1px dashed ${colors.keyColor.hex};
    box-shadow: inset 0px 0px 8px rgba(255, 255, 255, 0.84);
  }
  &.checked {
    color: ${(props) => (props.guest ? colors.white.hex : colors.white.hex)};
    background-color: ${(props) => (props.guest ? `#B34215` : colors.whiteAccent.hex)};
  }
`;

const LabelWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CellFreeToSmartphone: React.FC<CellFreeProps> = (props) => {
  const { addUuid, removeUuid, uuid, range, blockDays } = useContext(TimeContext);
  const [checked, setChecked] = useState(props.initChecked || false);
  const [text, setText] = useState(props.children);
  const dateFrom = moment(props.startTime);
  const dateTo = moment(props.endTime);
  const [isShow, setIsShow] = useState(true);

  const updateChecked = (e) => {
    if (e.currentTarget.checked) {
      if (props.isGuest) {
        setText("Selected");
        addUuid(props.uuid);
      }
    } else {
      if (props.isGuest) {
        setText(props.children);
        removeUuid(props.uuid);
      }
    }
    setChecked(e.currentTarget.checked);
  };

  useEffect(() => {
    if (props.isGuest && uuid[0] === props.uuid) {
      props.addFunction(props.startTime, props.endTime, props.uuid);
      setChecked(true);
      setText(`Selected:`);
    } else {
      props.addFunction(props.startTime, props.endTime, props.uuid);
      setChecked(false);
      setText(`${props.children}`);
    }
  }, [props.uuid, uuid]);

  useEffect(() => {
    const startDate = `${moment(dateFrom).format("YYYY-MM-DD")} ${range["start"]}`;
    const endDate = `${moment(dateFrom).format("YYYY-MM-DD")} ${range["end"]}`;
    const dateFromFormatted = moment(dateFrom).format("YYYY-MM-DD HH:mm");
    const dateToFormatted = moment(dateTo).format("YYYY-MM-DD HH:mm");
    const isStartRangeStart = moment(dateFromFormatted).isSameOrAfter(startDate);
    const isStartRangeEnd = moment(dateFromFormatted).isSameOrBefore(endDate);
    const isEndRangeStart = moment(dateToFormatted).isSameOrAfter(startDate);
    const isEndRangeEnd = moment(dateToFormatted).isSameOrBefore(endDate);

    setIsShow(true);
    if (!props.isGuest) {
      if (isStartRangeStart && isStartRangeEnd && isEndRangeStart && isEndRangeEnd) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    }

    blockDays.map((day) => {
      if (moment(day).format("YYYY-MM-DD") === moment(dateFrom).format("YYYY-MM-DD")) {
        setIsShow(false);
      }
    });
  }, [range, blockDays]);

  return (
    <>
      {isShow && (
        <Cell className={checked ? "checked" : ""} guest={props.isGuest}>
          <Input
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              updateChecked(e);
            }}
          />
          <LabelWrap>{text}</LabelWrap>
        </Cell>
      )}
    </>
  );
};

export default CellFreeToSmartphone;