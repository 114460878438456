import React from "react";
import { Layout } from "../components/Layout";
import { Header } from "../components/molecules/Header";
import Footer from "../components/molecules/Footer";
import styled from "styled-components";
import { colors } from "../data/colors";

import { ButtonPrimary, ButtonRound } from "../components/atoms/Button";
import { TextField } from "../components/atoms/TextField";
import { Selectbox, SelectboxOption } from "../components/atoms/Selectbox";
import { Checkbox } from "../components/atoms/Checkbox";
import { RadioGroup } from "../components/atoms/RadioGroup";
import { Textarea } from "../components/atoms/Textarea";
import { HeaderMenu } from "../components/molecules/HeaderMenu";
import { Tag } from "../components/atoms/Tag";

import { ReactComponent as IcoAccount } from "../assets/ico_account.svg";
import { ReactComponent as IcoNotification } from "../assets/ico_notification.svg";
import { ReactComponent as IcoScedule } from "../assets/ico_schedule.svg";
import { ReactComponent as IcoDelete } from "../assets/ico_delete.svg";
import { ReactComponent as IcoClose } from "../assets/ico_close.svg";
import { ReactComponent as IcoArrow } from "../assets/ico_arrow.svg";

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  padding: 120px 24px 48px;
  background-color: #868686;
`;

const ComponentWrap = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const PageTitle = styled.h1`
  font-size: 14px;
  color: ${colors.middleGray.hex};
  margin: 0 0 8px;
  padding: 0;
`;

const IcoAccountWrap = styled(IcoAccount)`
  fill: ${colors.keyColor.hex};
  height: 19px;
`;

const IcoSceduleWrap = styled(IcoScedule)`
  fill: ${colors.keyColor.hex};
  height: 23px;
`;

const IcoCloseWrap = styled(IcoClose)`
  fill: ${colors.keyColor.hex};
  height: 20px;
`;

const IcoNotificationWrap = styled(IcoNotification)`
  fill: ${colors.keyColor.hex};
  width: 13px;
  height: 16px;
`;

const IcoDeleteWrap = styled(IcoDelete)`
  fill: ${colors.keyColor.hex};
  width: 14px;
  height: 18px;
`;

const IcoArrowPrevWrap = styled(IcoArrow)`
  fill: ${colors.keyColor.hex};
  width: 15px;
  height: 15px;
`;

const IcoArrowNextWrap = styled(IcoArrow)`
  fill: ${colors.keyColor.hex};
  width: 15px;
  height: 15px;
  transform: scaleX(-1);
`;

const ButtonRoundAccount = styled(ButtonRound)`
  width: 32px;
  height: 32px;
`;

const ButtonRoundNotification = styled(ButtonRound)`
  width: 16px;
  height: 16px;
  padding: 0;
  background-color: transparent;
`;

const ButtonPrimarySquareLarge = styled(ButtonPrimary)`
  width: 64px;
  height: 64px;
`;

const ButtonIconText = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

const ButtonIconSubText = styled.p`
  width: calc(100% / 0.8);
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  margin: 4px 0 0;
  padding: 0;
  transform: scale(0.8);
  transform-origin: center left;
`;

const ButtonIconSubTextLong = styled.p`
  width: calc(100% / 0.6);
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  margin: 4px 0 0;
  padding: 0;
  transform: scale(0.6);
  transform-origin: center left;
`;

const SelectboxTime = styled(Selectbox)`
  font-size: 20px;
`;

const ButtonPrimarySuqure28x28 = styled(ButtonPrimary)`
  width: 28px;
  height: 28px;
`;

const ButtonPrimarySuqure30x30 = styled(ButtonPrimary)`
  width: 30px;
  height: 30px;
`;

const ButtonPrimarySquareCancel = styled(ButtonPrimary)`
  width: 64px;
  height: 64px;
`;

const SelectWrap = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
`;

export const Components: React.FC<{}> = () => {
  return (
    <Layout title="コンポーネント">
      <Header />
      <MainContent>
        <PageTitle>コンポーネント</PageTitle>

        <ComponentWrap>
          <ButtonPrimary>アカウント作成</ButtonPrimary>
        </ComponentWrap>

        <ComponentWrap>
          <ButtonRoundAccount>
            <IcoAccountWrap />
          </ButtonRoundAccount>
        </ComponentWrap>

        <ComponentWrap>
          <ButtonRoundNotification>
            <IcoNotificationWrap />
          </ButtonRoundNotification>
        </ComponentWrap>

        <ComponentWrap>
          <ButtonPrimarySquareLarge>
            <ButtonIconText>ALL</ButtonIconText>
          </ButtonPrimarySquareLarge>
        </ComponentWrap>

        <ComponentWrap>
          <ButtonPrimarySquareLarge>
            <IcoSceduleWrap />
            <ButtonIconSubTextLong>新規日程調整</ButtonIconSubTextLong>
          </ButtonPrimarySquareLarge>
        </ComponentWrap>

        <ComponentWrap>
          <ButtonPrimarySquareCancel>
            <IcoCloseWrap />
            <ButtonIconSubText>Cancel</ButtonIconSubText>
          </ButtonPrimarySquareCancel>
        </ComponentWrap>

        <ComponentWrap>
          <ButtonPrimarySuqure28x28>
            <IcoDeleteWrap />
          </ButtonPrimarySuqure28x28>
        </ComponentWrap>

        <ComponentWrap>
          <ButtonPrimarySuqure30x30>
            <IcoArrowPrevWrap />
          </ButtonPrimarySuqure30x30>
        </ComponentWrap>

        <ComponentWrap>
          <ButtonPrimarySuqure30x30>
            <IcoArrowNextWrap />
          </ButtonPrimarySuqure30x30>
        </ComponentWrap>

        <ComponentWrap>
          <TextField type="text" placeholder="Email" />
        </ComponentWrap>

        <ComponentWrap>
          <TextField type="password" placeholder="Password" />
        </ComponentWrap>

        <ComponentWrap>
          <SelectWrap>
            <Selectbox>
              <SelectboxOption value="日本">日本</SelectboxOption>
            </Selectbox>
          </SelectWrap>
        </ComponentWrap>

        <ComponentWrap>
          <SelectWrap>
            <SelectboxTime>
              <SelectboxOption value="00:00">00:00</SelectboxOption>
              <SelectboxOption value="00:00">00:15</SelectboxOption>
              <SelectboxOption value="00:00">00:30</SelectboxOption>
              <SelectboxOption value="00:00">01:00</SelectboxOption>
              <SelectboxOption value="00:00">01:15</SelectboxOption>
              <SelectboxOption value="00:00">01:30</SelectboxOption>
              <SelectboxOption value="00:00">01:45</SelectboxOption>
              <SelectboxOption value="00:00">02:00</SelectboxOption>
            </SelectboxTime>
          </SelectWrap>
        </ComponentWrap>

        <ComponentWrap>
          <Checkbox label="1" initChecked={false} onChange={() => {}} />
        </ComponentWrap>

        <ComponentWrap>
          <RadioGroup
            title="名前の入力"
            radioGroup="nameGroup"
            defaultId="必須"
            radioContents={[
              { id: "必須", value: "必須", label: "必須" },
              { id: "任意", value: "任意", label: "任意" },
            ]}
          />
        </ComponentWrap>

        <ComponentWrap>
          <Textarea />
        </ComponentWrap>

        <ComponentWrap>
          <HeaderMenu />
        </ComponentWrap>

        <ComponentWrap>
          <Tag>オンライン</Tag>
        </ComponentWrap>
      </MainContent>
      <Footer />
    </Layout>
  );
};
