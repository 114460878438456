import styled from "styled-components";
import { colors } from "../../data/colors";

export const Textarea = styled.textarea`
  background: ${colors.silverGray.hex};
  border: none;
  box-sizing: border-box;
  color: ${colors.darkGray.hex};
  font-size: 14px;
  width: 100%;
  min-height: 52px;
  outline: none;
  padding: 6px 8px;
  resize: none;

  &::placeholder {
    color: ${colors.white.hex};
  }

  &:invalid {
    border: 1px solid ${colors.orange.hex};
  }

  &.invalid {
    border: 1px solid ${colors.orange.hex};
  }
`;
