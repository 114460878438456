/*
 DateNoCheckbox：既存のDateコンポーネントがチェックボックスを含むものだったので、新規作成した。
 Templateページの日付＋曜日表示に用いる。
 */
import React from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";

type DateProps = {
  date?: string;
  data: {
    date: string;
    day: string;
    holiday: boolean;
    holiday_name: string;
    data: object[];
  };
  day: string;
  today?: string;
};

interface ContentProps {
  contentDay: string;
}

const Container = styled.div<ContentProps>`
  width: 100%;
  margin-right: ${(props) => (props.contentDay === "San." ? 0 : 12)}px;
`;

const DateWrap = styled.div`
  color: ${colors.orange.hex};
  display: flex;
  align-items: center;
  background-color: ${colors.white.hex};
  width: 100%;
  height: 42px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid ${colors.orange.hex};
`;

const DateTextWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
`;

const DayWrap = styled.div`
  width: 100%;
  height: 26px;
  margin-top: 8px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: ${colors.gray.hex};
  &.sat {
    color: #2d9cdb;
  }
  &.sun {
    color: #eb5757;
  }
  &.today {
    color: ${colors.white.hex};
    background-color: ${colors.primary.hex};
  }
`;

export const DateNoCheckbox: React.FC<DateProps> = (props) => {

  let dayElement;

  if (props.day === "土") {
    dayElement = <DayWrap className={props.today === props.data.date ? "today sat" : "sat"}>{props.day}</DayWrap>;
  } else if (props.day === "日") {
    dayElement = <DayWrap className={props.today === props.data.date ? "today sun" : "sun"}>{props.day}</DayWrap>;
  } else {
    dayElement = <DayWrap className={props.today === props.data.date ? "today" : ""}>{props.day}</DayWrap>;
  }

  const numberDate = String(Number(props.data.date.split("-")[2]));

  return (
    <Container contentDay={props.day}>
      <DateWrap>
        <DateTextWrap>
          {numberDate}
        </DateTextWrap>
      </DateWrap>
      {dayElement}
    </Container>
  );
};

export default DateNoCheckbox
