import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../data/colors";
import { ReactComponent as IcoDone } from "../../assets/ico_done.svg";

type CheckboxProps = {
  label: string;
  initChecked: boolean;
  onChange?: Function;
  updateChaecked?: boolean;
  disabled?: boolean;
  isGuest?: boolean;
};

interface ContentProps {
  isGuest: boolean;
}

const Input = styled.input`
  display: none;
`;

const Label = styled.label<ContentProps>`
  color: ${colors.gray.hex};
  display: flex;
  align-items: center;
  background-color: ${colors.silverGray.hex};
  cursor: ${(props) => (props.isGuest ? "default" : "pointer")};
  width: 100%;
  height: 42px;
  font-size: 14px;
  font-weight: bold;
  user-select: none;
  padding: 4px;
  &.checked {
    color: ${colors.orange.hex};
    background-color: ${colors.white.hex};
    border: 1px solid ${colors.orange.hex};
  }
  &.disabled {
    cursor: default;
  }
`;

const IcoDoneWrap = styled(IcoDone)`
  fill: ${colors.white.hex};
  width: 14px;
  height: auto;
`;

const IconDoneFrame = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${colors.buttonGrayGround.hex};
  border-radius: 50%;
  &.checked {
    background-color: ${colors.primary.hex};
  }
`;

const LabelWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
`;

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const [checked, setChecked] = useState(props.initChecked);
  const [labelClassName, setLabalClassName] = useState("disabled");

  useEffect(() => {
    setChecked(props.updateChaecked);
  }, [props.updateChaecked]);

  useEffect(() => {
    if (!props.disabled) {
      if (checked) {
        setLabalClassName("checked");
        props.onChange(true);
      } else {
        setLabalClassName("");
        props.onChange(false);
      }
    }
  }, [checked, props.disabled]);

  useEffect(() => {
    if (props.disabled) {
      setLabalClassName("disabled");
    }
  }, [props.disabled]);

  return (
    <Label className={labelClassName} isGuest={props.isGuest}>
      <Input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          if (!props.isGuest) {
            setChecked(e.currentTarget.checked);
          }
        }}
      />
      <IconDoneFrame className={labelClassName}>
        <IcoDoneWrap />
      </IconDoneFrame>
      <LabelWrap>{props.label}</LabelWrap>
    </Label>
  );
};
