import React from "react";
import Media from "react-media";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { Home } from "./pages/Home";
import { Schedule } from "./pages/Schedule";
import { Template } from "./pages/Template";
import { Mailauthsuccess } from "./pages/Mailauthsuccess";
import { Invitation } from "./pages/Invitation";
import { PasswordReset } from "./pages/PasswordReset";
import { Apiconnectfinish } from "./pages/Apiconnectfinish";
import { Profile } from "./pages/Profile";
import { Setting } from "./pages/Setting";
import { SignupConfirm } from "./pages/SignupConfirm";
import { Components } from "./pages/Components";
import PrivateRoute from "./lib/PrivateRoute";
import { AuthProvider } from "./lib/AuthProvider";
import { TimeProvider } from "./lib/CheckboxProvider";
import InvitationToSmartphone from "./pages/InvitationToSmartphone";
import {Success} from "./pages/Success";

export const App: React.FC<{}> = () => {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <TimeProvider>
            <PrivateRoute exact path="/" component={Login} />
            <PrivateRoute path="/home" component={Home} />
            <PrivateRoute path="/schedule" component={Schedule} />
            <PrivateRoute path="/template" component={Template} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/setting" component={Setting} />
            <Route path="/components" component={Components} />
            <Route path="/success" component={Success} />
            <Route path="/signup" component={Signup} />
            <Route path="/mailauthsuccess" component={Mailauthsuccess} />
            <Route path="/password-reset" component={PasswordReset} />
            <Route path="/apiconnectfinish" component={Apiconnectfinish} />
            <Route path="/signup-confirm" component={SignupConfirm} />

            {/* ウィンドウ幅が599px以下の場合、スマートフォン専用の画面を表示 */}
            <Media query="(max-width: 599px)">
              { matches => matches ? (
                <Route path="/invitation" component={InvitationToSmartphone} />
              ) : (
                <Switch>
                  <Route path="/invitation" component={Invitation} />
                </Switch>
              )}
            </Media>
          </TimeProvider>
        </Switch>
      </Router>
    </AuthProvider>
  );
};
