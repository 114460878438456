import React from 'react';
import styled from "styled-components";
import CellFreeToSmartphone from "../atoms/CellFreeToSmartphone";

type DateProps = {
  date?: string;
  data: {
    date: string;
    day: string;
    holiday: boolean;
    holiday_name: string;
    data: object[];
  };
  day: string;
  addFunction?: Function;
  removeFunction?: Function;
};

const Container = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  heightL 100%;
  top: 0;
`;

const CellWrap = styled.div`
  padding: 5px;
`;

const DayWrap = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
`;

const DateTimelineToSmartphone: React.FC<DateProps> = (props) => {

  return (
    <Container>
      <DayWrap>{props.data.date}({props.day})</DayWrap>
      <CellWrap>
        {props.data.data.length > 0 &&
        props.data.data.map((data, index) =>
          <CellFreeToSmartphone
            key={index}
            startTime={data["start_time"]}
            endTime={data["end_time"]}
            initChecked={false}
            isGuest={true}
            uuid={data["uuid"]}
            addFunction={props.addFunction}
            removeFunction={props.removeFunction}
          >
            {data["name"]}
          </CellFreeToSmartphone>)
        }
      </CellWrap>
    </Container>
  );
};

export default DateTimelineToSmartphone;