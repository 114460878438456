import React from "react";
import { Layout } from "../components/Layout";
import styled from "styled-components";
import { colors } from "../data/colors";
import { ReactComponent as AppLogo } from "../assets/app_logo.svg";
import Footer from "../components/molecules/Footer";
import { ButtonLink } from "../components/atoms/Button";
import { AppVersion } from "../components/atoms/AppVersion";

const AppLogoWrap = styled(AppLogo)`
  width: 220px;
  height: 220px;
`;

const MainContent = styled.main`
  width: 100%;
  min-height: 100%;
  padding-top: 40px;
  background-color: ${colors.white.hex};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  width: 460px;
`;

const Headings = styled.h1`
  display: flex;
  justify-content: center;
`;

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SignupConfirm: React.FC<{}> = () => {
  return (
    <Layout title="アカウント作成:メール送信完了">
      <MainContent>
        <Content>
          <Headings>
            <AppLogoWrap />
          </Headings>
          <AppVersion />
          <p>
            入力されたメールアドレス宛に確認メールを送信しました。
            <br />
            メールに記載されているURLをクリックし会員登録を完了してください。
          </p>
          <ButtonWrap>
            <ButtonLink to="/">ログインページ</ButtonLink>
          </ButtonWrap>
        </Content>
      </MainContent>
      <Footer />
    </Layout>
  );
};
